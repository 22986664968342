import axios from "axios";

interface ApiResponseOutput {
  success: boolean;
  data: any;
}

const fetchFastTrackApi = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=fee/fasttrack/status`);
    if (res.data.success) {
      return {
        success: true,
        data: res.data.data,
      };
    } else {
      return {
        success: false,
        data: res.data.message,
      };
    }
  } catch (err:any) {
    return {
      success: false,
      data: "Something went wrong",
    };
  }
};

const fetchFastTrackCardDetail = async (): Promise<ApiResponseOutput> => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=fasttrack/video/cards`);
    if (res.data.success) {
      return {
        success: true,
        data: res.data.data,
      };
    } else {
      return {
        success: false,
        data: res.data.message,
      };
    }
  } catch (err:any) {
    return {
      success: false,
      data: "Something went wrong",
    };
  }
};

const fetchFastTrackCategoryList=async (): Promise<ApiResponseOutput> => {
  try {
    const res = await axios.get('/api/scholarRoute?path=fasttrack/video/category/list');
    if (res.data.success) {
      return {
        success: true,
        data: res.data.data,
      };
    } else {
      return {
        success: false,
        data: res.data.message,
      };
    }
  } catch (err:any) {
    return {
      success: false,
      data: "Something went wrong",
    };
  }
}

export const fastTrackApi = {
  fetchFastTrackApi,
  fetchFastTrackCardDetail,
  fetchFastTrackCategoryList
};
