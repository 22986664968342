import { call, put, takeLatest } from "redux-saga/effects";
import { upcomingIeltsPlusBatchesApi } from "components/Ielts/UpcomingIeltsBatches/redux/UpcomingIeltsApi";
import {
  upcomingIeltsBatchesAction,
  UpcomingType,
} from "components/Ielts/UpcomingIeltsBatches/redux/UpcomingIeltsActions";
import { AnyAction } from "redux";
import { priceBundleName } from "DataMapper/Common/WorkshopData";
import { WorkshopSchema } from "Interfaces/reducerTypes/IeltsPlusSchema";

function* fetchData(action: AnyAction): Generator<any, any, any> {
  const res = yield call(
    upcomingIeltsPlusBatchesApi.fetchUpcomingIeltsPlusClass,
    action.payload
  );
  if (res.success) {
    yield put(
      upcomingIeltsBatchesAction.fetchUpcomingIeltsSuccess(
        res.data.workshopBatches
      )
    );
    yield put(
      upcomingIeltsBatchesAction.fetchNudgeSuccess(res.data.nudgeOffer)
    );
  } else {
    yield put(upcomingIeltsBatchesAction.fetchUpcomingIeltsFail(res.data));
  }
}

function* fetchDataClient(): Generator<any, any, any> {
  const res = yield call(
    upcomingIeltsPlusBatchesApi.fetchUpcomingIeltsPlusClassClientSide
  );
  if (res.success) {
    const ieltsPlusBatch = res.data.workshopBatches.filter(
      (data: WorkshopSchema) =>
        data.pricingBundleName != priceBundleName.IELTS_FOUNDATION
    );
    yield put(
      upcomingIeltsBatchesAction.fetchUpcomingIeltsSuccess(ieltsPlusBatch)
    );
    const foundationBatch = res.data.workshopBatches.filter(
      (data: WorkshopSchema) =>
        data.pricingBundleName == priceBundleName.IELTS_FOUNDATION
    );
    yield put(
      upcomingIeltsBatchesAction.fetchIeltsFoundationBatchSuccess(
        foundationBatch
      )
    );
    const plusBatch = res.data.workshopBatches.filter(
      (data: WorkshopSchema) =>
        data.pricingBundleName == priceBundleName.IELTS_PLUS
    );
    yield put(upcomingIeltsBatchesAction.fetchIeltsPlusBatchSuccess(plusBatch));
  } else {
    yield put(upcomingIeltsBatchesAction.fetchUpcomingIeltsFail(res.data));
  }
}

function* upcomingIeltsSaga() {
  yield takeLatest(UpcomingType.UPCOMING_IELTS_FETCH_REQUESTED, fetchData);
  yield takeLatest(
    UpcomingType.UPCOMING_IELTS_FETCH_CLIENT_REQUESTED,
    fetchDataClient
  );
}

export default upcomingIeltsSaga;
