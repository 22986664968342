import { onboardingActionConstants } from 'components/Onboarding/redux/OnboardingActions'
import { cloneDeep } from 'lodash'
import { AnyAction } from 'redux'

const initialState: any = {
  currentTab: '',
  addedCountries: [],
  removedCountries: [],
  initialCountry: 'Canada',
  isEditMode: false,
  activeStep: null,
  completedSteps: [],
  isOpenSkipModal: false,
  percentCompletionList: ['5', '10', '50', '80', '100'],
  progressCompleted: [],
  preparationStage: '',
  currentProgress: Number(0),
  countryFormData: [],
  isCheckingForm: false,
  isFormValidated: false,
  firstStepError: false,
  initialData: {},
  educationDetailsFormData: {
    highestLevelEducation: '',
    educationDetails: [],
  },
  preferredLocations: [],
  examDetails: {},
  isValidatedEducation: null,
  isCheckingEduForm: false,
  deletedCountryId: Number(-1),
  isCheckingExamForm: false,
  isValidatedExamForm: null,
  isOpenSuccessModal: false,
  citiesData: [],
  isSubmittingCountryForm: false,
  stepsCompleted: 0,
  preferredProgramCourses: [],
  preferredCountryIds: [],
  initialPreferredCourses: [],
  initialPreferredLocations: [],
  isNextClicked: false,
  isIeltsRequired: false,
  isIeltsNotInitiated: false,
}

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case onboardingActionConstants.ADD_COUNTRY:
      return {
        ...state,
        addedCountries: [...state.addedCountries, action.payload],
        currentTab: action.payload,
      }
    case onboardingActionConstants.DELETE_COUNTRY:
      const updatedCountries = [
        ...state.countryFormData.filter(
          (c: any) => c.country !== action.payload
        ),
      ]
      const availableCountries = [
        ...state.addedCountries.filter((c: any) => c != action.payload),
      ]
      return {
        ...state,
        countryFormData: [...updatedCountries],
        addedCountries: [
          ...state.addedCountries.filter((c: any) => c != action.payload),
        ],
        removedCountries: [...state.removedCountries, action.payload],
        currentTab: availableCountries[availableCountries.length - 1],
      }
    case onboardingActionConstants.SET_EDIT_MODE:
      return {
        ...state,
        isEditMode: action.payload,
      }

    case onboardingActionConstants.HANDLE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      }
    case onboardingActionConstants.SET_COMPLETE:
      return {
        ...state,
        completedSteps: [...state.completedSteps, action.payload],
      }
    case onboardingActionConstants.TOGGLE_SKIP_MODAL:
      return {
        ...state,
        isOpenSkipModal: !state.isOpenSkipModal,
      }

    case onboardingActionConstants.CHECK_FORM:
      return {
        ...state,
        isCheckingForm: action.payload,
      }

    case onboardingActionConstants.HANDLE_TAB_SELECT:
      return {
        ...state,
        currentTab: action.payload,
      }
    case onboardingActionConstants.SET_FIRST_STEP_DATA:
      return {
        ...state,
        preparationStage: action.payload,
      }
    case onboardingActionConstants.SET_PREPARATION_STAGE:
      return {
        ...state,
        preparationStage: action.payload,
      }
    case onboardingActionConstants.SET_CURRENT_PROGRESS:
      return {
        ...state,
        currentProgress:
          state.stepsCompleted > action.payload
            ? state.stepsCompleted
            : action.payload,
      }
    case onboardingActionConstants.SET_COUNTRY_FORM_DATA:
      const index = state.countryFormData?.findIndex(
        (item: any) => item.country == action?.payload.country
      )
      const newArray = [...state.countryFormData]

      if (index >= 0) {
        newArray[index] = action.payload
      } else {
        newArray.push(action.payload)
      }
      for (let i = 0; i < newArray?.length; i++) {
        newArray[i].preferredCourses = action.payload?.preferredCourses
        newArray[i].preferredLocations = action.payload?.preferredLocations
        newArray[i].budget = action.payload?.budget
        newArray[i].courseDuration = action.payload?.courseDuration
        newArray[i].noLocationPreference = action.payload?.noLocationPreference
        newArray[i].preferredIntake = action.payload?.preferredIntake
        newArray[i].preferredProgram = action.payload?.preferredProgram
      }
      return {
        ...state,
        countryFormData: [...newArray],
        initialPreferredCourses: action.payload?.preferredCourses,
        initialPreferredLocations: action.payload?.preferredLocations,
      }

    case onboardingActionConstants.SET_FORM_VALIDATION:
      return {
        ...state,
        isFormValidated: action.payload,
      }
    case onboardingActionConstants.HANDLE_NEXT_CLICKED:
      return {
        ...state,
        isNextClicked: action.payload,
      }
    case onboardingActionConstants.SHOW_FIRST_STEP_ERROR:
      return {
        ...state,
        firstStepError: action.payload,
      }

    case onboardingActionConstants.FETCH_INITIAL_DATA_SUCCESS:
      const countriesData = cloneDeep(
        action.payload.countriesInterestedIn
      )?.reduce((a: any, c: any) => {
        a['preferredProgram'] = cloneDeep(c.preferredProgram)
        a['preferredIntake'] = cloneDeep(c.preferredIntake)
        a['budget'] = cloneDeep(c.budget)
        a['countryId'] = cloneDeep(c.countryId)
        a['courseDuration'] = cloneDeep(c.courseDuration)
        a['preferredLocations'] = cloneDeep(c.preferredLocations)
        a['preferredCourses'] = cloneDeep(c.preferredCourses)
        return a
      }, {})
      const countryData = [] as any
      const countries = [] as any
      return {
        ...state,
        initialData: action.payload,
        educationDetailsFormData: {
          ...state.educationDetailsFormData,
          educationDetails: action.payload.educationDetails,
          highestLevelEducation: action.payload.highestLevelEducation,
        },
        examDetails: action.payload.examDetails,
        preparationStage: action.payload.preparationStage,
        countryFormData: countryData,
        initialPreferredCourses: countryData[0]?.preferredCourses,
        initialPreferredLocations: countryData[0]?.preferredLocations,
        addedCountries: countries,
        countryDetails: countriesData,
        currentTab: countries[0],
        examData: {
          gapYears: action.payload.gapYears,
          passportStatus: action.payload.passportStatus,
          totalWorkExperience: action.payload.totalWorkExperience,
          proofOfEmploymentHistory: action.payload.proofOfEmploymentHistory,
        },
        stepsCompleted: action.payload.stepsCompleted,
      }

    case onboardingActionConstants.SET_HIGHEST_EDUCATION:
      return {
        ...state,
        educationDetailsFormData: {
          ...state.educationDetailsFormData,
          highestLevelEducation: action.payload.status,
          // educationDetails: [
          //   ...state.educationDetailsFormData.educationDetails,
          //   action.payload.details,
          // ],
        },
      }

    case onboardingActionConstants.VALIDATE_EDUCATION_FORM:
      return {
        ...state,
        isValidatedEducation: action.payload,
      }

    case onboardingActionConstants.CHECK_EDUCATION_FORM:
      return {
        ...state,
        isCheckingEduForm: action.payload,
      }
    case onboardingActionConstants.GET_PREFERRED_PROGRAM_COURSE_LISTS_SUCCESS:
      return {
        ...state,
        preferredProgramCourses: action.payload,
      }
    case onboardingActionConstants.GET_PREFERRED_LOCATION_LISTS_SUCCESS:
      return {
        ...state,
        preferredLocations: action.payload,
      }
    case onboardingActionConstants.GET_PREFERRED_COUNTRY_ID_LISTS_SUCCESS:
      return {
        ...state,
        preferredCountryIds: action.payload,
      }

    case onboardingActionConstants.SET_EDUCATION_FORM_DATA:
      if (action.payload.length == 2) {
        const [a, b] = action.payload
        let UpdatedEducationDetails = [
          ...state.educationDetailsFormData?.educationDetails,
        ]
        UpdatedEducationDetails =
          state.educationDetailsFormData?.educationDetails.map((item: any) => {
            return item.educationLevel == a?.educationLevel ? a : item
          })
        UpdatedEducationDetails = UpdatedEducationDetails.map((item: any) => {
          return item.educationLevel == b?.educationLevel ? b : item
        })
        return {
          ...state,
          educationDetailsFormData: {
            ...state.educationDetailsFormData,
            educationDetails: UpdatedEducationDetails,
          },
        }
      } else if (action.payload.educationLevel == 'TWELFTH') {
        let UpdatedEducationDetails = [
          ...state.educationDetailsFormData?.educationDetails,
        ]
        UpdatedEducationDetails =
          state.educationDetailsFormData?.educationDetails.map((item: any) => {
            return item.educationLevel == action.payload?.educationLevel
              ? action.payload
              : item
          })
        return {
          ...state,
          educationDetailsFormData: {
            ...state.educationDetailsFormData,
            educationDetails: UpdatedEducationDetails,
          },
        }
      } else {
        const UpdatedEducationDetails = [
          ...state.educationDetailsFormData?.educationDetails,
        ]
        return {
          ...state,
          educationDetailsFormData: {
            ...state.educationDetailsFormData,
            educationDetails: UpdatedEducationDetails,
          },
        }
      }

    case onboardingActionConstants.SUBMIT_EDUCATION_DETAILS_SUCCESS:
      return {
        ...state,
        isCheckingEduForm: action.payload,
      }
    case onboardingActionConstants.SUBMIT_COLLEGE_FORMS_SUCCESS:
      return {
        ...state,
        isSubmittingCountryForm: false,
      }
    case onboardingActionConstants.SUBMIT_PREPARATION_STAGE_SUCCESS:
      return {
        ...state,
        preparationStage: action.payload,
      }

    case onboardingActionConstants.CHECK_EXAM_FORM:
      return {
        ...state,
        isCheckingExamForm: action.payload,
      }
    case onboardingActionConstants.TOGGLE_SUCCESS_MODAL:
      return {
        ...state,
        isOpenSuccessModal: !state.isOpenSuccessModal,
      }
    case onboardingActionConstants.FETCH_CITY_FROM_COUNTRY_SUCCESS:
      return {
        ...state,
        citiesData: action.payload,
      }
    case onboardingActionConstants.FETCH_CITY_FROM_COUNTRY_ERROR:
      return {
        ...state,
        citiesData: [],
      }
    case onboardingActionConstants.DELETE_COUNTRY_FROM_FORM_DATA_SUCCESS:
      return {
        ...state,
        deletedCountryId: action.payload,
      }
    case onboardingActionConstants.DELETE_COUNTRY_FROM_FORM_DATA_ERROR:
      return {
        ...state,
        deletedCountryId: state.deletedCountryId,
      }

    case onboardingActionConstants.SET_SUBMITTING_COUNTRY_FORM:
      return {
        ...state,
        isSubmittingCountryForm: action.payload,
      }
    case onboardingActionConstants.IELTS_REQUIRED:
      return {
        ...state,
        isIeltsRequired: action.payload,
      }
    case onboardingActionConstants.IELTS_STATUS:
      return {
        ...state,
        isIeltsNotInitiated: action.payload,
      }
    default:
      return state
  }
}

export default reducer
