import axios from "axios";

const fetchPracticeTabApi = async () => {
  try {
    const res = await axios.get("/api/scholarRoute?path=practise/view");
    return res.data;
  } catch (e) {
    console.log("err", e);
    return null;
  }
};

const fetchUpdateVideoCompletion = async (videoId: number) => {
  try {
    const res = await axios.put(
      `/api/scholarRoute?path=practise/user/watched/video/${videoId}`
    );
    return res.data;
  } catch (error) {
    console.log(error, "error");
  }
};
export const androidApis = { fetchPracticeTabApi, fetchUpdateVideoCompletion };
