import axios from "axios";

const fetchPersonalData = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=student/info/personal-info`
    );
    console.log("res", res);
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const savePersonalData = async (values: Record<string, any>) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/personal-info`,
      values
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const fetchAddressData = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=student/info/address`);
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const saveAddressData = async (values: Record<string, any>) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/address`,
      values
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const fetchPassportData = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=student/info/passport`);
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const savePassportData = async (values: Record<string, any>) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/passport`,
      values
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const fetchBackgroundData = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=student/info/background-info`
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const saveBackgroundData = async (values: Record<string, any>) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/background-info`,
      values
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const getCountryList = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=value-object/countries`
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const getLanguageList = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=value-object/languages`
    );
    console.log("language", res);
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

/*----------- Academic details - start ---------- */
const fetchAcademicData = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=student/info/academic-info`
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const saveAcademicData = async (values: Record<string, any>) => {
  console.log("POST /info/academic-info body --->", values);
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/academic-info`,
      values
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const fetchEducationData = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=student/info/education`
    );
    console.log("res", res.data);
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const saveEducationData = async (values: Record<string, any>) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/education`,
      values
    );

    console.log("save education", res.data);
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

/*----------- Academic details - end ---------- */

/*----------- Emergency contact details - start ---------- */
const fetchEmergencyContactData = async () => {
  console.log("GET /info/emergency Request");
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=student/info/emergency`
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const saveEmergencyContactData = async (values: Record<string, any>) => {
  console.log("POST /info/emergency body --->", values);
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/emergency`,
      values
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};
/*----------- Emergency contact details  - end ---------- */

/*----------- Exam score details  - end ---------- */
const fetchExamScore = async () => {
  console.log("GET info/exams Request");
  try {
    const res = await axios.get(`/api/scholarRoute?path=student/info/exams`);
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const saveExamScore = async (values: Record<string, any>) => {
  console.log("POST info/exams body --->", values);
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/exams`,
      values
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};
/*----------- Exam score details  - end ---------- */

/*----------- Work Experience details  - start ---------- */
const fetchWorkExp = async () => {
  console.log("GET info/work-ex Request");
  try {
    const res = await axios.get(`/api/scholarRoute?path=student/info/work-ex`);
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const saveWorkExp = async (values: Record<string, any>) => {
  console.log("POST info/exams body --->", values);
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/work-ex`,
      values
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};
const deleteWorkExp = async (values: Record<string, any>) => {
  console.log("POST info/exams body --->", values);
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/work-ex`,
      values
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};
/*----------- Work Experience details  - end ---------- */

/*----------- State, City apis - start ---------- */
const fetchStateList = async (country: string) => {
  console.log(`GET value-object/countries/${country}/state --->`);
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=value-object/countries/${country}/state`
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const fetchCityList = async (data: any) => {
  const { country = "", state = "" } = data || {};
  console.log(
    `GET value-object/countries/${country}/state/${state}/city ---->`
  );
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=value-object/countries/${country}/state/${state}/city`
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

/*----------- State, City apis - end ---------- */

//referee form
const fetchRefereeData = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=student/info/referee`);
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const saveRefereeData = async (values: Record<string, any>) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/referee`,
      values
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

export const updateProfileApis = {
  fetchPersonalData,
  savePersonalData,
  getCountryList,
  getLanguageList,
  fetchAddressData,
  saveAddressData,
  fetchPassportData,
  savePassportData,
  fetchBackgroundData,
  saveBackgroundData,
  //ub - start
  fetchAcademicData,
  saveAcademicData,
  fetchEmergencyContactData,
  saveEmergencyContactData,
  fetchStateList,
  fetchCityList,
  fetchExamScore,
  saveExamScore,
  //ub -end
  fetchEducationData,
  saveEducationData,

  //referee details

  fetchRefereeData,
  saveRefereeData,
  //ub- start
  fetchWorkExp,
  saveWorkExp,
  deleteWorkExp,
  //ub-end
};
