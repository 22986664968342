import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  SpotCounselingLiveCalendarResponseDto,
  SpotCounsellingCardDto,
} from 'components/SpotCounselling/Calendar/calendarInterface'
import { CalendarView } from 'DataMapper/IeltsProfile/IeltsDemo'

const IeltsDemoSliceName = 'IeltsDemo'

export interface IeltsDemoSchema {
  calendarView: CalendarView
  ieltsSlotsDetails: SpotCounselingLiveCalendarResponseDto | null
  slotsArray: Array<SpotCounsellingCardDto>
  selectedSlot: SpotCounsellingCardDto | null
  error: boolean
  hasBookedSlot: boolean
  hasNoSlots: boolean
  isLoading: boolean
}
const initialState: IeltsDemoSchema = {
  calendarView: CalendarView.LOADING,
  ieltsSlotsDetails: null,
  slotsArray: [],
  selectedSlot: null,
  error: false,
  hasBookedSlot: false,
  hasNoSlots: false,
  isLoading: true,
}

const ieltsDemoSlice = createSlice({
  name: IeltsDemoSliceName,
  initialState,
  reducers: {
    setCalendarView(state, action: PayloadAction<CalendarView>) {
      return { ...state, calendarView: action.payload }
    },
    setIeltsSlotsDetails(
      state,
      action: PayloadAction<SpotCounselingLiveCalendarResponseDto | null>,
    ) {
      return { ...state, ieltsSlotsDetails: action.payload }
    },
    setSlotsArray(state, action: PayloadAction<Array<SpotCounsellingCardDto>>) {
      return { ...state, slotsArray: action.payload }
    },
    setSelectedSlot(
      state,
      action: PayloadAction<SpotCounsellingCardDto | null>,
    ) {
      return { ...state, selectedSlot: action.payload }
    },
    setError(state, action: PayloadAction<boolean>) {
      return { ...state, error: action.payload }
    },
    setHasBookedSlot(state, action: PayloadAction<boolean>) {
      return { ...state, hasBookedSlot: action.payload }
    },
    setHasNoSlots(state, action: PayloadAction<boolean>) {
      return { ...state, hasNoSlots: action.payload }
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload }
    },
  },
})

export const fetchIeltsSlots = createAction(
  `${IeltsDemoSliceName}/fetchIeltsSlots`,
)

export const bookIeltsSlot = createAction<{
  payload: any
  isReschedule: boolean
  segmentPayload?: any
}>(`${IeltsDemoSliceName}/bookIeltsSlot`)

export const {
  setCalendarView,
  setIeltsSlotsDetails,
  setSlotsArray,
  setSelectedSlot,
  setError,
  setHasBookedSlot,
  setHasNoSlots,
  setIsLoading,
} = ieltsDemoSlice.actions

export default ieltsDemoSlice.reducer
