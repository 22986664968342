import { call, put, takeLatest } from '@redux-saga/core/effects'
import { masterclassActions } from 'components/Profile/IeltsProfile/Masterclass/redux/MasterclassActions'
import { masterclassApis } from 'components/Profile/IeltsProfile/Masterclass/redux/MasterclassApis'
import { masterclassRegistrationActions } from 'components/Profile/MasterclassRegistrationForm/redux/MasterclassRegistrationFormAction'
import { WorkshopSchema } from 'Interfaces/reducerTypes/IeltsPlusSchema'
import { sortJSON } from 'lib/sortingJson'
import moment from 'moment'
import { AnyAction } from 'redux'

function* fetchMasterclassSubscriptionStatus(
  action: AnyAction
): Generator<any, any, any> {
  try {
    const res = yield call(masterclassApis.fetchMasterclassSubscriptionStatus)
    if (res.success) {
      yield put(
        masterclassActions.fetchMasterclassSubscriptionStatusSuccess(res.data)
      )
      action.callback(res.data)
    } else {
      yield put(masterclassActions.updateError(res.message))
    }
  } catch (err: any) {
    yield put(
      masterclassActions.updateError(
        'Something went wrong. Please try after sometime'
      )
    )
  }
}

function* fetchMasterclassData(): Generator<any, any, any> {
  try {
    const res = yield call(masterclassApis.fetchMasterclassData)
    if (res.success) {
      const masterclassData: Array<WorkshopSchema> = res.data.filter(
        (data: WorkshopSchema) => moment(data.meetingStartTime) > moment()
      )
      yield put(
        masterclassActions.fetchMasterclassDataSuccess(
          sortJSON(masterclassData, 'meetingStartTime', true)
        )
      )
    } else {
      yield put(masterclassActions.updateError(res.message))
    }
  } catch (err: any) {
    yield put(
      masterclassActions.updateError(
        'Something went wrong. Please try after sometime'
      )
    )
  }
}

function* enrollForMasterclassRequested(): Generator<any, any, any> {
  yield put(masterclassActions.updateUnsubscribedCtaText('Please wait'))
  try {
    const res = yield call(masterclassApis.enrollForMasterclass)
    if (res.success) {
      yield put(masterclassActions.setMasterclassSubscriptionStatus(null))
      yield put(masterclassActions.toggleMasterclassEnrollModal(true))
      yield put(masterclassRegistrationActions.toggleMasterclassFormModal(true))
      yield put(masterclassActions.fetchMasterclassSubscriptionStatus())
    } else {
      yield put(masterclassActions.updateError(res.message))
    }
  } catch (err: any) {
    yield put(
      masterclassActions.updateError(
        'Something went wrong. Please try after sometime'
      )
    )
  } finally {
    yield put(
      masterclassActions.updateUnsubscribedCtaText(
        'Subscribe For Free Masterclass'
      )
    )
  }
}

function* fetchBeginnerMasterclassData(): Generator<any, any, any> {
  try {
    const res = yield call(masterclassApis.fetchBeginnerMasterclassData)
    if (res.success) {
      const masterclassData: Array<WorkshopSchema> = res.data.filter(
        (data: WorkshopSchema) => moment(data.meetingStartTime) > moment()
      )
      yield put(
        masterclassActions.fetchBeginnerMasterclassDataSuccess(
          sortJSON(masterclassData, 'meetingStartTime', true)
        )
      )
    } else {
      yield put(masterclassActions.updateError(res.message))
    }
  } catch (err: any) {
    yield put(
      masterclassActions.updateError(
        'Something went wrong. Please try after sometime'
      )
    )
  }
}

function* fetchMasterclassSubscriptionState(): Generator<any, any, any> {
  try {
    const res = yield call(masterclassApis.fetchMasterclassSubscriptionState)
    if (res.success) {
      yield put(masterclassActions.fetchFreeIeltsCourseSuccess(res.data))
    } else {
      yield put(masterclassActions.updateError(res.message))
    }
  } catch (err: any) {
    yield put(
      masterclassActions.updateError(
        'Something went wrong. Please try after sometime'
      )
    )
  }
}

function* fetchMasterclassRecommendationSaga(): Generator<any, any, any> {
  try {
    const res = yield call(masterclassApis.fetchMasterclassRecommendation)
    if (res.success) {
      yield put(
        masterclassActions.fetchMasterclassRecommendationSuccess(res.data)
      )
    } else {
      yield put(masterclassActions.updateError(res.message))
    }
  } catch (err: any) {
    yield put(
      masterclassActions.updateError(
        'Something went wrong. Please try after sometime'
      )
    )
  }
}

function* masterclassSaga() {
  yield takeLatest(
    'FETCH_MASTERCLASS_SUBSCRIPTION_REQUESTED',
    fetchMasterclassSubscriptionStatus
  )
  yield takeLatest(
    'FETCH_FREE_IELTS_COURSE_STATE',
    fetchMasterclassSubscriptionState
  )
  yield takeLatest('FETCH_MASTERCLASS_DATA_REQUESTED', fetchMasterclassData)
  yield takeLatest('FETCH_BEGINNER_MASTERCLASS', fetchBeginnerMasterclassData)
  yield takeLatest(
    'ENROLL_FOR_MASTERCLASS_REQUESTED',
    enrollForMasterclassRequested
  )
  yield takeLatest(
    'FETCH_MASTERCLASS_RECOMMENDATION',
    fetchMasterclassRecommendationSaga
  )
}

export default masterclassSaga
