import { reviewActions } from 'components/Ielts/Reviews/redux/ReviewsActions'
import { reviewApis } from 'components/Ielts/Reviews/redux/ReviewsApis'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchReviews(): Generator<any, any, any> {
  try {
    const res = yield call(reviewApis.fetchReviews)
    const reviewsObj = res.data.reviews.reduce((a: any, c: any) => {
      if (a[c.type] && a[c.type].length > 0) {
        a[c.type].push(c)
      } else {
        a[c.type] = []
        a[c.type].push(c)
      }
      return a
    }, {})
    yield put(reviewActions.fetchReviewsSuccess(reviewsObj))
  } catch (e: any) {
    yield put(
      reviewActions.updateError(
        'Something went wrong. Please try after sometime'
      )
    )
    console.log(e)
  }
}

function* reviewsSaga() {
  yield takeLatest('FETCH_IELTS_REVIEWS', fetchReviews)
}

export default reviewsSaga
