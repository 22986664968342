import { call, put, takeLatest } from 'redux-saga/effects'
import { IeltsDemoApi } from 'components/Ielts/IeltsDemo/redux/IeltsDemoApi'
import {
  bookIeltsSlot,
  setError,
  setHasNoSlots,
  setIeltsSlotsDetails,
  setHasBookedSlot,
  setIsLoading,
} from 'components/Ielts/IeltsDemo/redux/IeltsDemoSlice'
import { fetchIeltsSlots } from 'components/Ielts/IeltsDemo/redux/IeltsDemoSlice'
import { confirmIeltsEliteDemoSlot } from 'components/SpotCounselling/Calendar/service'
import { AnyAction } from 'redux'
import { trackEventAsync } from 'scripts/segment'

function* fetchIeltsDemoSlots(): Generator<any, any, any> {
  try {
    const res = yield call(IeltsDemoApi.fetchIeltsEliteDemoSlots)
    yield put(setIsLoading(false))
    if (res.data.success) {
      if (res.data.data.spotCounselingCalendarResponses.length > 0) {
        yield put(setIeltsSlotsDetails(res.data.data))
      } else {
        yield put(setHasNoSlots(true))
        yield put(setIeltsSlotsDetails(null))
      }
    } else {
      yield put(setError(true))
      yield put(setIeltsSlotsDetails(null))
    }
  } catch (e) {
    yield put(setIsLoading(false))
    yield put(setError(true))
    yield put(setIeltsSlotsDetails(null))
  }
}

function* bookIeltsSlotSaga(Action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      confirmIeltsEliteDemoSlot,
      Action.payload.payload,
      Action.payload.isReschedule,
    )
    if (res.success) {
      yield trackEventAsync('Event Booked', {
        ...Action.payload.segmentPayload,
      })
      yield put(setHasBookedSlot(res.data))
    } else {
      yield put(setError(true))
      yield put(setHasBookedSlot(false))
    }
  } catch (e) {
    yield put(setError(true))
    yield put(setHasBookedSlot(false))
  }
}

function* ieltsDemoSaga() {
  yield takeLatest(fetchIeltsSlots, fetchIeltsDemoSlots)
  yield takeLatest(bookIeltsSlot, bookIeltsSlotSaga)
}

export default ieltsDemoSaga
