import axios from "axios";
import { ValidationPayload } from "components/Ielts/PromoCode";

const fetchPromoCodeValidation = async (payload: ValidationPayload) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=promocode/apply/${payload.promoCode}`,
      {
        bundleId: payload.bundleId,
      }
    );
    return res.data;
  } catch (err:any) {
    console.log(err);
  }
};

export const promoCodeApi = {
  fetchPromoCodeValidation,
};
