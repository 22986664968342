export const isFunction = (data: any) => {
  if (typeof data === "function") return true;
  return false;
};

export const localStorageSetItem = (key: string, value: any): boolean => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    return false;
  }
};

export const localStorageGetItem = (key: string): any => {
  try {
    const itemWeGet = localStorage.getItem(key);
    if (itemWeGet !== null) {
      return JSON.parse(itemWeGet);
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getQueryParamStringFromObject = (object: any) => {
  let valuesString: any = Object.keys(object).map((key) =>
    object[key]?.length > 0 ? `${key}=${object[key]}` : ``
  ) as string[];

  valuesString = valuesString?.filter((n: any) => n)?.join("&");
  valuesString = valuesString.length > 0 ? `&${valuesString}` : ``;
  return valuesString;
};

export const redirectOutFromIframe = (url: string) => {
  try {
    if (window && window.top) {
      window.top.location.href = url;
    }
  } catch (err: any) {
    console.log(err);
  }
};

export const checkIfWeAreInFrame = (): boolean => {
  try {
    if (window) {
      if (window.parent.location === window.location) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  } catch (error) {
    return false;
  }
};

export function getFixedRandomNumberPerHour(
  startingNumber: number,
  endingNumber: number,
  salt?: number
) {
  let randomNumber = salt || 5144;
  const currentHour = new Date().getHours();
  const diff = endingNumber - startingNumber;
  return Math.floor((randomNumber * currentHour) % diff) + startingNumber;
}
