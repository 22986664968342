export interface TestPrepSchema {
  isLoading: boolean
  errorMessage: string
  MockTestData: MockTestDto | null
  examData: Array<ExamDashBoardDto>
  showModal: ModalTypes
  mocktestModalData: MocktestModalData | null
  initiatePayment: boolean
  unlockPriceObj: PriceDTO | null
  daignosticTests: null | DaignosticTests
  daignosticTestsError: null | boolean | string
  singleMockTest: ExamDashBoardCardDto | null
}

export interface PriceDTO {
  mrp: number
  offerPrice: number
  alreadyPaid: boolean
  currency: string
}

export enum ModalTypes {
  INITIAL = '',
  MOCKTEST_MODAL = 'MOCKTEST_MODAL',
  REGISTRATION_MODAL = 'REGISTRATION_MODAL',
}

export interface ExamDashBoardDto {
  examCategories: string
  examCards: Array<ExamDashBoardCardDto>
  color?: string
}

export interface DaignosticTests {
  speaking: DaignosticTestExamDto | null
  reading: DaignosticTestExamDto | null
  listening: DaignosticTestExamDto | null
  summary: {
    averageScore: number
    predictedBand: string
  }
}

export interface speakingResponseDto {
  overAllScore: number
  qualitativeRatingText: string
  spokenTextInHtml: string
  correctPhonemeColor: string
  firstQuestionProcessed: boolean
  secondQuestionProcessed: boolean
}
export interface ExamDashBoardCardDto {
  examId: number
  examName: string
  metaInfo?: Record<string, any>
  result: string | null
  status: Status
  isFree: boolean
}
export interface DaignosticTestExamDto extends ExamDashBoardCardDto {
  ieltsBand: null | string
  ieltsBandRange: string
}

export const ExamFormatDto: Record<string, string> = {
  'Sectional Test': 'Sectional Test',
  'Full Length Test': 'Full Length Test',
  'Diagnostic Test': 'Diagnostic Test',
}

export enum ExamFormats {
  SECTIONAL = 'Sectional Test',
  FULL_LENGTH = 'Full Length Test',
  DAIGNOSTIC = 'Diagnostic Test',
}

export enum Status {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
}
export interface MockTestDto {
  examId: number
  examName: string
  examType: ExamDto
  metaInfo?: Record<string, any>
  examTags: string
  sections: Array<SectionDto>
}

export interface SectionDto {
  sectionId: null | number
  sectionName: string
  metaInfo?: Array<string>
  sectionDurationInMinutes: number // int in minutes
  sectionTags: string
  sectionDescription: string
  questionPassages: Array<any>
}

export enum ExamDto {
  SAT = 'SAT',
  IELTS = 'IELTS',
}

export interface MocktestModalData {
  headerImage: CmsImage
  heading: string
  descriptionList: Array<DescriptionList>
  promoImage: CmsImage
  status: StatusDto
}

export enum StatusDto {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export interface DescriptionList {
  img: CmsImage
  description: string
  subDescription: string
}

export interface CmsImage {
  url: string
}
