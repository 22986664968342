import { ApolloProvider } from '@apollo/react-common'
import * as Sentry from '@sentry/nextjs'
import { ApolloClient } from 'apollo-client'
import withData from 'apollo/apollo'
import Layout from 'components/common/Layout'
import { spotCounsellingActions } from 'components/SpotCounselling/redux/SpotCounsellingActions'
import { initGA, initOptimize, logPageView } from 'ga'
import { NextPageContext } from 'next'
import App, { AppContext, AppProps } from 'next/app'
import Router, { useRouter } from 'next/router'
import Script from 'next/script'
import 'public/static/scss/GlobalStyleSheet.scss'
import 'public/static/scss/GlobalVendor.css'
import { ParsedUrlQuery } from 'querystring'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Store } from 'redux'
import { END } from 'redux-saga'
import { wrapper } from 'redux/store'
import { getCookie, saveCookie, saveUtmCookie } from 'utils/cookie-utiils'
import { saveUtmData } from 'utils/utm_utils'
import FourOhFour from './404'

const CANONICAL_DOMAIN = 'https://leapscholar.com'

interface Props {
  apollo: ApolloClient<{}>
  query: ParsedUrlQuery
  err: Error
}

declare global {
  interface Window {
    dataLayer: object
  }
}

declare module 'next' {
  export interface NextPageContext {
    /**
     * Provided by next-redux-wrapper: The redux store
     */
    store: Store
  }
}

if (process.env.SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.SENTRY_DSN,
    sampleRate: 1.0,
    autoSessionTracking: true,
  })
}

const savePromocode = (context: NextPageContext, query: any) => {
  const promocode = query && query.promocode
  if (!!promocode) saveCookie(context, 'promocode', promocode)
}

const captureAndSaveUtm = (context: NextPageContext, query: any) => {
  const utmSource = query && query.utm_source
  const utmCampaign = query && query.utm_campaign
  const utmTerm = query && query.utm_term
  const utmMedium = query && query.utm_medium
  const campaignType = query && query.campaign_type
  const adId = query && query.ad_id
  const trackingId = query && query.tracking_id
  saveUtmData(context, {
    utmSource: utmSource as string,
    utmCampaign: utmCampaign as string,
    utmMedium: utmMedium as string,
    utmTerm: utmTerm as string,
    adId: adId as string,
    campaignType: campaignType as string,
    trackingId: trackingId as string,
  })
}

const ScholarApp = ({
  Component,
  pageProps,
  apollo,
  err,
}: Props & AppProps) => {
  const router = useRouter()
  const dispatch = useDispatch()
  let timer: ReturnType<typeof setTimeout>
  useEffect(() => {
    if (router.isReady) {
      dispatch(spotCounsellingActions.fetchStage())
      initGA(pageProps?.auth?.decodedToken?.user_id?.user_id)
      logPageView(router.asPath)
    }
    const onRouteChangeComplete = () => {
      dispatch(spotCounsellingActions.fetchStage())
    }
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [])

  useEffect(() => {
    if (process.env.GA_OPTIMIZE_ID) {
      initOptimize(process.env.GA_OPTIMIZE_ID)
      fireOptimizeEvent()
    }
    const _pathSliceLength = Math.min.apply(Math, [
      Router.asPath.indexOf('?') > 0
        ? Router.asPath.indexOf('?')
        : Router.asPath.length,
      Router.asPath.indexOf('#') > 0
        ? Router.asPath.indexOf('#')
        : Router.asPath.length,
    ])
    const canonicalURL =
      CANONICAL_DOMAIN + Router.asPath.substring(0, _pathSliceLength)
    const link = document.createElement('link')
    link.rel = 'canonical'
    link.href = canonicalURL
    document.head.appendChild(link)

    if (
      ['blog', 'university', 'universities'].includes(
        document?.referrer?.split('/')[3],
      )
    ) {
      saveUtmData({}, { referer: document?.referrer as string })
    }

    const refererUrl = getCookie({}, 'refererUrl')

    if (!refererUrl) {
      saveUtmCookie({}, 'refererUrl', document?.referrer as string)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  const fireOptimizeEvent = async () => {
    if ((window as any).dataLayer) {
      await (window as any).dataLayer.push({ event: 'optimize.activate' })
    }
  }

  const isDev = process.env.NODE_ENV === 'development'

  if (pageProps?.error) {
    return (
      <Layout>
        <FourOhFour />
      </Layout>
    )
  }

  return (
    <>
      {!isDev && (
        <Script
          id="leap-tracker-clarity"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "bbvlrvwtbs");
  `,
          }}
        />
      )}
      {!isDev && (
        <Script
          id="vwoCode"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `window._vwo_code=window._vwo_code || (function() {
            var account_id=667156,
            version=1.4,
            settings_tolerance=2000,
            library_tolerance=2500,
            use_existing_jquery=false,
            is_spa=1,
            hide_element='body',
            /* DO NOT EDIT BELOW THIS LINE */
            f=false,d=document,vwoCodeEl=document.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.innerText;t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:^|;)\s?(_vis_opt_exp_\d+_combi=[^;$]*)/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp_\d+_combi=(?:\d+,?)+\s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([\d,]+)/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){window.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.createElement('style'),t=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',i=d.getElementsByTagName('head')[0];e.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&e.setAttribute('nonce',vwoCodeEl.nonce);e.setAttribute('type','text/css');if(e.styleSheet)e.styleSheet.cssText=t;else e.appendChild(d.createTextNode(t));i.appendChild(e);var n=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version+(n?'&c='+n:''));return settings_timer}};window._vwo_settings_timer = code.init();return code;}());`,
          }}
        />
      )}
      <Script
        id="leap-tracker-gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${process.env.GTM_ID}');`,
        }}
      />

      <Script
        id="gtm-city"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GTM_ID_CITY}`}
      />
      <Script
        id="leap-tracker-gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${process.env.GTM_ID_CITY}');`,
        }}
      />
      <ApolloProvider client={apollo}>
        {/* Workaround for https://github.com/vercel/next.js/issues/8592  */}
        <Component {...pageProps} err={err} />
      </ApolloProvider>
    </>
  )
}

ScholarApp.getInitialProps = async (
  appContext: AppContext & NextPageContext,
) => {
  captureAndSaveUtm(appContext.ctx, appContext.ctx.query)
  savePromocode(appContext.ctx, appContext.ctx.query)
  const appProps = await App.getInitialProps(appContext)

  if (appProps.pageProps?.error?.statusCode && appContext.ctx.res) {
    appContext.ctx.res.statusCode = appProps.pageProps.error.statusCode
  }

  if (appContext.ctx.req && appContext.Component.getInitialProps) {
    appContext.ctx.store.dispatch(END)
    await (appContext.ctx.store as any).sagaTask.toPromise()
  }
  return {
    query: appContext.ctx.query,
    ...appProps,
  }
}

export default wrapper.withRedux(withData(ScholarApp))
