import { WorkshopSchema } from 'Interfaces/reducerTypes/IeltsPlusSchema'
import { NextPageContext } from 'next'

export const UpcomingType = {
  UPCOMING_IELTS_FETCH_REQUESTED: 'UPCOMING_IELTS_FETCH_REQUESTED',
  UPCOMING_IELTS_FETCH_SUCCESS: 'UPCOMING_IELTS_FETCH_SUCCESS',
  UPCOMING_IELTS_FETCH_FAIL: 'UPCOMING_IELTS_FETCH_FAIL',
  UPCOMING_IELTS_FETCH_CLIENT_REQUESTED:
    'UPCOMING_IELTS_FETCH_CLIENT_REQUESTED',
  IELTS_FOUNDATION_BATCH_FETCH_REQUESTED:
    'IELTS_FOUNDATION_BATCH_FETCH_REQUESTED',
  IELTS_FOUNDATION_BATCH_FETCH_SUCCESS: 'IELTS_FOUNDATION_BATCH_FETCH_SUCCESS',
  IELTS_PLUS_BATCH_FETCH_SUCCESS: 'IELTS_PLUS_BATCH_FETCH_SUCCESS',
  IELTS_PREMIUM_BATCH_FETCH_SUCCESS: 'IELTS_PREMIUM_BATCH_FETCH_SUCCESS',
  NUDGE_FETCH_SUCCESS: 'NUDGE_FETCH_SUCCESS',
  TOGGLE_UPCOMING_IELTS_BATCHES_MODAL: 'TOGGLE_UPCOMING_IELTS_BATCHES_MODAL',
}

const fetchUpcomingIeltsBatch = (payload: NextPageContext) => ({
  type: UpcomingType.UPCOMING_IELTS_FETCH_REQUESTED,
  payload,
})

const fetchUpcomingIeltsBatchClient = () => ({
  type: UpcomingType.UPCOMING_IELTS_FETCH_CLIENT_REQUESTED,
})

const fetchIeltsFoundationBatchRequest = () => ({
  type: UpcomingType.IELTS_FOUNDATION_BATCH_FETCH_REQUESTED,
})

const fetchIeltsFoundationBatchSuccess = (payload: any) => ({
  type: UpcomingType.IELTS_FOUNDATION_BATCH_FETCH_SUCCESS,
  payload,
})

const fetchIeltsPlusBatchSuccess = (payload: any) => ({
  type: UpcomingType.IELTS_PLUS_BATCH_FETCH_SUCCESS,
  payload,
})

const fetchUpcomingIeltsSuccess = (payload: Array<WorkshopSchema>) => ({
  type: UpcomingType.UPCOMING_IELTS_FETCH_SUCCESS,
  payload,
})

const fetchNudgeSuccess = (payload: any) => ({
  type: UpcomingType.NUDGE_FETCH_SUCCESS,
  payload,
})

const fetchUpcomingIeltsFail = (message: string) => ({
  type: UpcomingType.UPCOMING_IELTS_FETCH_FAIL,
  payload: message,
})

const toggleUpcomigIeltsBatchModal = (payload: boolean) => ({
  type: UpcomingType.TOGGLE_UPCOMING_IELTS_BATCHES_MODAL,
  payload,
})

export const upcomingIeltsBatchesAction = {
  fetchUpcomingIeltsBatch,
  fetchUpcomingIeltsSuccess,
  fetchUpcomingIeltsFail,
  fetchUpcomingIeltsBatchClient,
  fetchNudgeSuccess,
  fetchIeltsFoundationBatchRequest,
  fetchIeltsFoundationBatchSuccess,
  fetchIeltsPlusBatchSuccess,
  toggleUpcomigIeltsBatchModal,
}
