import axios from "axios";

const fetchNoticeRequest = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=profile/post/notice`);
    console.log("notice response", res.data);
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

export const noticeApi = {
  fetchNoticeRequest,
};
