import axios from "axios";

const submitFirstStepRequest = async (data: any) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/preparation-stage/${data}?step=1`
    );
    if (res.data.success) {
      return res.data;
    } else {
      return false;
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const fetchInitialDataRequest = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=student/info/onboard-info/v2`
    );
    if (res.data.success) {
      return res;
    } else {
      return false;
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const submitEducationDetailsRequest = async (data: any) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/education-details?step=3`,
      data
    );
    if (res.data.success) {
      return res.data;
    } else {
      return false;
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};
const deleteCountryFromFormData = async (id: any) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/country-details/delete/${id}`
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};
const submitCollegeFormsRequest = async (data: any) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/country-details/update/v2?step=2`,
      data
    );
    if (res.data.success) {
      return res.data;
    } else {
      return false;
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const submitExamFormRequest = async (data: any) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/info/misc-details?step=4`,
      data
    );
    if (res.data.success) {
      return res.data;
    } else {
      return false;
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};
const fetchPreferredPrograms = async () => {
  try {
    const res = await axios.get(
      `/api/seoRoute?path=course/substream/listAll`
    );
    if (res.data.success) {
      return res.data;
    } else {
      return false;
    }
  } catch (err) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};
const getPreferredLocationLists = async (data: any) => {
  try {
    const res = await axios.post(`/api/seoRoute?path=cities/suggestions`, data);
    if (res?.data?.success) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};
const getPreferredProgramCourseLists = async (data: any) => {
  try {
    const res = await axios.post(
      `/api/seoRoute?path=course/substream/suggestions`,
      data
    );
    if (res?.data?.success) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};
const getPreferredCountryIdLists = async () => {
  try {
    const res = await axios.get(`/api/seoRoute?path=country/listAll`);
    if (res?.data?.success) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const fetchCityListFromCountry = async (country: string) => {
  const getCountry = () => {
    let c = "";
    if (country == "USA") {
      c = "United States";
    } else if (country == "UK") {
      c = "United Kingdom";
    } else {
      c = country;
    }
    return c;
  };
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=value-object/countries/${getCountry()}/cities`
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const getAllCountryData = async () => {
  try {
    const res = await axios.get(`/api/seoRoute?path=country/listAll`);
    if (res?.data?.success) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (err) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

export const onboardingApi = {
  submitFirstStepRequest,
  fetchInitialDataRequest,
  submitEducationDetailsRequest,
  submitExamFormRequest,
  submitCollegeFormsRequest,
  fetchCityListFromCountry,
  deleteCountryFromFormData,
  fetchPreferredPrograms,
  getPreferredLocationLists,
  getPreferredCountryIdLists,
  getPreferredProgramCourseLists,
  getAllCountryData,
};
