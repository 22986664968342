import axios from 'axios'
import { getCookie } from 'utils/cookie-utiils'

const generateOtp = async (
  countryCode: string,
  country: string,
  phone: string,
  whatsAppConsent: boolean
) => {
  const appName = process.env.AUTH_APP_NAME
  try {
    const res = await axios.post(
      `/api/auth?path=otp/generate/${appName}/%2B${countryCode}${phone}/&nocache=${new Date().getTime()}&isWebRequest=${true}&whatsappConsent=${whatsAppConsent}`,
      null,
      {
        params: {
          country_code: country,
        },
      }
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const validateOtp = async (
  corelationId: string,
  otp: string,
  whatsAppConsent: boolean
) => {
  const appName = process.env.AUTH_APP_NAME
  try {
    const res = await axios.post(
      `/api/auth?path=otp/validate/${appName}/${corelationId}/${otp}/${whatsAppConsent}&nocache=${new Date().getTime()}&isWebRequest=${true}`
    )
    if (res?.data?.success) {
      localStorage.setItem('userDataCollectedByChatBot', 'true')
    }
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const resendOtp = async (
  country: string,
  corelationId: string,
  whatsAppConsent: boolean
) => {
  const appName = process.env.AUTH_APP_NAME
  try {
    const res = await axios.post(
      `/api/auth?path=otp/resend/${appName}/${corelationId}&nocache=${new Date().getTime()}&isWebRequest=${true}&whatsappConsent=${whatsAppConsent}`,
      null,
      {
        params: {
          country_code: country,
        },
      }
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const registerUser = async (flowType: string) => {
  const refererUrl = getCookie(null, 'refererUrl')
  try {
    const res = await axios.post('/api/register', {
      flowType,
      refererUrl: refererUrl,
    })
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const verifyTruecaller = async (
  requestId: string,
  whatsAppSubscription: boolean
) => {
  try {
    const res = await axios.post(`/api/auth?path=truecaller/verify`, {
      requestId,
      whatsAppSubscription,
    })
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const initTruecallerLogin = async (requestId: string) => {
  const app = process.env.AUTH_APP_NAME
  try {
    const res = await axios.post(`/api/auth?path=truecaller/initTruecaller`, {
      requestId,
      app,
    })
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

export const loginApis = {
  generateOtp,
  validateOtp,
  registerUser,
  resendOtp,
  initTruecallerLogin,
  verifyTruecaller,
}
