import axios from "axios";

interface uploadEssayApiPayloadSchema {
  batchId: number;
  file: File;
}

type replaceEssayApiPayloadSchema = uploadEssayApiPayloadSchema & {
  essayId: number;
};

const uploadEssayApi = async (
  payload: uploadEssayApiPayloadSchema
): Promise<any> => {
  try {
    const { batchId, file } = payload;
    const formData = new FormData();
    formData.append("file", file);
    const res = await axios.post(
      `/api/uploadFile?name=workshop/essay/upload/${batchId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res.data.success) {
      return { success: true, payload: res.data.data };
    } else {
      return { success: false, payload: res.data.message };
    }
  } catch (err:any) {
    return { success: false, payload: "res.data.message" };
  }
};

const replaceEssayApi = async (
  payload: replaceEssayApiPayloadSchema
): Promise<any> => {
  try {
    const { batchId, file, essayId } = payload;
    const formData = new FormData();
    formData.append("file", file);
    const res = await axios.post(
      `/api/uploadFile?name=workshop/essay/upload/${batchId}/${essayId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res.data.success) {
      return { success: true, payload: res.data.data } as any;
    } else {
      return { success: false, payload: res.data.message } as any;
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
};

const fetchEssayApi = async (batchId: any): Promise<any> => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=workshop/essay/${batchId}
      `
    );
    if (res.data.success) {
      return { success: true, payload: res.data.data };
    } else {
      return { success: false, payload: res.data.message };
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
};

export const essayApi = {
  uploadEssayApi,
  fetchEssayApi,
  replaceEssayApi,
};
