import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";
import { chatActionsConstants } from "./chatActions";
import { chatApis } from "components/Chat/redux/chatApis";

// sagas
function* feedbackResponse(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(chatApis.feedbackResponse, action.payload);

    if (res?.data?.success) {
      yield put({ type: chatActionsConstants.FEEDBACK_RESPONSE_SUCCESS });
    }
  } catch (e: any) {
    console.error(e);
    yield put({ type: chatActionsConstants.FEEDBACK_RESPONSE_ERROR });
  }
}

function* ratingResponse(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(chatApis.ratingResponse, action.payload);

    if (res?.data?.success) {
      yield put({ type: chatActionsConstants.RATING_RESPONSE_SUCCESS });
    }
  } catch (e: any) {
    console.error(e);
    yield put({ type: chatActionsConstants.RATING_RESPONSE_ERROR });
  }
}

function* unsatisfiedResponse(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(chatApis.unsatisfiedResponse, action.payload);

    if (res?.data?.success) {
      yield put({ type: chatActionsConstants.UNSATISFACTORY_RESPONSE_SUCCESS });
    }
  } catch (e: any) {
    console.error(e);
    yield put({ type: chatActionsConstants.UNSATISFACTORY_RESPONSE_ERROR });
  }
}

function* chatSaga() {
  yield takeLatest(chatActionsConstants.FEEDBACK_RESPONSE, feedbackResponse);
  yield takeLatest(chatActionsConstants.RATING_RESPONSE, ratingResponse);
  yield takeLatest(
    chatActionsConstants.UNSATISFACTORY_RESPONSE,
    unsatisfiedResponse
  );
}

export default chatSaga;
