import gql from "graphql-tag";

export const FETCH_EVENT_DATA = gql`
  query ($slugArray: [String!]) {
    events(where: { slug: $slugArray }) {
      name
      slug
      description
      previous_event
      Agenda {
        agenda
        sequence
      }
      poster {
        url
      }
      thumbnail {
        url
      }
      event_tags_v2 {
        tag {
          Name
          type
        }
        visible
      }
      speakers {
        name
        image {
          url
        }
        headline
        comment
        linkedin
      }
      displayEventAsBanner
    }
  }
`;

export const FETCH_EVENT_IMAGES = gql`
  query ($slugArray: [String!]) {
    events(where: { slug: $slugArray }) {
      name
      slug
      poster {
        url
      }
      thumbnail {
        url
      }
    }
  }
`;

export const FETCH_SINGLE_EVENT_DATA = gql`
  query ($slugString: String!) {
    events(where: { slug: $slugString }) {
      name
      slug
      description
      previous_event
      Agenda {
        agenda
        sequence
      }
      poster {
        url
      }
      thumbnail {
        url
      }
      event_tags_v2 {
        tag {
          Name
          type
        }
        visible
      }
      speakers {
        name
        image {
          url
        }
        headline
        comment
        linkedin
      }
    }
  }
`;
