import axios from "axios";

const fetchInitialFormValues = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=workshop/master-class`);
    return res.data;
  } catch (err:any) {
    console.log(err);
  }
}

export const registrationFormApi = {
  fetchInitialFormValues
}