import { makeStyles } from '@material-ui/styles'
import { toast } from 'react-toastify'
import { trackIeltsClick } from 'scripts/segment'
import { ieltsPageName } from 'utils/events-utils'

export const formProgressBarUseStyles = makeStyles({
  root: {
    width: '100%',
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: 'transparent',
      height: '12px',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#7FBF7D',
      height: '12px',
    },
  },
})

export const getProductCategory = (slotType: number) => {
  if (slotType) {
    if (slotType === 1) {
      return 'Slot 1'
    }

    if (slotType === 2) {
      return 'Slot 2'
    }

    if (slotType === 7) {
      return 'Slot 7'
    }

    return 'Slot 3'
  } else {
    return 'regular_counselling'
  }
}

export const returnCountry = (country: string) => {
  switch (country) {
    case 'UK':
      return 'UK'
    case 'CANADA':
      return 'Canada'
    case 'USA':
      return 'USA'
    case 'AUSTRALIA':
      return 'Australia'
    case 'IRELAND':
      return 'Ireland'
    default:
      return 'Other'
  }
}

export const SpotLeadCaptureStepsPage: { [key: number]: string } = {
  1: 'Prog_Form_P1',
  2: 'Prog_Form_P2',
  3: 'Prog_Form_P3',
  4: 'Prog_Form_P4',
  5: 'Prog_Form_P5',
  6: 'SEM v5 - CTA Page',
}

export const LeadCaptureStepsPage: { [key: string]: string } = {
  preferredRequirement: 'Prog_Form_P1',
  preferredProgram: 'Prog_Form_P2',
  preferredIntake: 'Prog_Form_P3',
  ieltsStatus: 'Prog_Form_P4',
  highestLevelEducation: 'Prog_Form_P5',
  highestEducationPassoutYear: 'Prog_Form_P6',
  grades: 'Prog_Form_P7',
  name: 'SEM v5 - CTA Page',
  admitStatus: 'Prog_Form_P9',
  ielts_toefl_score: 'Prog_Form_P10',
  howLeapHelp: 'Prog_Form_P11',
  ieltsBookConf: 'Prog_Form_P12',
}

export const V8LeadCaptureStepsPage: { [key: string]: string } = {
  home: 'SEM v8 - Home Screen',
  help: 'SEM v8 - First Question Screen',
  preferredIntake: 'SEM v8 - Second Question',
  ieltsStatus: 'SEM v8 - Third Question',
  highestLevelEducation: 'SEM v8 - Fourth Question',
  highestEducationPassoutYear: 'SEM v8 - Fifth Question',
  grades: 'SEM v8 - Sixth Question',
  preferredProgram: 'SEM v8 - Seventh Question',
  admitStatus: 'SEM v8 - Eigth Question',
  ielts_toefl_score: 'SEM v8 - Ninth Question',
  howLeapHelp: 'SEM v8 - Tenth Question',
  ieltsBookConf: 'SEM v8 - Elevnth Question',
  name: 'SEM v8 - CTA Page',
}

export const V8IntakeMapper: { [key: string]: string } = {
  JAN_2023: 'January 2023',
  SEP_2023: 'September 2023',
}

export const geturlToRedirect = () => {
  if (process.env.SCHOLAR_URL === 'https://jarvis-api.leapscholar.com') {
    return 'https://jarvis.leapscholar.com'
  } else if (process.env.SCHOLAR_URL === 'https://asgard-api.leapscholar.com') {
    return 'https://asgard.leapscholar.com'
  } else if (process.env.SCHOLAR_URL === 'https://mario-api.leapscholar.com') {
    return 'https://mario.leapscholar.com'
  } else if (process.env.SCHOLAR_URL === 'https://popeye-api.leapscholar.com') {
    return 'https://popeye.leapscholar.com'
  } else if (
    process.env.SCHOLAR_URL === 'https://spartan-api.leapscholar.com'
  ) {
    return 'https://spartan.leapscholar.com'
  } else if (process.env.SCHOLAR_URL === 'https://ultron-api.leapscholar.com') {
    return 'https://ultron.leapscholar.com'
  } else if (process.env.SCHOLAR_URL === 'https://api.leapscholar.com') {
    return 'https://leapscholar.com'
  } else if (process.env.SCHOLAR_URL === 'https://thanos-api.leapscholar.com') {
    return 'https://thanos.leapscholar.com'
  } else if (process.env.SCHOLAR_URL === 'https://arya-api.leapscholar.com') {
    return 'https://arya.leapscholar.com'
  } else if (process.env.SCHOLAR_URL === 'https://droid-api.leapscholar.com') {
    return 'https://droid.leapscholar.com'
  } else if (
    process.env.SCHOLAR_URL === 'http://localhost:3000' ||
    process.env.NODE_ENV === 'development'
  ) {
    return 'http://localhost:3000'
  } else if (
    process.env.SCHOLAR_URL === 'https://staging-api.leapscholar.com'
  ) {
    return 'https://staging.leapscholar.com'
  } else {
    return 'https://leapscholar.com'
  }
}

//ielts reward banner tracking

export const viewRewardClick = (ieltsUserCategory: string | string[]) => {
  trackIeltsClick(ieltsPageName.COUNSELLING_PAGE, {
    pageName: 'Counselling Page',
    widgetName: 'Congratulations Modal',
    widgetFormat: 'Card',
    contentName: 'See all Rewards',
    eventCategory: ieltsUserCategory,
  })
}

//autoNextLogicSpotCouncellingForm

export const autoNextLogicSpotCouncellingForm = (
  currentFormStep: number,
  setCurrentFormStep: any,
  changedValues: any,
) => {
  if (currentFormStep === 1 && changedValues?.preferredCountry) {
    setCurrentFormStep(currentFormStep + 1)
  }
  if (
    currentFormStep === 2 &&
    changedValues?.preferredIntake &&
    changedValues?.preferredProgram
  ) {
    setCurrentFormStep(currentFormStep + 1)
  }
  if (
    currentFormStep === 3 &&
    changedValues?.highestLevelEducation &&
    changedValues?.highestEducationPassoutYear
  ) {
    setCurrentFormStep(currentFormStep + 1)
  }
}

//name validator

export const nameValidator = (name: string) => {
  if (!name.trim()) {
    return false
  }
  return /^[A-Za-z\s]+$/.test(name)
}

//extra space trimming from name

export const nameExtraSpacetriming = (name: string) => {
  //replacing extra space between name if any
  return name.replace(/\s+/g, ' ').trim()
}

//Spot Profile data mapper

export const spotProfileDataMapper: any = {
  TENTH: '10th Standard',
  TWELFTH: '12th Standard',
  BACHELORS: 'Bachelor’s Degree',
  MASTERS: 'Master’s Degree',
  EXAM_BOOKED: 'Scheduled',
  ALREADY_GIVEN: 'Already given',
  NOT_DECIDED: 'Not decided',
  PLANING_TO_BOOK: 'Planning to Give',
}

export const validateHowToHelpVal = (
  v: string[] | undefined,
  limit: number,
) => {
  if (v) {
    if (v.length > limit) {
      v.splice(-1, 1)
      toast.warn(`Maximum ${limit} selections allowed`)
    } else {
    }
  }
  if (!v?.length) return 'Please select atleast one option'
}

export const validateHowToHelpValSem = (
  v: string[] | undefined,
  limit: number,
) => {
  if (v) {
    if (v.length > limit) {
      v.splice(-1, 1)
      return `Maximum ${limit} selections allowed`
    } else {
    }
  }
  if (!v?.length) return 'Please select atleast one option'
}

export const isObjectEmpty = (obj: Object): boolean => {
  for (const i in obj) return false
  return true
}

export const getDependentQuestionFieldName: any = (
  formJson: any,
  questionId: any,
) => {
  for (let i = 0; i < formJson?.fields.length; i++) {
    if (formJson?.fields[i].question_id === questionId) {
      return formJson?.fields[i].fieldValue
    }
  }
  return 'df'
}

export const indexCal = (formJson: any, currentFormStep: number) => {
  const modifiedFormStepQuestionDistribution = [
    ...formJson?.page_distribution?.slice(0, currentFormStep - 1),
  ]
  const sumTillCurrentIndex = modifiedFormStepQuestionDistribution?.reduce(
    (partialSum, a) => partialSum + a,
    0,
  )

  const startIndex = currentFormStep > 1 ? sumTillCurrentIndex : 0
  const endIndex = startIndex + formJson?.page_distribution[currentFormStep - 1]

  return [startIndex, endIndex]
}

export const getfirstQuestionToDisplay = (json: any) => {
  const fieldObject = json?.fields?.find(
    (field: any) => field?.question_id === 1,
  )
  return fieldObject?.fieldValue
}

export const autonextDecision = (
  dublicateFormValues: any,
  formJson: any,
  currentFormStep: number,
  setCurrentFormStep: Function,
  isClickingBack: boolean,
) => {
  let isNormalAutoNextRequired = true
  const startInd = indexCal(formJson, currentFormStep)[0]
  const endIndex = indexCal(formJson, currentFormStep)[1]
  for (let i = startInd; i < endIndex; i++) {
    if (
      formJson?.fields[i]?.default_visibility &&
      !(
        formJson?.fields[i]?.type === 'numberInput' ||
        formJson?.fields[i]?.type == 'textInput'
      )
    ) {
      if (dublicateFormValues[formJson?.fields[i]?.fieldValue]) {
        continue
      } else {
        isNormalAutoNextRequired = false
      }
    } else {
      isNormalAutoNextRequired = false
      return
    }
  }

  if (isNormalAutoNextRequired && !isClickingBack) {
    setCurrentFormStep(currentFormStep + 1)
  }
}

export const forceAutonextDecision = (
  formJson: any,
  currentFormStep: number,
  formValues: any,
  setCurrentFormStep: Function,
  isClickingBack: boolean,
) => {
  let isForceAutoNextRequired = false
  const startInd = indexCal(formJson, currentFormStep)[0]
  const endIndex = indexCal(formJson, currentFormStep)[1]

  for (let i = startInd; i < endIndex; i++) {
    if (formJson?.fields[i]?.default_visibility) {
      return
    } else {
      const selectedField =
        formJson?.fields[i]?.visibility_dependency?.dependencies
      let isThisFieldVisible = true
      for (let j = 0; j < selectedField?.length; j++) {
        const questionId = selectedField[j]?.question_id

        if (
          selectedField[j]?.value?.includes(
            formValues[getDependentQuestionFieldName(formJson, questionId)],
          ) ||
          selectedField[j]?.value.length == 0
        ) {
          continue
        } else {
          isThisFieldVisible = false
        }
      }
      if (!isThisFieldVisible) {
        isForceAutoNextRequired = true
      } else {
        return
      }
    }
  }

  if (isForceAutoNextRequired && !isClickingBack) {
    setCurrentFormStep(currentFormStep + 1)
  } else if (isForceAutoNextRequired && isClickingBack) {
    setCurrentFormStep((prev: any) => prev - 1)
  }
}

export const semFormBackNextDecision = (
  formJson: any,
  values: any,
  currentQuestion: string,
  direction: string,
) => {
  let nextQuestionHolder = ''
  for (let i = 0; i < formJson?.fields?.length; i++) {
    if (currentQuestion === formJson?.fields[i]?.fieldValue) {
      if (formJson?.fields[i]?.visibility_dependency?.hasDependentQuestion) {
        if (direction === 'next') {
          for (
            let j = 0;
            j <
            formJson?.fields[i]?.visibility_dependency?.nextQuestionRequirment
              ?.length;
            j++
          ) {
            const element =
              formJson?.fields[i]?.visibility_dependency
                ?.nextQuestionRequirment[j]
            let hasAllValueIncluded = true

            element?.prevQuestion?.forEach((ques: string) => {
              if (!element?.value.includes(values[ques])) {
                hasAllValueIncluded = false
              }
            })
            if (hasAllValueIncluded || element?.value?.length === 0) {
              nextQuestionHolder = element?.nextQuestion
              break
            }
          }
        } else {
          for (
            let j = 0;
            j <
            formJson?.fields[i]?.visibility_dependency?.prevQuestionRequirment
              ?.length;
            j++
          ) {
            const element =
              formJson?.fields[i]?.visibility_dependency
                ?.prevQuestionRequirment[j]
            let hasAllValueIncluded = true
            element?.question?.forEach((ques: string) => {
              if (!element?.value?.includes(values[ques])) {
                hasAllValueIncluded = false
              }
            })
            if (element?.question === 'help') {
              nextQuestionHolder = 'help'
            } else if (hasAllValueIncluded || element?.value?.length === 0) {
              nextQuestionHolder = element?.prevQuestion
              break
            }
          }
        }
      }
    }
  }
  return nextQuestionHolder
}

export const getLeadCohort = (leadDetails: any, slotId: number | string) => {
  // for cohort 4
  if (
    (slotId == 1 || slotId == 2) &&
    (leadDetails?.ieltsStatus === 'NOT_DECIDED' ||
      leadDetails?.ieltsStatus === 'PLANING_TO_BOOK') &&
    (leadDetails?.passportStatus === 'PENDING' ||
      leadDetails?.workingDegreeStatus == false ||
      leadDetails?.workingMarksheetStatus == false)
  ) {
    return 'COHORT_4'
  }
}
