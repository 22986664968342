import axios from "axios";

// Before Login
const getBeginnerMasterClassData = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=v2/workshop/BEGINNER_MASTER_CLASS/0/12`);
    return res.data;
  } catch (err) {
    console.log(err);     
  }
};

// Before Login
const getClassicMasterClassData = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=v2/workshop/MASTER_CLASS/0/12`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const masterclassTypeApi = {
  getBeginnerMasterClassData,
  getClassicMasterClassData
};
