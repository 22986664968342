import axios from "axios";

const requestStories = () => {
  return axios.get(`/api/scholarRoute?path=stories/get`, {
    headers: {
      "X-API-VERSION": "3",
    },
  });
};

const requestUpdateStoryStatus = (storyId: number) => {
  return axios.post(`/api/scholarRoute?path=stories/update/story/SEEN/${storyId}`);
}

export const storiesApis = {
  requestStories,
  requestUpdateStoryStatus,
};
