import axios from "axios";

const fetchIeltsPlusData = async () => {
  try {
    const res = await axios.get("/api/scholarRoute?path=student/ielts/fee/v2");
    if (res.data.success) {
      return res.data.data;
    } else {
      throw new Error(res.data.message);
    }
  } catch (err: any) {
    console.log(err);
    throw new Error("Something went wrong. Please try after sometime");
  }
};

const enrollStudentToIeltsPlus = async (batchId: number) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/workshop/1/${batchId}/enroll`
    );
    if (res.data.success) {
      return true;
    } else {
      throw new Error(res.data.message);
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
};

const fetchObjects = async (batchId: string) => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=workshop/objects/${batchId}`
    );
    return res;
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
};

const fetchEnrolledBatches = async () => {
  try {
    const headers = {
      "X-API-VERSION": "4",
    };
    const res = await axios.get(
      `/api/scholarRoute?path=student/enrolled/batches`,
      { headers }
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const fetchRewardList = async (batchId: number) => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=reward/list/${batchId}`
    );
    return res.data;
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
};

export const ieltsPlusApi = {
  fetchIeltsPlusData,
  enrollStudentToIeltsPlus,
  fetchObjects,
  fetchEnrolledBatches,
  fetchRewardList,
};
