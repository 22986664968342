import axios from "axios";

const fetchRecordings = async (batchId: string) => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=video/batch/${batchId}`,
      {
        headers: {
          "X-API-VERSION": "3"
        }
      }
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      throw new Error(res.data.message);
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
};

export const fetchVideo = async (payload: any) => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=video/batch/${payload.batchId}/${payload.videoId}`,
      {
        headers: {
          "X-API-VERSION": "3"
        }
      }
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      throw new Error(res.data.message);
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
};

export const classRecordingsApi = {
  fetchRecordings,
  fetchVideo
};
