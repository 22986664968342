import { BatchSchema } from 'Interfaces/reducerTypes/BatchDetailsSchema'
import moment from 'moment'

export const formatDate = (date: string): string => {
  return new Intl.DateTimeFormat('en-GB', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(new Date(date))
}

export const formatTime = (
  startHour: number,
  startMinute: number,
  endHour: number,
  endMinute: number,
) => {
  const startSuffix = startHour >= 12 ? 'pm' : 'am'
  const endSuffix = endHour >= 12 ? 'pm' : 'am'
  const newStartHour = startHour > 12 ? startHour - 12 : startHour
  const newEndHour = endHour > 12 ? endHour - 12 : endHour
  let newStartMinute = startMinute as unknown
  let newEndMinute = endMinute as unknown
  String(newStartMinute).length === 1
    ? (newStartMinute = `0${newStartMinute}`)
    : newStartMinute
  String(newEndMinute).length === 1
    ? (newEndMinute = `0${newEndMinute}`)
    : newEndMinute
  return `${newStartHour}:${newStartMinute}${startSuffix} - ${newEndHour}:${newEndMinute}${endSuffix}`
}

export const timeDiffInMin = (startTime: string, endTime: string) => {
  const start = moment(startTime)
  const end = moment(endTime)
  return moment.duration(end.diff(start)).asMinutes()
}

export const getDayDiff = (time: string): 'Tomorrow' | any => {
  const timeDff = moment(time)
    .startOf('day')
    .diff(moment(Date.now()).startOf('day'), 'days')
  if (timeDff < -1) return `${Math.abs(timeDff)} days ago`
  if (timeDff < 0) return 'Yesterday'
  if (timeDff == 0) return 'Today'
  if (timeDff == 1) return 'Tomorrow'
  else return `${timeDff} days`
}

export const getDayDiffInNumber = (date1: string, date2: string): number => {
  const daysDifference = moment(date1)
    .startOf('day')
    .diff(moment(date2).startOf('day'), 'days')
  return daysDifference
}
export const formatTimeIn12Hours = (hour: number, minute: number) => {
  const suffix = hour >= 12 ? 'pm' : 'am'
  let newHour = hour > 12 ? hour - 12 : hour
  if (hour === 0) newHour = 12
  let newMinute = minute as unknown
  String(newMinute).length === 1 ? (newMinute = `0${newMinute}`) : newMinute
  return {
    suffix,
    newHour,
    newMinute,
  }
}
type hoursAndMinutes = { hours: number; minutes: number }
type getTimeDiffWithHoursAndMinutes = (
  firstTime: hoursAndMinutes,
  secondTime: hoursAndMinutes,
) => hoursAndMinutes

export const getTimeDiffWithHoursAndMinutes: getTimeDiffWithHoursAndMinutes = (
  firstTime,
  secondTime,
) => {
  return {
    hours: firstTime.hours - secondTime.hours,
    minutes: firstTime.minutes - secondTime.minutes,
  }
}

export const isThereAClassToday = (batchData: BatchSchema): boolean => {
  const daysWithNumbers: any = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  }
  const todayDay = new Date().getDay()
  let [firstDayInWeeklySchedule, lastDayInWeeklySchedule] =
    batchData?.weeklySchedule.split('-')
  firstDayInWeeklySchedule = daysWithNumbers[firstDayInWeeklySchedule]
  lastDayInWeeklySchedule = daysWithNumbers[lastDayInWeeklySchedule]
  if (
    todayDay >= parseInt(firstDayInWeeklySchedule) &&
    todayDay <= parseInt(lastDayInWeeklySchedule)
  ) {
    return true
  }
  return false
}

export const getFullDate = (date: string, hour: number, min: number) => {
  const currentDate = new Date(date)
  currentDate.setHours(hour)
  currentDate.setMinutes(min)
  return currentDate
}

export const isClassOngoingNow = (batchData: BatchSchema): boolean => {
  const startTimeInUnix = getFullDate(
    new Date().toDateString(),
    batchData?.startHour,
    batchData?.startMinute,
  )
  const endTimeInUnix = getFullDate(
    new Date().toDateString(),
    batchData?.endHour,
    batchData?.endMinute,
  )
  const currentTime = new Date()
  return startTimeInUnix <= currentTime && currentTime <= endTimeInUnix
}

export const isClassStarted = (batchData: BatchSchema): boolean => {
  const startTimeInUnix = getFullDate(
    new Date().toDateString(),
    batchData?.startHour,
    batchData?.startMinute,
  )

  const currentTime = new Date()

  if (startTimeInUnix >= currentTime) {
    return false
  }

  return true
}

export const checkIfDateIsTodayOrTommorow = (date: string): boolean => {
  const tommorowDateString = new Date(
    new Date().setDate(new Date().getDate() + 1),
  ).toDateString()
  const todayDateString = new Date().toDateString()
  const givenDateString = new Date(date).toDateString()
  if (
    givenDateString == todayDateString ||
    givenDateString === tommorowDateString
  ) {
    return true
  }
  return false
}

export const addDaysToDate = (date: string, days: number) => {
  const newDate = new Date(date)
  return new Date(newDate.setDate(newDate.getDate() + days))
}

export const getDay = (date: string, showTodayString?: boolean) => {
  const weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const currentDate = new Date().toLocaleDateString()
  const dateString = new Date(date)
  const TODAY = 'Today'
  if (currentDate === dateString.toLocaleDateString() && showTodayString)
    return TODAY
  else return weekday[new Date(date).getDay()]
}

export const getDateFormat = (date: string) => {
  return new Date(date).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
  })
}

export const getHourFormat = (hour: number) => {
  const suffix = hour >= 12 ? 'pm' : 'am'
  let newHour = hour > 12 ? hour - 12 : hour
  if (hour === 0) newHour = 12

  return `${newHour} ${suffix}`
}
