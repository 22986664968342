import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { classRecordingsApi } from "components/Profile/IeltsProfile/ClassRecordings/redux/ClassRecordingsApi";
import { classRecordingsActions } from "components/Profile/IeltsProfile/ClassRecordings/redux/ClassRecordingsActions";

function* fetchData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(classRecordingsApi.fetchRecordings, action.payload);
    let modifiedRes = null;
    if (res) {
      if(!res.playList) {
        res.playList = [];
      }
      modifiedRes = {
        ...res,
        playList: [
          {
            id: res.id,
            title: res.title,
            url: res.url,
            imageUrl: res.imageUrl,
            createdAt: res.createdAt,
            batchClassTopic: res?.batchClassTopic,
          },
          ...res.playList,
        ],
      };
    }
    yield put(classRecordingsActions.fetchRecordingsSuccess(modifiedRes));
  } catch (e:any) {
    console.error(e);
    yield put(classRecordingsActions.fetchRecordingsFail(e.message));
  }
}

function* fetchVideo(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(classRecordingsApi.fetchVideo, action.payload);
    let modifiedRes = null;
    if (res) {
      if(!res.playList) {
        res.playList = [];
      }
      modifiedRes = {
        ...res,
        playList: [
          {
            id: res.id,
            title: res.title,
            url: res.url,
            imageUrl: res.imageUrl,
            createdAt: res.createdAt,
            batchClassTopic: res?.batchClassTopic,
          },
          ...res.playList,
        ],
      };
    }
    yield put(classRecordingsActions.fetchVideoSuccess(modifiedRes));
  } catch (e:any) {
    console.error(e);
    yield put(classRecordingsActions.fetchVideoFail(e.message));
  }
}

function* recordingsSaga() {
  yield takeLatest("RECORDINGS_FETCH_REQUESTED", fetchData);
  yield takeLatest("VIDEO_FETCH_REQUESTED", fetchVideo);
}

export default recordingsSaga;
