import Image from 'next/image'
import React, { useEffect, useRef, useState, MouseEvent } from 'react'
import { useRouter } from 'next/router'

import { LoginProps, withLogin } from 'components/Login/withLogin'
import useVerloopChatbot from 'hooks/useVerloopChatbot'
import { getTokenCookie } from 'lib/auth-cookies-new'
import { AuthToken } from 'lib/auth_token'
import { contactInfo, loggedInUrls, navbarLinks } from 'page_routes'
import { trackClick } from 'scripts/segment'
import withWindowDimensions from 'uiKit/utils/withWindowDimensions'
import DownloadAppNavNudge from 'components/Nudges/AppDownloadNudges/DownloadAppNavNudge'
import DownloadAppNavController from 'components/Nudges/AppDownloadNudges/DownloadAppNavController'
import DownloadAppModal from 'components/Nudges/AppDownloadNudges/DownloadAppModal'
import DownloadAppModalController from 'components/Nudges/AppDownloadNudges/DownloadAppModalController'

interface Props {
  isMobileSized?: boolean
}

const getPath = (path: string) => {
  if (path === '/') return 'LeapScholar Home Page'
  return path?.slice(1, path?.length)
}

export const handleLogoEvent = async (
  e: MouseEvent<HTMLElement>,
  path: string,
  contentName: string,
  isTargetBlank: boolean,
) => {
  const currentTarget = e.currentTarget
  e.preventDefault()

  try {
    await trackClick(getPath(path), {
      contentName,
      widgetName: 'NavBar',
      widgetFormat: 'Strip',
      contentFormat: 'Link',
    })
  } catch (err) {}

  if ((currentTarget as HTMLAnchorElement)?.href) {
    window.open(
      (currentTarget as HTMLAnchorElement).href,
      isTargetBlank ? '_blank' : '_self',
      isTargetBlank ? 'noopener noreferrer' : '',
    )
  }
}

const Navbar: React.FC<Props & LoginProps> = (props) => {
  const { isMobileSized, initLogin } = props
  const [isMenuOpen, setMenuVisiblity] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const handleMenuOpen = () => setMenuVisiblity((prev) => !prev)
  const router = useRouter()
  const path = router.pathname
  const token = getTokenCookie()
  const auth = new AuthToken(token)
  const [openVerloopChatBot] = useVerloopChatbot()

  useEffect(() => {
    setIsLoggedIn(auth?.isValid ? true : false)
  }, [auth])

  return (
    <>
      <div className="bg-white shadow-md w-full right-0 left-0 fixed top-0 z-[900]">
        <div className="max-w-[1400px] header md:m-auto p-4 xl:px-0 flex justify-between items-center">
          <div className="flex items-center gap-9">
            <a
              onClick={(e) => {
                handleLogoEvent(e, path, 'LeapScholar', false)
              }}
              href={process.env.DOMAIN_NAME ?? 'https://leapscholar.com'}
              className="flex justify-center items-center"
            >
              <Image
                src="/assets/company_logo/new-header-logo.svg"
                height={37}
                width={191}
                alt="leap-scholar-logo"
                className="inline-block"
              />
            </a>

            {!isMobileSized ? (
              <DropdownMenu
                openVerloopChatBot={openVerloopChatBot}
                heading="Select Country"
                values={navbarLinks['Select Country'].links}
                icon={navbarLinks['Select Country'].icon}
              />
            ) : null}
          </div>
          <div>
            <DownloadAppNavController />
          </div>
          <div className="flex items-center gap-2 md:gap-6">
            <div className="hidden md:flex">
              <LinksSection
                isLoggedIn={isLoggedIn}
                openVerloopChatBot={openVerloopChatBot}
                isMobileSized={isMobileSized}
                initLogin={initLogin}
              />
            </div>

            <div className="md:hidden flex items-center justify-center">
              <img
                src={
                  isMenuOpen
                    ? 'https://leapassets.s3.ap-south-1.amazonaws.com/close-btn.svg'
                    : 'https://leapassets.s3.ap-south-1.amazonaws.com/hamburger.svg'
                }
                height={24}
                width={24}
                onClick={handleMenuOpen}
                alt="hamburger-menu"
              />
            </div>
          </div>
        </div>
      </div>
      <SubMenu
        openVerloopChatBot={openVerloopChatBot}
        initLogin={initLogin}
        isLoggedIn={isLoggedIn}
        isMobileSized={isMobileSized}
        isMenuOpen={isMenuOpen}
      />

      <style jsx global>{`
        body {
          overflow: ${isMenuOpen ? 'hidden' : 'auto'};
          padding-top: 72px;
        }

        .header {
          max-width: 1200px;
        }
      `}</style>
    </>
  )
}

interface SubmenuProps {
  isMenuOpen: boolean
  openVerloopChatBot: (customRecipi: string) => void
}

const SubMenu: React.FC<LinksSectionProps & SubmenuProps> = (props) => {
  const {
    isMobileSized,
    initLogin,
    isMenuOpen,
    isLoggedIn,
    openVerloopChatBot,
  } = props
  const [isOpen, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let id: ReturnType<typeof setTimeout> | null = null
    if (isMenuOpen) {
      setOpen(isMenuOpen)
      if (ref.current) {
        ref.current.style.transform = 'translateX(0%)'
      }
    } else {
      if (ref.current) {
        ref.current.style.transform = 'translateX(100%)'
      }
      id = setTimeout(() => {
        setOpen(isMenuOpen)
      }, 150)
    }

    return () => {
      if (id) {
        clearTimeout(id)
      }
    }
  }, [isMenuOpen])

  if (isMenuOpen || isOpen) {
    return (
      <div
        ref={ref}
        className={`fixed overflow-auto translate-x-[100%] transition-transform bottom-0 px-4 top-[69px] z-[900] w-full bg-white py-6`}
      >
        <LinksSection
          openVerloopChatBot={openVerloopChatBot}
          isMobileSized={isMobileSized}
          isLoggedIn={isLoggedIn}
          initLogin={initLogin}
        />
      </div>
    )
  }

  return null
}

interface LinksSectionProps extends LoginProps {
  isMobileSized?: boolean
  isLoggedIn: boolean
  openVerloopChatBot: (customRecipi: string) => void
}

const LinksSection: React.FC<LinksSectionProps> = (props) => {
  const { isMobileSized, initLogin, openVerloopChatBot, isLoggedIn } = props

  return (
    <div className="flex flex-col md:gap-0 gap-8 md:flex-row md:items-center">
      {isMobileSized ? (
        <DropdownMenu
          openVerloopChatBot={openVerloopChatBot}
          heading="Select Country"
          values={navbarLinks['Select Country'].links}
          icon={navbarLinks['Select Country'].icon}
          isMobileSized={isMobileSized}
        />
      ) : null}

      {isLoggedIn ? (
        <>
          {loggedInUrls.map((el) => (
            <SingleLink
              key={el.href}
              icon={el.icon}
              isMobileSized={isMobileSized}
              text={el.name}
              link={el.href}
            />
          ))}

          <DropdownMenu
            openVerloopChatBot={openVerloopChatBot}
            heading="Download App"
            values={navbarLinks['Download App'].links}
            classes="md:bg-[#1B1D6A] md:text-white md:rounded"
            icon={navbarLinks['Download App'].icon}
            isMobileSized={isMobileSized}
          />
          <DropdownMenu
            openVerloopChatBot={openVerloopChatBot}
            heading="My Account"
            values={navbarLinks['My Account'].links}
            isMobileSized={isMobileSized}
            icon={navbarLinks['My Account'].icon}
          />
          {isMobileSized && (
            <div className="flex gap-2 items-center">
              {isMobileSized && (
                <Image
                  src="/assets/navbar/need-help.svg"
                  height={24}
                  width={24}
                  alt={`Need Help icon`}
                />
              )}
              <button
                onClick={() => openVerloopChatBot('wB3pEWHKGe9Twu9bD')}
                className={`font-bold md:py-2 md:px-4 text-left text-base leading-6 md:cursor-pointer text-black`}
              >
                Need Help
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          <SingleLink
            icon="/assets/navbar/about-us.svg"
            isMobileSized={isMobileSized}
            text="About"
            link="/about-us"
          />
          <DropdownMenu
            openVerloopChatBot={openVerloopChatBot}
            heading="Our Experts"
            values={navbarLinks['Our Experts'].links}
            icon={navbarLinks['Our Experts'].icon}
            isMobileSized={isMobileSized}
          />
          <DropdownMenu
            openVerloopChatBot={openVerloopChatBot}
            heading="IELTS"
            values={navbarLinks.IELTS.links}
            icon={navbarLinks.IELTS.icon}
            isMobileSized={isMobileSized}
          />
          <SingleLink
            icon="/assets/navbar/events.svg"
            isMobileSized={isMobileSized}
            text="Events"
            link="/events"
          />
          <SingleLink
            icon="/assets/navbar/scholarship.svg"
            isMobileSized={isMobileSized}
            text="Scholarship"
            link="/scholarship-finder"
          />
          <div className="flex flex-col gap-3 md:flex-row md:items-center">
            {['Sign up', 'Sign in'].map((el, i) => (
              <button
                key={i}
                onClick={() => initLogin()}
                className={`max-w-[170px] md:py-2 md:px-4 ${
                  i === 1
                    ? 'text-primary border-primary'
                    : 'text-white hover:text-white visited:text-white active:text-white text-center bg-[#443EFF]'
                } text-center text-sm leading-[17px] font-bold p-3 rounded border`}
              >
                {el}
              </button>
            ))}
          </div>
        </>
      )}

      <div className="gap-6 flex flex-col md:hidden">
        <p className="font-bold text-lg leading-4">Contact Us</p>
        {contactInfo.map((el, i) => {
          return (
            <div key={i} className="flex gap-[14px] items-center">
              <img src={el.icon} height={20} width={20} alt="contact-icon" />
              <a
                href={el.link}
                className="font-bold text-black text-base leading-6"
              >
                {el.text}
              </a>
            </div>
          )
        })}
      </div>
    </div>
  )
}

interface DropdownMenuProps {
  heading: string
  values: Array<{
    text: string
    link: string
    isTargetBlank: boolean
  }>
  isMobileSized?: boolean
  classes?: string
  icon: string
  openVerloopChatBot: (customRecipi: string) => void
}

const DropdownMenu: React.FC<DropdownMenuProps> = (props) => {
  const { heading, values, isMobileSized, classes, icon, openVerloopChatBot } =
    props
  const router = useRouter()
  const path = router.pathname

  const [subMenuText, setSubMenuText] = useState('')
  const ref: any = useRef()

  const handleSubmenuOpen = (toOpen: string) => {
    setSubMenuText((prev) => (prev === toOpen ? '' : toOpen))
  }

  useEffect(() => {
    const checkIfClickedOutside = (e: globalThis.MouseEvent) => {
      if (ref.current?.contains(e.target as Node)) {
        return
      }
      setSubMenuText('')
    }
    document.addEventListener('click', checkIfClickedOutside)
    return () => {
      document.removeEventListener('click', checkIfClickedOutside)
    }
  }, [])

  return (
    <div
      ref={ref}
      onClick={() => {
        handleSubmenuOpen(heading)
      }}
      className={`flex flex-col md:py-2 md:px-4 gap-6 md:relative ${
        classes ? classes : ''
      }`}
    >
      <div className="flex items-center gap-2">
        {isMobileSized && (
          <Image src={icon} height={24} width={24} alt={`${heading} icon`} />
        )}
        {heading === 'My Account' && !isMobileSized ? (
          <Image
            src={'/assets/navbar/user-icon.svg'}
            height={24}
            width={24}
            alt="user-icon"
          />
        ) : (
          <p
            className={`font-bold text-base leading-6 md:cursor-pointer ${
              subMenuText === heading || heading === 'Select Country'
                ? heading === 'Download App'
                  ? 'md:text-white'
                  : 'text-[#443EFF]'
                : ''
            }`}
          >
            {heading}
          </p>
        )}
        <img
          className={`${
            subMenuText === heading ? '-rotate-180 transition-all' : ''
          }${heading === 'Download App' ? 'md:invert' : ''}`}
          src="https://leapassets.s3.ap-south-1.amazonaws.com/course-finder/nav-drop-down.svg"
          width={8}
          height={4}
          alt="dropdown-icon"
        />
      </div>
      {subMenuText === heading ? (
        <div
          className={`flex flex-col pl-[32px] md:p-2 md:shadow-[0_2px_8px_rgba(0,0,0,0.08)] md:min-w-[158px] bg-white md:gap-2 gap-6 md:absolute md:top-[57px] md:translate-x-[-50%] md:left-[50%]`}
        >
          {values.map((el, i) => {
            return (
              <React.Fragment key={i}>
                {i === 1 && heading === 'My Account' && !isMobileSized && (
                  <button
                    onClick={() => openVerloopChatBot('wB3pEWHKGe9Twu9bD')}
                    className="flex rounded text-sm transition md:hover:bg-primary md:hover:text-white hover:text-right md:px-[10px] md:py-3 justify-between items-center md:cursor-pointer"
                  >
                    Need Help
                  </button>
                )}

                <a
                  onClick={(e) =>
                    handleLogoEvent(e, path, el.text, el.isTargetBlank)
                  }
                  href={el.link}
                  className="flex rounded transition md:hover:bg-primary md:px-[10px] md:py-3 justify-between items-center md:cursor-pointer text-[#666666] md:hover:text-white md:text-black font-medium leading-[18px] text-sm"
                >
                  {el.text}
                </a>
              </React.Fragment>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

interface SingleLinkProps {
  isMobileSized?: boolean
  text: string
  link: string
  icon: string
}

const SingleLink: React.FC<SingleLinkProps> = (props) => {
  const { isMobileSized, text, link, icon } = props
  const router = useRouter()
  const path = router.pathname

  return (
    <div className="flex gap-2 items-center">
      {isMobileSized && (
        <Image src={icon} height={24} width={24} alt={`${text} icon`} />
      )}
      <a
        onClick={(e) => handleLogoEvent(e, path, text, false)}
        href={link}
        className={`font-bold md:py-2 md:px-4 block text-base leading-6 md:cursor-pointer text-black`}
      >
        {text}
      </a>
    </div>
  )
}

export default withWindowDimensions(withLogin(Navbar))
