import axios from "axios";
import { toast } from "react-toastify";

const fetchFaculties = async () => {
  try {
    const res = await axios.get(`${process.env.SCHOLAR_URL}/teacher`);
    if (res.data.success) {
      return res.data.data;
    } else {
      toast.error(res.data.message);
    }
  } catch (err:any) {
    console.log(err);
    toast.error("Something went wrong. Please try after sometime");
  }
};

const fetchFacultiesClientSide = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=teacher`);
    if (res.data.success) {
      return res.data.data;
    } else {
      toast.error(res.data.message);
    }
  } catch (err:any) {
    console.log(err);
    toast.error("Something went wrong. Please try after sometime");
  }
};

export const facultyApis = {
  fetchFaculties,
  fetchFacultiesClientSide,
};
