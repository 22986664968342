import { call, put, select, takeLatest } from '@redux-saga/core/effects'
import { ieltsEliteApis } from 'components/Ielts/IeltsEliteDemo/redux/IeltsEliteApi'
import {
  fetchGetIeltsStage,
  fetchGetStudentEligibility,
  fetchPostDetailsIeltsClass,
  fetchPostIeltsEliteNudgeForm,
  setIeltsStageError,
  setIeltsStageLoading,
  setStudentEligibilityError,
  setStudentEligibilityLoading,
  updateIeltsStage,
  updateStudentEligibility,
} from 'components/Ielts/IeltsEliteDemo/redux/IeltsEliteSlice'
import { State } from 'Interfaces/storeSchema'
import { AnyAction } from 'redux'
import { isFunction } from 'utils/common_utils'

function* fetchGetIeltsStageSaga(): Generator<any, any, any> {
  yield put(setIeltsStageLoading(true))
  const postGetStageAction = yield select(
    (state: State) => state.IeltsElite.postGetStageAction
  )
  try {
    const res = yield call(ieltsEliteApis.fetchGetIeltsStage)
    const userStage = res.data.userStage
    yield put(setIeltsStageLoading(false))
    if (res.success) {
      yield put(updateIeltsStage(userStage))
    } else {
      yield put(setIeltsStageError(res.data))
    }
    if (isFunction(postGetStageAction)) {
      postGetStageAction(userStage)
    }
  } catch (error) {
    if (isFunction(postGetStageAction)) {
      postGetStageAction(null)
    }
    yield put(setIeltsStageLoading(false))
    yield put(
      setIeltsStageError('Something went wrong Please try after some time')
    )
  }
}

function* fetchGetStudentEligibilitySaga(): Generator<any, any, any> {
  yield put(setStudentEligibilityLoading(true))
  const postGetEligibilityAction = yield select(
    (state: State) => state.IeltsElite.postGetEligibilityAction
  )
  try {
    const res = yield call(ieltsEliteApis.fetchGetStudentEligibility)
    yield put(setStudentEligibilityLoading(false))
    if (res.success) {
      yield put(updateStudentEligibility(res.data))
    } else {
      yield put(setStudentEligibilityError(res.data))
    }
    if (isFunction(postGetEligibilityAction)) {
      postGetEligibilityAction(res.data)
    }
  } catch (error) {
    yield put(setStudentEligibilityLoading(false))
    yield put(
      setStudentEligibilityError(
        'Something went wrong Please try after some time'
      )
    )
    if (isFunction(postGetEligibilityAction)) {
      postGetEligibilityAction(null)
    }
  }
}

function* fetchPostIeltsEliteNudgeFormSaga(
  action: AnyAction
): Generator<any, any, any> {
  try {
    yield call(ieltsEliteApis.fetchPostIeltsEliteNudgeForm, action.payload)
  } catch (error) {}
}

function* fetchPostDetailsIeltsClassSaga(
  action: AnyAction
): Generator<any, any, any> {
  const postFillIeltsQeFormAction = yield select(
    (state: State) => state.IeltsElite.postFillIeltsQeFormAction
  )
  try {
    yield call(ieltsEliteApis.fetchPostDetailsIeltsClass, action.payload)
  } catch (error) {}
  if (isFunction(postFillIeltsQeFormAction)) {
    postFillIeltsQeFormAction(action.payload)
  }
}

export default function* ieltsEliteSaga() {
  yield takeLatest(fetchGetIeltsStage, fetchGetIeltsStageSaga)
  yield takeLatest(
    fetchPostIeltsEliteNudgeForm,
    fetchPostIeltsEliteNudgeFormSaga
  )
  yield takeLatest(fetchGetStudentEligibility, fetchGetStudentEligibilitySaga)
  yield takeLatest(fetchPostDetailsIeltsClass, fetchPostDetailsIeltsClassSaga)
}
