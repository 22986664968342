import { call, put, takeLatest } from "@redux-saga/core/effects"
import { AnyAction } from "redux"
import  RequestCallBackApi  from "components/Ielts/BatchDetails/RequestCallBackForm/redux/RequestcallbackApi"
import { fetchRequestCallBackStatus, fetchRequestCbFailure, fetchRequestCbRequested, fetchRequestCbStatusFailure, fetchRequestCbStatusSuccess, fetchRequestCbSuccess, savecallBackFormData, toggleRequestCbForm, toggleRequestCbSucessModal } from "components/Ielts/BatchDetails/RequestCallBackForm/redux/RequestCallBackFormSlice"

function* fetchRequestCbSaga(action: AnyAction): Generator<any, any, any>{
    

try {
    const res=yield call(RequestCallBackApi.fetchRequestCallBack,action.payload)
    yield put(savecallBackFormData(action.payload))
    if(res.success){
        yield put(fetchRequestCbSuccess())
        yield put(toggleRequestCbForm(false))
        yield put(toggleRequestCbSucessModal(true))
    }
    else{
        yield put(fetchRequestCbFailure(res.message))
    }
} catch (error) {
    yield put(fetchRequestCbFailure("Something went wrong. Please try after sometime"))  
}
}

function* fetchRequestCbStatusSaga(action: AnyAction): Generator<any, any, any>{
    try {
        const res=yield call(RequestCallBackApi.fetchRequestCallBackStatus,action.payload)
        if(res.success){
            if(res.data) yield put(fetchRequestCbStatusSuccess(true))
            else yield put(fetchRequestCbStatusSuccess(false))
        }
        else{
            yield put(fetchRequestCbStatusFailure("Something went wrong. Please try after sometime"))
        }
    } catch (error) {
        yield put(fetchRequestCbStatusFailure("Something went wrong. Please try after sometime"))        
    }
}

function* RequestCbSaga(){
    yield takeLatest(fetchRequestCbRequested,fetchRequestCbSaga)
    yield takeLatest(fetchRequestCallBackStatus,fetchRequestCbStatusSaga)
}
export default RequestCbSaga