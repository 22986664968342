const saveForm = (payload: any) => ({
  type: "MASTERCLASS_FORM_SUBMIT",
  payload,
});
//ub
const saveFormSuccess = (payload: any) => ({
  type: "MASTERCLASS_FORM_SUBMIT_SUCCESS",
  payload,
});
const saveFormValues = (payload: any) => ({
  type: "SAVE_MASTERCLASS_FORM_VALUES",
  payload,
});
const toggleCtaText = (payload: string) => ({
  type: "TOGGLE_MASTERCLASS_FORM_CTA_TEXT",
  payload,
});

const toggleMasterclassFormModal = (payload: boolean) => ({
  type: "TOGGLE_MASTERCLASS_FORM_MODAL",
  payload,
});

const enrollToMasterclass = (payload: any) => ({
  type: "ENROLL_TO_MASTERCLASS_FORM",
  payload,
});

const updateError = (message: string) => ({
  type: "MASTERCLASS_FORM_ERROR",
  payload: message,
});

const toggleShowForm = (payload: boolean) => ({
  type: "TOGGLE_MASTERCLASS_SHOW_FORM",
  payload,
});

const toggleShowLoader = (payload: boolean) => ({
  type: "TOGGLE_MASTERCLASS_SHOW_LOADER",
  payload,
});

const postFormAction = (payload: Function | null) => ({
  type: "POST_FORM_ACTION",
  payload,
});

const toggleIsLoading = (payload: boolean) => ({
  type: "TOGGLE_MC_LOADING_STATUS",
  payload,
});

export const masterclassRegistrationActions = {
  saveForm,
  saveFormSuccess,
  toggleCtaText,
  toggleMasterclassFormModal,
  enrollToMasterclass,
  updateError,
  toggleShowForm,
  toggleShowLoader,
  postFormAction,
  saveFormValues,
  toggleIsLoading,
};
