import { navRoutes } from 'page_routes'

export const getAndroidDeepLink = (pageName: string) => {
  switch (pageName) {
    case navRoutes.IELTS_TEST_PREP_MOCK_TEST:
      return navRoutes.ANDROID_MOCKTEST
    case navRoutes.MASTERCLASS_TYPE:
      return navRoutes.ANDROID_MASTERCLASS
    case navRoutes.IELTS_OVERVIEW:
    default:
      return navRoutes.ANDROID_DASHBOARD
  }
}
