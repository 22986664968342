import axios from 'axios'
import { toast } from 'react-toastify'

const fetchRequestCallBack = async (formData: any) => {
  try {
    const path = 'v2/workshop/callbackDetails'
    const res = await axios.post(`/api/scholarRoute?path=${path}`, formData)
    return res.data
  } catch (error) {
    toast?.error('Something went wrong. Please try after sometime')
    return 'something went wrong'
  }
}

const fetchRequestCallBackStatus = async (userCategory: string) => {
  try {
    const path = `v2/workshop/callback-request/${userCategory}/status`
    const res = await axios.get(`/api/scholarRoute?path=${path}`)
    return res.data
  } catch (error) {
    return 'something went wrong'
  }
}

const RequestCallBackApi = {
  fetchRequestCallBack,
  fetchRequestCallBackStatus,
}
export default RequestCallBackApi
