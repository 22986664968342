import { FunctionComponent, ChangeEvent, useRef, useEffect } from "react";
import { PrimaryButton, ImageButton } from "components/Inputs/Button";
import { isSafari, isMobile } from "react-device-detect";
import CountryCodeSelector from "components/Login/CountryCodeSelector";
import { trackClick } from "scripts/segment";
import { pageName } from "utils/events-utils";

interface Props {
  phone: string;
  whatsAppSubscription: boolean;
  phoneText: string;
  phoneErrMsg: string;
  showTruecaller: boolean;
  phoneInfoMsg: string;
  country: string;
  canChangeCountryCode: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  changeView: (view: string) => void;
  generateOtp: () => void;
  handleWhatsAppSubscriptionChange: () => void;
  truecallerLoginManager: () => void;
  changeCountry: (country: string) => void;
}

const EnterMobile: FunctionComponent<Props> = ({
  phone,
  whatsAppSubscription,
  phoneText,
  phoneErrMsg,
  showTruecaller,
  phoneInfoMsg,
  country,
  canChangeCountryCode,
  handleChange,
  handleWhatsAppSubscriptionChange,
  generateOtp,
  truecallerLoginManager,
  changeCountry,
}) => {
  const hadSentEvent = useRef(false);

  const phoneRef = useRef<any>(null);

  const handlePhoneChange = (e: any) => {
    handleChange(e);
  };

  useEffect(() => {
    phoneRef.current.focus();
  }, []);

  return (
    <>
      <div
        className={`flex items-center w-full rounded border  bg-lightgray-1350 ${
          phone.length === 10 ? "border-green-300" : "border-lightgray-1350"
        }`}
      >
        <div
          className={`font-bold md:text-lg text-base ${
            canChangeCountryCode
              ? "pl-2 w-2/5 md:w-1/3"
              : "pl-4 pr-2 border-r border-gray-300"
          }`}
        >
          {canChangeCountryCode ? (
            <CountryCodeSelector
              country={country}
              changeCountry={changeCountry}
            />
          ) : (
            "+91"
          )}
        </div>
        <input
          ref={phoneRef}
          className={`md:text-lg text-base md:p-4 p-3 bg-transparent ${
            canChangeCountryCode && "w-3/5 md:w-2/3"
          }`}
          type="number"
          value={phone}
          onChange={handlePhoneChange}
          placeholder="Mobile Number"
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              generateOtp();
            } else {
              if (!hadSentEvent.current) {
                trackClick(pageName.Login_Modal, {
                  widgetName: "Login Modal",
                  widgetFormat: "Modal",
                  contentName: "Input Number",
                  contentFormat: "Button",
                });
                hadSentEvent.current = true;
              }
            }
          }}
        />
      </div>
      {!phoneErrMsg && phoneInfoMsg && (
        <p className="text-sm text-primary text-left py-1 w-full">
          {phoneInfoMsg}
        </p>
      )}
      {phoneErrMsg && (
        <p className="text-sm text-red-600 text-left py-1 w-full">
          {phoneErrMsg}
        </p>
      )}
      <PrimaryButton
        py='py-2 md:py-3'
        disabled={phone.length !== 10}
        className="my-4 md:my-6 w-full"
        ctaText={phoneText}
        onClick={generateOtp}
      />
      {isMobile && showTruecaller && !isSafari && (
        <>
          <div className="w-full text-lightgray-900 font-bold text-center text-font14">
            -- OR --
          </div>
          <ImageButton
            image="/assets/images/login/truecaller_logo.png"
            ctaAction={truecallerLoginManager}
            ctaText="One tap login with Truecaller"
          />
        </>
      )}
      <div className="flex pt-2">
        <input
          className="w-5 h-5 mt-1"
          type="checkbox"
          checked={whatsAppSubscription}
          onChange={handleWhatsAppSubscriptionChange}
        />
        <p className="text-sm pl-4 w-11/12 text-gray-1600">
          Subscribe to receive whatsapp updates directly from your counsellor
        </p>
      </div>
    </>
  );
};

export default EnterMobile;
