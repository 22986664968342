import axios from "axios";

const feedbackResponse = async (data: any) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=chat/ticket/feedback`,
      data
    );

    if (res?.data?.success) {
      return res?.data?.data;
    } else {
      return false;
    }
  } catch (e: any) {
    console.log(e);
  }
};

const ratingResponse = async (data: any) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=chat/ticket/rating`,
      data
    );

    if (res?.data?.success) {
      return res?.data?.data;
    } else {
      return false;
    }
  } catch (e: any) {
    console.log(e);
  }
};

const unsatisfiedResponse = async (data: any) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=chat/ticket/unsatisfied-reason`,
      data
    );

    if (res?.data?.success) {
      return res?.data?.data;
    } else {
      return false;
    }
  } catch (e: any) {
    console.log(e);
  }
};

export const chatApis = {
  feedbackResponse,
  unsatisfiedResponse,
  ratingResponse,
};
