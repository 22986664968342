import differenceInSeconds from 'date-fns/differenceInSeconds'
import differenceInDays from 'date-fns/differenceInDays'

export function secondsConverter(seconds: number) {
  const day = Math.floor(seconds / (24 * 3600))
  const h = Math.floor((seconds % (24 * 3600)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor((seconds % 3600) % 60)
  return {
    sec: s,
    min: m,
    hour: h,
    day: day,
  }
}

export function appendZeroForTiming(digit: number) {
  if (`${digit}`.length == 1) return `0${digit}`
  return digit
}

export const getTimeLeftForMeetingInSeconds = (meetingStartTime: string) => {
  const seconds = differenceInSeconds(new Date(meetingStartTime), new Date())
  return seconds
}

export function getClassTimingInWork(ms: number) {
  const seconds = Math.floor(ms / 1000)
  const date = secondsConverter(seconds)
  if (date.day) {
    if (date.day > 1) return `${date.day} Days`
    return 'Tomorrow'
  } else {
    return 'Today'
  }
}

export function getNextClassTiming(date: string) {
  const todayDay = new Date(new Date().toDateString()) //excluding the time because we need date only
  const classDay = new Date(date)
  const dayDiff = differenceInDays(classDay, todayDay)
  if (dayDiff < 0) return ''
  if (todayDay.getDate() == classDay.getDate()) return 'Today'
  if (dayDiff == 1 || dayDiff == 0) return 'Tomorrow'
  else return `${differenceInDays(classDay, todayDay)} Days`
}
