const saveForm = (payload: any) => ({
  type: "SAVE_IELTS_FORM",
  payload,
});

const toggleCtaText = (payload: string) => ({
  type: "TOGGLE_IELTS_FORM_CTA_TEXT",
  payload,
});

const toggleIeltsFormModal = (payload: boolean) => ({
  type: "TOGGLE_IELTS_FORM_MODAL",
  payload,
});

const enrollToIeltsPlus = (payload: any) => ({
  type: "ENROLL_TO_IELTS_PLUS_FORM",
  payload,
});

const updateError = (message: string) => ({
  type: "IELTS_FORM_ERROR",
  payload: message,
});

//ub
const setIeltsPlusPaymentSuccess = (payload: any) => ({
  type: "IELTS_PLUS_PAYMENT_SUCCESS",
  payload,
});

const toggleFreeTrailEligibility=(payload: boolean) => ({
  type:"TOGGLE_FREE_TRAIL_ELIGIBILITY",
  payload,
})

export const ieltsRegistrationFormActions = {
  saveForm,
  toggleCtaText,
  toggleIeltsFormModal,
  enrollToIeltsPlus,
  updateError,
  //ub
  setIeltsPlusPaymentSuccess,
  toggleFreeTrailEligibility,
};
