import { call, put, takeLatest } from "redux-saga/effects";
import { batchDetailsActions } from "components/Ielts/BatchDetails/redux/BatchDetailsAction";
import { batchDetailsApi } from "components/Ielts/BatchDetails/redux/BatchDetailsApi";
import { AnyAction } from "redux";

function* fetchData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      batchDetailsApi.fetchBatchDetails,
      action.payload.batchId,
      action.payload.ctx
    );
    yield put(batchDetailsActions.fetchBatchDetailsSuccess(res));
    const teacherResponse = yield call(
      batchDetailsApi.fetchBatchTeacherDetails,
      res.primaryTeacherId,
      action.payload.ctx
    );
    yield put(batchDetailsActions.fetchBatchTeacherSuccess(teacherResponse));
  } catch (e:any) {
    console.error(e);
    yield put(batchDetailsActions.fetchBatchDetailsFail(e.message));
  }
}

function* fetchDataClientSide(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      batchDetailsApi.fetchBatchDetailsClientSide,
      action.payload
    );
    yield put(batchDetailsActions.fetchBatchDetailsSuccess(res));
    const teacherResponse = yield call(
      batchDetailsApi.fetchBatchTeacherDetailsClient,
      res.primaryTeacherId
    );
    yield put(batchDetailsActions.fetchBatchTeacherSuccess(teacherResponse));
  } catch (e:any) {
    console.error(e);
    yield put(batchDetailsActions.fetchBatchDetailsFail(e.message));
  }
}

function* facultiesSaga() {
  yield takeLatest("FETCH_BATCH_DETAILS_REQUESTED", fetchData);
  yield takeLatest(
    "FETCH_BATCH_DETAILS_REQUESTED_CLIENT_SIDE",
    fetchDataClientSide
  );
}

export default facultiesSaga;
