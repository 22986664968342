import axios from "axios";

const fetchFastTrackVideoApi = async (videoCategoryName: string) => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=fasttrack/video/v2/category/${videoCategoryName}`
    );
    if (res.data.success) {
      return {
        success: true,
        data: res.data.data,
      };
    } else {
      return {
        success: false,
        data: res.data.message,
      };
    }
  } catch (err:any) {
    return {
      success: false,
      data: "Something went wrong",
    };
  }
};

const fetchFastTrackMarkVideoWatched = async (videoId: number) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=fasttrack/video/watched/${videoId}`
    );
    if (res.data.success) {
      return {
        success: true,
        data: res.data.data,
      };
    } else {
      return {
        success: false,
        data: res.data.message,
      };
    }
  } catch (err:any) {
    return {
      success: false,
      data: "Something went wrong",
    };
  }
};

export const fastTrackVideoApi = {
  fetchFastTrackVideoApi,
  fetchFastTrackMarkVideoWatched,
};
