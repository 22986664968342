import { AnyAction } from 'redux'
import { IeltsScreeSchema } from 'Interfaces/reducerTypes/IeltsScreenController'

const initialState: IeltsScreeSchema = {
  activeTab: null,
  isLoading: false,
  userStates: null,
  demoDetails: null,
  userIeltsDetails: null,
  userPaymentInfo: null,
}

const ieltsScreenControllerReducer = (
  state: any = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case 'SET_IELTS_SCREEN':
      return {
        ...state,
        activeTab: action.payload,
      }
    case 'SET_USER_STATES':
      return {
        ...state,
        userStates: action.payload,
      }
    case 'SET_DEMO_DETAILS':
      return {
        ...state,
        demoDetails: action.payload,
      }
    case 'FETCH_USER_IELTS_DETAILS_SUCCESS':
      return {
        ...state,
        userIeltsDetails: action.payload,
      }
    case 'FETCH_USER_PAYMENT_INFO_SUCCESS':
      return {
        ...state,
        userPaymentInfo: action.payload,
      }
    default:
      return state
  }
}

export default ieltsScreenControllerReducer
