import axios from "axios";

const fetchGetIeltsStage = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=ielts-elite/stage`);
    return res.data;
  } catch (error) {
    return { success: false, data: null };
  }
};

const fetchPostIeltsEliteNudgeForm = async (formData: any) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=ielts-elite/details`,
      formData
    );
    return res.data;
  } catch (error) {
    return { success: false, data: null };
  }
};

const fetchGetStudentEligibility = async () => {
  try {
    const res = await axios.get("/api/scholarRoute?path=student/eligibility");
    return res.data;
  } catch (err: any) {
    return {
      success: false,
      data: null,
    };
  }
};

const fetchPostDetailsIeltsClass = async (values: Record<string, any>) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=workshop/ielts-class`,
      values
    );
    return res.data;
  } catch (err: any) {
    return {
      success: false,
      data: null,
    };
  }
};

export const ieltsEliteApis = {
  fetchPostIeltsEliteNudgeForm,
  fetchPostDetailsIeltsClass,
  fetchGetIeltsStage,
  fetchGetStudentEligibility,
};
