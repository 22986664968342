import axios from "axios";

const enrollStudentToIeltsPlus = async (batchId: number) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/workshop/1/${batchId}/enroll`
    );
    if (res.data.success) {
      return true;
    } else {
      throw new Error(res.data.message);
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
};

const fetchMasterClassData = async () => {
  try {
    const res = await axios.get(
      "/api/scholarRoute?path=v2/workshop/MASTER_CLASS/0/12"
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      throw new Error(res.data.message);
    }
  } catch (err:any) {
    console.log(err);
    throw new Error("Something went wrong. Please try after sometime");
  }
};

const enrollStudentToMasterClass = async () => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=workshop/master-class/subscribe`
    );
    if (res.data.success) {
      return true;
    } else {
      throw new Error(res.data.message);
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
};

const notifyUser = async (batchId: number) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=workshop/enroll/${batchId}/MASTER_CLASS`
    );
    if (res.data.sucess) {
      return res.data;
    } else {
      return res.data;
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
};

const fetchMasterclassSubscriptionStatus = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=workshop/master-class-subscription-status`
    );
    return res.data;
  } catch (err:any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
};

export const myClassApi = {
  enrollStudentToIeltsPlus,
  fetchMasterClassData,
  enrollStudentToMasterClass,
  notifyUser,
  fetchMasterclassSubscriptionStatus,
};
