import { MasterclassDataSchema } from "Interfaces/reducerTypes/MasterclassSchema";

export const actionConstant = {
  REQUEST_BEGINNER_MASTERCLASS_TYPE_DATA:
    "REQUEST_BEGINNER_MASTERCLASS_TYPE_DATA",
  SUCCESS_BEGINNER_MASTERCLASS_TYPE_DATA:
    "SUCCESS_BEGINNER_MASTERCLASS_TYPE_DATA",
  REQUEST_CLASSIC_MASTERCLASS_TYPE_DATA:
    "REQUEST_CLASSIC_MASTERCLASS_TYPE_DATA",
  SUCCESS_CLASSIC_MASTERCLASS_TYPE_DATA:
    "SUCCESS_CLASSIC_MASTERCLASS_TYPE_DATA",
  UPDATE_ERROR: "UPDATE_ERROR",
};

const requestBeginnerMasterClassData = (payload?: boolean) => ({
  type: actionConstant.REQUEST_BEGINNER_MASTERCLASS_TYPE_DATA,
  payload,
});

const successBeginnerMasterClassData = (
  payload: Array<MasterclassDataSchema>
) => ({
  type: actionConstant.SUCCESS_BEGINNER_MASTERCLASS_TYPE_DATA,
  payload,
});

const requestClassicMasterClassData = (payload?: boolean) => ({
  type: actionConstant.REQUEST_CLASSIC_MASTERCLASS_TYPE_DATA,
  payload,
});

const successClassicMasterClassData = (
  payload: Array<MasterclassDataSchema>
) => ({
  type: actionConstant.SUCCESS_CLASSIC_MASTERCLASS_TYPE_DATA,
  payload,
});

const updateError = (payload: string) => ({
  type: actionConstant.UPDATE_ERROR,
  payload,
});

export const masterclassTypeActions = {
  requestBeginnerMasterClassData,
  successBeginnerMasterClassData,
  requestClassicMasterClassData,
  successClassicMasterClassData,
  updateError,
};
