import axios from 'axios'

const fetchIeltsUserStates = () => axios.get('/api/ielts/studentDashboard')

const fetchDemoDetails = (counsellingType: string) =>
  axios.get(
    `/api/scholarRoute?path=counselling/slots/${counsellingType}/booked`,
    { headers: { 'X-API-VERSION': '2' } },
  )

const fetchIeltsUserDetails = () =>
  axios.get(`/api/scholarRoute?path=ielts-elite/onboarding-details`)

const fetchIeltsPaymentInfo = (batchId: string) =>
  axios.get(`/api/scholarRoute?path=payment/details/${batchId}`)

export const ieltsScreenApi = {
  fetchIeltsUserStates,
  fetchDemoDetails,
  fetchIeltsUserDetails,
  fetchIeltsPaymentInfo,
}
