import axios from "axios";
import { toast } from "react-toastify";

const fetchSetReminderApi = async ({
  batchId,
  day,
}: {
  batchId: number | string;
  day: number | string;
}) => {
  try {
    const res = await axios.post(
      "/api/scholarRoute?path=v2/workshop/reminder/batchClass",
      {
        batchId,
        day,
      }
    );
    return res.data;
  } catch (error) {
    toast.error("Something went wrong. Please try after sometime");
    return {
      success: false,
      message: "something went wrong",
    };
  }
};

export const schedulePageApis = { fetchSetReminderApi };
