import gql from "graphql-tag";

const FETCH_PLAYLIST = gql`
  query {
    plAyLists {
      name
      play_list {
        caption
        youtube_link
        description
        thumbnail {
          url
        }
      }
    }
  }
`;

export default FETCH_PLAYLIST;
