import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

const PaymentBundlesName = 'PaymentBundles'

export type PaymentDescription = {
  subtitle: string
  image: string
  features: string[]
  redirectionTag: string //html tag will be there in this key
  support_channel: string
}
export enum DiscountType {
  PERCENTAGE = 'PERCENTAGE',
  FLAT = 'FLAT',
}
export type VersionPriceBundle = {
  id: number
  version: number
  basePrice: number
  discount: number
  discountType: DiscountType
  currency: number
}
export interface PaymentBundleResponseDto {
  productBundleName: string
  productBundleId: number
  title: string
  description: PaymentDescription
  versions: Array<VersionPriceBundle>
}
export interface PaymentBundlesSchema {
  bundleDetails: PaymentBundleResponseDto | null
  error: string | null
  isLoading: boolean
}
const initialState: PaymentBundlesSchema = {
  bundleDetails: null,
  error: null,
  isLoading: false,
}

const PaymentBundlesSlice = createSlice({
  name: PaymentBundlesName,
  initialState,
  reducers: {
    setBundleDetails(state, action: PayloadAction<PaymentBundleResponseDto>) {
      let description: any = action.payload.description //convert it to json because it will come as string
      try {
        if (description) {
          description = JSON.parse(description.replaceAll(`'`, `"`))
        }
      } catch (error) {
        console.log(error, 'errorInpayment')
      }
      return { ...state, bundleDetails: { ...action.payload, description } }
    },
    setBundleDetailsLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload }
    },
    setBundleDetailsError(state, action: PayloadAction<string>) {
      return { ...state, error: action.payload }
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        bundleDetails: action.payload.PaymentBundles.bundleDetails,
        error: action.payload.PaymentBundles.error,
        isLoading: action.payload.PaymentBundles.isLoading,
      }
    },
  },
})

export const {
  setBundleDetails,
  setBundleDetailsError,
  setBundleDetailsLoading,
} = PaymentBundlesSlice.actions

export default PaymentBundlesSlice.reducer
