import axios from "axios";

const saveForm = async (values: Record<string, any>) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=workshop/ielts-class`,
      values
    );
    return res.data;
  } catch (err:any) {
    console.log(err);
  }
};

const enrollToFastTrack = async (batchId: string) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/workshop/1/${batchId}/enroll`
    );
    return res.data;
  } catch (err:any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
};

export const fastTrackRegistrationFormApi = {
  saveForm,
  enrollToFastTrack,
};
