import axios from "axios";
import { initBkashPayment } from "lib/Razorpay/bkashPayment";
import {
  initPaymentRequestV2,
  initRazorpayPaymentRequestV2,
} from "lib/Razorpay/razorpay";
import { initRazorpayScript } from "razorpayScript";
import { paymentGatewayVendorConstant } from "DataMapper/Common/Payment";

interface PaymentInfo {
  paymentType: string;
  formDescription: string;
  currency?: string;
}

export const initPaymentGateway = async (
  requestBody: any,
  paymentInfo: PaymentInfo,
  batchId: string
) => {
  try {
    initRazorpayScript();
    const { paymentType, formDescription } = paymentInfo;
    requestBody.paymentType = paymentType;
    requestBody.batchId = batchId;
    const res = await axios.post(
      `/api/scholarRoute?path=payment/createOrder`,
      { ...requestBody },
      {
        headers: {
          "X-API-VERSION": "3",
        },
      }
    );
    const { success, data } = res.data;
    if (success) {
      const { orderId, paymentGatewayVendor, amount } = data || {};
      if (amount == 0) return true;
      switch (paymentGatewayVendor) {
        case paymentGatewayVendorConstant.BKASH:
          initBkashPayment(orderId);
          return undefined;
        case paymentGatewayVendorConstant.RAZORPAY:
        default:
          return await initPaymentRequestV2(requestBody, data, formDescription);
      }
    }
    return false;
  } catch (err:any) {
    console.log(err);
  }
};

export const initDefaultPaymentGateway = async (paymentInfo: PaymentInfo) => {
  try {
    initRazorpayScript();
    const res = await axios.post(
      `/api/scholarRoute?path=payment/createOrder`,
      { ...paymentInfo },
      {
        headers: {
          "X-API-VERSION": "3",
        },
      }
    );
    const { success, data } = res.data;
    if (success) {
      const { orderId, paymentGatewayVendor, amount } = data || {};
      if (amount == 0) return true;
      switch (paymentGatewayVendor) {
        case paymentGatewayVendorConstant.BKASH:
          initBkashPayment(orderId);
          return undefined;
        case paymentGatewayVendorConstant.RAZORPAY:
        default:
          return await initRazorpayPaymentRequestV2(paymentInfo, data);
      }
    }
    return false;
  } catch (err:any) {
    console.log(err);
  }
};
