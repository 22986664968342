import { FunctionComponent } from 'react'

interface Props {
  text: string
  onClick: () => void
}

const DownloadAppNavNudge: FunctionComponent<Props> = ({ text, onClick }) => {
  return (
    <div
      className="bg-blue-gradient text-white text-[12px] font-bold rounded-full px-4 py-2 cursor-pointer"
      onClick={onClick}
    >
      {text}
    </div>
  )
}
export default DownloadAppNavNudge
