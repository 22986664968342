export enum CountryCode {
  INDIA = "INDIA",
  BANGLADESH = "BANGLADESH",
}

interface CountryDataSchema {
  code: string;
  flag: string;
}

const { INDIA, BANGLADESH } = CountryCode;

export const countryData: { [key: string]: CountryDataSchema } = {
  [INDIA]: {
    code: "91",
    flag: "/assets/icons/flags/india.png",
  },
  [BANGLADESH]: {
    code: "880",
    flag: "/assets/icons/flags/bangladesh.png",
  },
};

export const countries = [
  {
    label: "India",
    value: INDIA,
  },
  {
    label: "Bangladesh",
    value: BANGLADESH,
  },
];
