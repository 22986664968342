import { IeltsStageType } from 'components/Ielts/IeltsEliteDemo/redux/IeltsEliteSlice'
import { priceBundleName } from 'DataMapper/Common/WorkshopData'
import { PaymentType } from 'Interfaces/common/PaymentSchema'
import { BatchSchema } from 'Interfaces/reducerTypes/BatchDetailsSchema'
import {
  IsEnrolledForBatchesType,
  WorkshopSchema,
} from 'Interfaces/reducerTypes/IeltsPlusSchema'
import moment from 'moment'
import { getFullDate } from 'utils/ielts_date_utils'

export const getBatchSlots = (startHours: number) => {
  if (startHours < 12) {
    return 'Morning Batch'
  } else if (startHours < 18) {
    return 'Afternoon Batch'
  } else {
    return 'Evening Batch'
  }
}

export const shortenWeeklySchedule = (weekday: string) => {
  // expected monday-friday
  // output mon-fri
  const weekArray = weekday.split('-')
  if (weekArray.length !== 2) return weekday
  return `${weekArray[0].slice(0, 3)}-${weekArray[1].slice(0, 3)}`
}

export const diffWeeks = (startDate: string, endDate: string) => {
  const dt1 = new Date(startDate)
  const dt2 = new Date(endDate)

  let diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= 60 * 60 * 24 * 7
  return Math.abs(Math.round(diff))
}

export const getStartingHour = (startDate: string) => {
  return Number(moment(startDate).format('HH'))
}

export const diffeDays = (
  startDate: string,
  hours: number,
  minutes: number,
) => {
  const startHours = hours < 10 ? `${0}${hours}` : `${hours}`
  const startMinutes = minutes < 10 ? `${0}${minutes}` : `${minutes}`
  const DateString =
    startDate + 'T' + startHours + ':' + startMinutes + ':' + '00'
  return getDayWithoutWeekend(DateString)
}

const getDayWithoutWeekend = (startDate: string) => {
  const ONE_DAY = 1000 * 60 * 60 * 24
  let variableDate = Date.parse(startDate)
  const currentDate = Date.parse(new Date().toString())
  let totalDate = 0
  while (variableDate <= currentDate) {
    const currentDayNumber = new Date(variableDate).getDay()
    if (currentDayNumber !== 0 && currentDayNumber !== 6) {
      totalDate += 1
    }
    variableDate += ONE_DAY
  }
  return totalDate
}

export const dayDifference = (startDate: string) => {
  return getDayWithoutWeekend(startDate)
}

export const getStartingDate = (startDate: string) => {
  return Number(moment(startDate).format('DD'))
}

export const isPremiumBatch = (batchData: any) => {
  return isPremiumBundleCheck(batchData?.pricingBundleName)
}

export const isPremiumBundleCheck = (
  pricingBundleName: priceBundleName | string,
): boolean => {
  return (
    pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM ||
    pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM_2 ||
    pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM_3 ||
    pricingBundleName === priceBundleName.IELTS_FOUNDATION ||
    pricingBundleName === priceBundleName.IELTS_SELECT ||
    pricingBundleName === priceBundleName.IELTS_ELITE ||
    pricingBundleName === priceBundleName.IELTS_SELECT_V2 ||
    pricingBundleName === priceBundleName.IELTS_ELITE_V1 ||
    pricingBundleName === priceBundleName.IELTS_CUSTOM
  )
}

export const isEliteBatch = (
  pricingBundleName: priceBundleName | string,
): boolean => {
  return (
    pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM_3 ||
    pricingBundleName === priceBundleName.IELTS_SELECT ||
    pricingBundleName === priceBundleName.IELTS_ELITE ||
    pricingBundleName === priceBundleName.IELTS_SELECT_V2 ||
    pricingBundleName === priceBundleName.IELTS_ELITE_V1 ||
    pricingBundleName === priceBundleName.IELTS_CUSTOM
  )
}

export const isIeltsEliteBundle = (
  pricingBundleName: priceBundleName | string | null | undefined,
): boolean => {
  return (
    pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM_3 ||
    pricingBundleName === priceBundleName.IELTS_ELITE ||
    pricingBundleName === priceBundleName.IELTS_ELITE_V1 ||
    pricingBundleName === priceBundleName.IELTS_CUSTOM
  )
}

export const isIeltsSelectBundle = (
  pricingBundleName: priceBundleName | string | null | undefined,
) => {
  return (
    pricingBundleName === priceBundleName.IELTS_SELECT ||
    pricingBundleName === priceBundleName.IELTS_SELECT_V2
  )
}
export const showDaignoisticTests = (
  isEnrolledForBatches: IsEnrolledForBatchesType,
): boolean => {
  if (
    isEnrolledForBatches.isEnrolledForElite === null &&
    isEnrolledForBatches.isEnrolledForSelect === null &&
    isEnrolledForBatches.isEnrolledForPlus === null &&
    isEnrolledForBatches.isEnrolledForPremium === null
  ) {
    return true //this means api is not called yet and everything is in it default state
  }
  if (
    isEnrolledForBatches.isEnrolledForElite === true ||
    isEnrolledForBatches.isEnrolledForSelect === true ||
    isEnrolledForBatches.isEnrolledForPlus === true ||
    isEnrolledForBatches.isEnrolledForPremium === true
  ) {
    return false //this means user is enrolled in any of the batch
  }

  return true //this means everything is false user is not enrolled in any batch
}
export const getPriceBundleNameFromPaymentType = (
  paymentType: PaymentType | string,
) => {
  switch (paymentType) {
    case PaymentType.IELTS_FOUNDATION_FEE:
      return priceBundleName.IELTS_FOUNDATION
    case PaymentType.IELTS_PREMIUM_FEE:
      return priceBundleName.IELTS_PLUS_PREMIUM
    case PaymentType.IELTS_PREMIUM_2_FEE:
      return priceBundleName.IELTS_PLUS_PREMIUM_2
    case PaymentType.IELTS_PREMIUM_3_FEE:
      return priceBundleName.IELTS_PLUS_PREMIUM_3
    case PaymentType.REWARD_BOOSTER_FEE:
      return priceBundleName.BOOSTER_PACK
    case PaymentType.IELTS_PLUS_PART_2_FEE:
      return priceBundleName.IELTS_PLUS_PART_2
    case PaymentType.IELTS_FEE:
    default:
      return priceBundleName.IELTS_PLUS
  }
}

const isBatchFull = (bookedSeat: number, numberOfSeat: number) => {
  return numberOfSeat <= bookedSeat
}

const classAlreadyStarted = (classStartDate: Date) => {
  return classStartDate < new Date()
}

export const getBatchErrorMessage = (batchDetails: BatchSchema) => {
  if (isBatchFull(batchDetails.seatBooked, batchDetails.numberOfSeats))
    return 'Sorry you cannot enrol in this batch as all seats are booked.'
  if (
    classAlreadyStarted(
      getFullDate(
        batchDetails.startDate,
        batchDetails.startHour,
        batchDetails.startMinute,
      ),
    )
  )
    return 'Sorry, You cannot enrol in this batch as it has already started.'
  return ''
}

export const removeNullValueFromArray = (arr: Array<any>) =>
  arr?.filter((data) => data)

export const selectOtherCountryIfGermanyIsThere = (arr: Array<any>) => {
  if (arr.length > 1 && arr.includes('Other')) {
    const index = arr.indexOf('Other')
    arr.splice(index, 1)
    return arr
  } else return arr
}

export const isIeltsPlusPart2Batch = (batchData: WorkshopSchema): boolean => {
  if (batchData) {
    return isIeltsPlusPart2BatchBundle(batchData?.pricingBundleName)
  }
  return false
}

export const isIeltsPlusPart2BatchBundle = (
  pricingBundleName: string | null | undefined,
): boolean => {
  return pricingBundleName === priceBundleName.IELTS_PLUS_PART_2
}

export const checkIfIeltsPlusPart2BatchExistsInEnrolledBatches = (
  enrolledBatches: WorkshopSchema[],
) => {
  const part2Batch = enrolledBatches?.find((item) =>
    isIeltsPlusPart2BatchBundle(item.pricingBundleName),
  )
  if (part2Batch) return true
  return false
}

export const checkToShowPlusDisableMentModal = (
  batchData: BatchSchema,
  isEnrolledForIelts: boolean | null,
  enrolledBatches: WorkshopSchema[],
): boolean => {
  if (
    (batchData.pricingBundleName &&
      isPremiumBundleCheck(batchData.pricingBundleName)) ||
    isIeltsPlusPart2BatchBundle(batchData.pricingBundleName)
  ) {
    if (isIeltsPlusPart2BatchBundle(batchData.pricingBundleName)) {
      if (checkIfIeltsPlusPart2BatchExistsInEnrolledBatches(enrolledBatches)) {
        return true
      }
    }
    return false
  }
  if (isEnrolledForIelts) {
    return true
  }
  return false
}

export const IsDemoBooked = (ieltsStage: IeltsStageType | null): boolean => {
  return (
    ieltsStage === IeltsStageType.IELTS_DEMO_BOOKED ||
    ieltsStage === IeltsStageType.IELTS_DEMO_ATTENDED
  )
}

export const eligibleForNewFlow = (bundleName: string) =>
  bundleName != priceBundleName.IELTS_CUSTOM && isEliteBatch(bundleName)

export const eligibleForNewFlowByPrice = (price: number) => price > 6800
