export const npsQuestions = {
  firstQuestion: {
    id: "Q1",
    question: "Sorry about that, What can we do better?",
    image: "/assets/images/nps/sad_emoji.png",
  },
  secondQuestion: {
    id: "Q2",
    question: "Thank you! What can we improve to delight you further?",
    image: "/assets/images/nps/happy_emoji.png",
  },
  thirdQuestion: {
    id: "Q3",
    question: "We are glad! What do you like about LeapScholar?",
    image: "/assets/images/nps/delight_emoji.png",
  },
};

export const ratingColors = {
  backgroundColor: [
    "#FF7791",
    "#FB8682",
    "#F99377",
    "#F69F6B",
    "#F3AB5F",
    "#F0B952",
    "#E0C176",
    "#D1D7B9",
    "#96C7C6",
    "#73B0B1",
  ],
  borderColor: [
    "#FD4B6D",
    "#FC5B55",
    "#F7724D",
    "#F88037",
    "#FD8D17",
    "#E4990E",
    "#DAAE42",
    "#BCC792",
    "#79B2B1",
    "#388E8E",
  ],
};

export const mockArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const fastTrackNpsQuestion =
  "How likely are you to recommend LeapScholar’s FastTrack course to your friends who are preparing for IELTS?";

export const ieltsNpsQuestion =
  "How likely are you to recommend LeapScholar's IELTS courses to your friends who are preparing for IELTS?";
