import { countries, countryData } from 'DataMapper/CountryCode'
import { FunctionComponent } from 'react'
import Select from 'react-select'

interface Props {
  country: string
  changeCountry: (country: string) => void
}

const CountryCodeSelector: FunctionComponent<Props> = ({
  country,
  changeCountry,
}) => {
  const customStyles = {
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: '0px',
      color: 'black',
    }),
    control: (styles: any) => {
      return {
        ...styles,
        borderWidth: 0,
        background: 'transparent',
        padding: '0px',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        width: '100%',
      }
    },
    menu: (styles: any) => {
      return {
        ...styles,
        zIndex: 99999,
        overflowY: 'scroll',
        width: '150px',
        fontWeight: 'normal',
      }
    },
    singleValue: (styles: any) => {
      return {
        ...styles,
        padding: '0px',
        margin: '0px',
        fontWeight: 'normal',
      }
    },
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: 'none',
    }),
    valueContainer: (styles: any) => {
      return {
        ...styles,
        padding: '0px',
        width: '100%',
      }
    },
    container: (styles: any) => ({
      ...styles,
      width: '100%',
    }),
  }

  return (
    <div className="flex items-center">
      <Select
        options={countries}
        onChange={(v) => {
          if (v) changeCountry((v as any).value)
        }}
        value={countries?.filter((a) => a.value === country) as Array<any>}
        formatOptionLabel={(option, { context }) => {
          if (context === 'menu') return option.label
          else {
            return (
              <div className="flex items-center">
                <img src={countryData[option.value].flag} />
                <p className="pl-2">+{countryData[option.value].code}</p>
              </div>
            )
          }
        }}
        isSearchable={false}
        styles={customStyles}
      />
    </div>
  )
}

export default CountryCodeSelector
