import { navRoutes } from 'page_routes'
import { trackIeltsClick } from 'scripts/segment'
import { ieltsPageName } from 'utils/events-utils'
export enum CalendarView {
  LOADING = 'LOADING',
  LIVE_VIEW = 'LIVE_VIEW',
  FULL_CALENDAR_VIEW = 'FULL_CALENDAR_VIEW',
}

interface HeaderFilterProps {
  heading: string
  subHeading: string
  showDateFilters: boolean
  showTimeFilters: boolean
}

export const getHeaderFilterProps = (
  isLiveView: boolean,
): HeaderFilterProps => {
  if (isLiveView) {
    return {
      heading: 'When are you free for a 30 mins demo?',
      subHeading: 'Personal live session with top IELTS trainer',
      showDateFilters: false,
      showTimeFilters: false,
    }
  } else {
    return {
      heading: 'Select Preferred timing for 30 min Demo',
      subHeading: 'Personal live session with top IELTS trainer',
      showDateFilters: true,
      showTimeFilters: true,
    }
  }
}

export interface TimeRange {
  startHour: number
  endHour: number
  disabled: boolean
}

export const timeRangeArray: Array<TimeRange> = [
  {
    startHour: 10,
    endHour: 14,
    disabled: false,
  },
  {
    startHour: 14,
    endHour: 19,
    disabled: false,
  },
  {
    startHour: 19,
    endHour: 23,
    disabled: false,
  },
]

export const noSlotsModalConten = {
  title: 'Thank you for signing up with Leap Scholar!',
  subtitle:
    'All demo sessions are currently booked. We will let you know once a demo session is available.',
  ctaAction: () => {
    trackIeltsClick(ieltsPageName.NO_DEMO_SLOTS, {
      contentName: 'Go to dashboard',
    })
    window.open(navRoutes.STUDENT_IELTS_DASHBOARD, '_self')
  },
  description:
    'Meanwhile, go to your dashboard to explore other offerings that will help you in IELTS Preparation',
  imageURL: '/assets/images/profile/retry-modal.svg',
  ctaText: 'Go to dashboard',
}

export enum IeltsDemoStages {
  TEACHER_MODAL,
  FORM_STAGE,
  TECAHER_SCREEN,
}
