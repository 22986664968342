import axios from 'axios'
import { useEffect, useState } from 'react'

declare global {
  interface Window {
    Verloop: any
  }

  interface HTMLElement {
    async: any
    src: string
  }
}

function useVerloopChatbot() {
  const [leadDetails, setLeadDetails] = useState<any>()

  useEffect(() => {
    axios
      .get('/api/scholarRoute?path=student/basic')
      .then((res) => {
        setLeadDetails(res?.data?.data)
      })
      .catch((e) => console.error(e))
  }, [])

  // triggers verloop chatbot functionality
  const openVerloopChatBot = (customRecipi: string) => {
    // checks if verloop script is already added or not
    if (
      document.getElementById('verloop_leap') === null ||
      document.getElementById('verloop_leap') === undefined
    ) {
      // adds verloop script to the window
      ;(function (w, d, s, u) {
        w.Verloop = function (c: any) {
          w.Verloop._.push(c)
        }
        w.Verloop._ = []
        w.Verloop.url = u
        const h = d.getElementsByTagName(s)[0] as any,
          j = d.createElement(s)
        j.id = 'verloop_leap'
        j.async = true
        j.src = 'https://leapscholar.verloop.io/livechat/script.min.js'
        h.parentNode.insertBefore(j, h)

        w.Verloop(function (this: any) {
          this.setRecipe(customRecipi ? customRecipi : 'dJaHkeZFuvWLxsSBJ')

          this.setUserId(leadDetails?.email, 'visitorToken')
          this.setUserParams({
            name: leadDetails?.name,
            email: leadDetails?.email,
            phone: leadDetails?.phone,
          })
        })
      })(window, document, 'script', 'https://leapscholar.verloop.io/livechat')
    }

    window.Verloop(function (this: any) {
      this.openWidget()
    })
  }

  return [openVerloopChatBot]
}

export default useVerloopChatbot
