import axios from "axios";
import { getTokenCookie } from "lib/auth-cookies-new";
import { NextPageContext } from "next";

const fetchBatchDetails = async (batchId: string, ctx: NextPageContext) => {
  try {
    const accessToken = getTokenCookie(ctx);
    let headers: { [key: string]: string } = {};

    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
    headers["X-API-VERSION"] = "4";

    const res = await axios.get(
      `${process.env.SCHOLAR_URL}/v2/workshop/${batchId}/details`,
      { headers }
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (err: any) {
    console.log(err);
  }
};

const fetchBatchDetailsClientSide = async (batchId: string) => {
  try {
    const headers = {
      "X-API-VERSION": "4",
    };
    const res = await axios.get(
      `/api/scholarRoute?path=v2/workshop/${batchId}/details`,
      { headers }
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (err: any) {
    console.log(err);
  }
};

const fetchBatchTeacherDetails = async (
  teacherId: string,
  ctx: NextPageContext
) => {
  try {
    const accessToken = getTokenCookie(ctx);
    let headers;
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
    const res = await axios.get(
      `${process.env.SCHOLAR_URL}/teacher/${teacherId}/details`,
      { headers }
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (err: any) {
    console.log(err);
  }
};

const fetchBatchTeacherDetailsClient = async (teacherId: string) => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=teacher/${teacherId}/details`
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (err: any) {
    console.log(err);
  }
};

export const batchDetailsApi = {
  fetchBatchDetails,
  fetchBatchTeacherDetails,
  fetchBatchDetailsClientSide,
  fetchBatchTeacherDetailsClient,
};
