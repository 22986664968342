import {
  CookieReqContext,
  CookieResContext,
  getCookie,
  saveUtmCookie,
} from 'utils/cookie-utiils'

type UtmData = {
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  utmTerm?: string
  campaignType?: string
  adId?: string
  trackingId?: string
  referer?: string
}

export function getUtmData(ctx: CookieReqContext): UtmData | undefined {
  let utmSource, utmCampaign, campaignType, adId, trackingId, utmMedium, referer
  if (getCookie(ctx, 'utmSource')) {
    utmSource = getCookie(ctx, 'utmSource') as string
  }
  if (getCookie(ctx, 'utmCampaign')) {
    utmCampaign = getCookie(ctx, 'utmCampaign') as string
  }
  if (getCookie(ctx, 'utmMedium')) {
    utmMedium = getCookie(ctx, 'utmMedium') as string
  }
  const utmTerm = getCookie(ctx, 'utmTerm')
  if (getCookie(ctx, 'campaignType')) {
    campaignType = getCookie(ctx, 'campaignType') as string
  }
  if (getCookie(ctx, 'adId')) {
    adId = getCookie(ctx, 'adId') as string
  }
  if (getCookie(ctx, 'trackingId')) {
    trackingId = getCookie(ctx, 'trackingId') as string
  }
  if (getCookie(ctx, 'referer')) {
    referer = getCookie(ctx, 'referer') as string
  }
  return {
    utmSource,
    utmCampaign,
    utmTerm,
    campaignType,
    adId,
    trackingId,
    utmMedium,
    referer,
  } as UtmData
}

export function saveUtmData(ctx: CookieResContext, utmData: UtmData) {
  if (utmData.utmSource) {
    saveUtmCookie(ctx, 'utmSource', utmData.utmSource)
  }
  if (utmData.utmCampaign) {
    saveUtmCookie(ctx, 'utmCampaign', utmData.utmCampaign)
  }
  if (utmData.utmMedium) {
    saveUtmCookie(ctx, 'utmMedium', utmData.utmMedium)
  }
  if (utmData.utmTerm) {
    saveUtmCookie(ctx, 'utmTerm', utmData.utmTerm)
  }
  if (utmData.campaignType) {
    saveUtmCookie(ctx, 'campaignType', utmData.campaignType)
  }
  if (utmData.adId) {
    saveUtmCookie(ctx, 'adId', utmData.adId)
  }
  if (utmData.trackingId) {
    saveUtmCookie(ctx, 'trackingId', utmData.trackingId)
  }
  if (utmData.referer) {
    saveUtmCookie(ctx, 'referer', utmData.referer)
  }
}
