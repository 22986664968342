import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const filtersSliceName = 'Filters'

interface TimeRange {
  startHour: number
  endHour: number
}
export interface FiltersSchema {
  selectedDate: string
  selectedTimeRange: TimeRange | null
}
const initialState: FiltersSchema = {
  selectedDate: '',
  selectedTimeRange: null,
}

const filtersSlice = createSlice({
  name: filtersSliceName,
  initialState,
  reducers: {
    setDate(state, action: PayloadAction<string>) {
      return { ...state, selectedDate: action.payload }
    },
    setTimeRange(state, action: PayloadAction<TimeRange | null>) {
      return { ...state, selectedTimeRange: action.payload }
    },
  },
})

export const { setDate, setTimeRange } = filtersSlice.actions

export default filtersSlice.reducer
