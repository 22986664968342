import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'
import { studyMaterialActions } from './StudyMaterialActions'
import { studyMaterialApi } from './StudyMaterialApi'

function* fetchStudyMaterial(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(studyMaterialApi.fetchStudyMaterial, action.payload)
    yield put(studyMaterialActions.fetchStudyMaterialSuccess(res))
  } catch (e: any) {
    console.error(e)
    yield put(studyMaterialActions.fetchStudyMaterialFail(e.message))
  }
}

function* fetchFastTrackStudyMaterial(): Generator<any, any, any> {
  try {
    const res = yield call(studyMaterialApi.fetchFastTrackStudyMaterial)
    yield put(studyMaterialActions.fetchStudyMaterialSuccess(res))
  } catch (e: any) {
    console.error(e)
    yield put(studyMaterialActions.fetchStudyMaterialFail(e.message))
  }
}

function* studyMaterialSaga() {
  yield takeLatest('STUDY_MATERIAL_FETCH_REQUESTED', fetchStudyMaterial)
  yield takeLatest(
    'FAST_TRACK_STUDY_MATERIAL_FETCH_REQUESTED',
    fetchFastTrackStudyMaterial
  )
}

export default studyMaterialSaga
