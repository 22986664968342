const fetchRecordings = (payload: string) => ({
  type: "RECORDINGS_FETCH_REQUESTED",
  payload,
});

const fetchRecordingsSuccess = (payload: any) => ({
  type: "RECORDINGS_FETCH_SUCCESS",
  payload,
});

const fetchRecordingsFail = (payload: any) => ({
  type: "RECORDINGS_FETCH_FAIL",
  payload,
});

const fetchVideo = (payload: any) => ({
  type: "VIDEO_FETCH_REQUESTED",
  payload,
});

const fetchVideoSuccess = (payload: any) => ({
  type: "VIDEO_FETCH_SUCCESS",
  payload,
});

const fetchVideoFail = (payload: any) => ({
  type: "VIDEO_FETCH_FAIL",
  payload,
});

const updateRecordings = (payload: any) => ({
  type: "UPDATE_RECORDINGS",
  payload
})

export const classRecordingsActions = {
  fetchRecordings,
  fetchRecordingsSuccess,
  fetchRecordingsFail,
  fetchVideo,
  fetchVideoSuccess,
  fetchVideoFail,
  updateRecordings
}
