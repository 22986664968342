import axios from "axios";
import { apoloClient } from "apollo/apollo";
import {
  FETCH_IELTS_QUIZ,
  FETCH_QUIZ_DETAILS,
} from "apollo/queries/ielts_quiz";
import { QuizQuestionSubmitPayloadDto } from "Interfaces/reducerTypes/IeltsPlusQuizSchema";

const fetchQuizList = async (batchId: string) => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=api/workshop/${batchId}/quiz-bundle/summary`
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const fetchIeltsQuizListCms = async (querySlug: [string]) => {
  try {
    const res = await apoloClient.query({
      query: FETCH_IELTS_QUIZ,
      variables: { slugArray: querySlug },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

// const quizUserStatusReq = async (id: string) => {
//   try {
//     const res = await axios.get(
//       `/api/scholarRoute?path=quiz/userQuizInfo/${id}`
//     );
//     return res.data;
//   } catch (err: any) {
//     console.log(err);
//   }
// };
const quizUserStatusReq = async (
  id: string,
  quizBundleId: number,
  batchId: number
) => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=quiz-bundle/result/${batchId}/${quizBundleId}/${id}`
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const quizEndAttemptReq = async (payload: {
  quizId: string;
  attemptId: number;
}) => {
  try {
    const res = await axios.post(`/api/scholarRoute?path=quiz/end`, {
      endTime: new Date(),
      ...payload,
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const rewardQuizEndAttemptReq = async (payload: {
  quizId: string;
  attemptId: number;
  quizBundleId: number | null;
}) => {
  try {
    const headers = {
      "X-API-VERSION": "1",
    };
    const res = await axios.post(
      `/api/scholarRoute?path=quiz-bundle/quiz/end`,
      {
        endTime: new Date(),
        ...payload,
      },
      { headers }
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const fetchQuizDetailCms = async (id: string) => {
  try {
    const res = await apoloClient.query({
      query: FETCH_QUIZ_DETAILS,
      variables: { slugId: id },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const quizStartAttemptReq = async (id: string) => {
  try {
    const res = await axios.post(`/api/scholarRoute?path=quiz/${id}/start`);
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const rewardQuizStartAttemptReq = async (
  id: string,
  quizBundleId: number | null,
  batchId: number | null
) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=quiz-bundle/${batchId}/${quizBundleId}/${id}/start`
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const quizQueAnsSubmit = async (payload: QuizQuestionSubmitPayloadDto) => {
  try {
    const res = await axios.post(`/api/scholarRoute?path=quiz/submission`, {
      ...payload,
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const rewardQuizQueAnsSubmit = async (
  payload: QuizQuestionSubmitPayloadDto
) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=quiz-bundle/quiz/submit`,
      {
        ...payload,
      }
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const fetchQuizModalType = async (quizId: string) => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=quiz/courseRecomendation/${quizId}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const fetchBundleQuizList = async (payload: {
  batchId: string;
  priority: number;
}) => {
  try {
    const headers = {
      "X-API-VERSION": "1",
    };
    const res = await axios.get(
      `/api/scholarRoute?path=api/workshop/${payload.batchId}/quiz-bundle/${payload.priority}`,
      { headers }
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const quizApis = {
  fetchQuizList,
  fetchIeltsQuizListCms,
  fetchQuizDetailCms,
  quizStartAttemptReq,
  quizEndAttemptReq,
  quizUserStatusReq,
  quizQueAnsSubmit,
  fetchQuizModalType,
  fetchBundleQuizList,
  rewardQuizEndAttemptReq,
  rewardQuizQueAnsSubmit,
  rewardQuizStartAttemptReq,
};
