import { BASE_ICON_PATH } from 'components/Chat/constants'
import InternetCheckAlertBox from 'components/common/InternetCheckAlertBox'
import { AuthProps } from 'components/decorator/WithAuth'
import { referAndEarnActions } from 'components/Profile/ReferAndEarn/redux/ReferAndEarnActions'
import { counsellingStageRank } from 'DataMapper/Profile/StageMapper'
import dynamic from 'next/dynamic'
import { Dispatch, FunctionComponent, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Action } from 'redux'
import Footer from './Footer'
import Navbar from 'components/common/Navbar'

const NeedHelpV1 = dynamic(() => import('components/NeedHelpV1/NeedHelpV1'))

interface LayoutProps extends AuthProps {
  children: React.ReactNode
  showConnect?: boolean
  handleConnectClick?: Function
  isHeaderHidden?: boolean
  isFooterHidden?: boolean
  customNavbar?: React.ReactNode
  showCustomNavbar?: boolean
  haveBottomPadding?: boolean
  counsellingStage: string
  fetchUserInfoData: Function
  userInfo: any
  shouldShowBackButton?: boolean
}

const Layout: FunctionComponent<LayoutProps> = ({
  auth,
  children,
  isHeaderHidden = false,
  isFooterHidden = false,
  counsellingStage,
  fetchUserInfoData,
  userInfo,
  shouldShowBackButton = false,
  customNavbar = null,
}) => {
  const [showNeedHelpModal, setShowNeedHelpModal] = useState<boolean>(false)
  const loggedIn = auth?.isValid
  const timeout = useRef<any>()

  useEffect(() => {
    if (showNeedHelpModal) {
      fetchUserInfoData()
    }
  }, [showNeedHelpModal])

  useEffect(() => {
    return clearTimeout(timeout.current)
  })

  const checkHistory = () => {
    if (window) {
      const history = window.history
      console.log(history.length)
      if (history.length > 2) window.history.back()
    }
  }

  const isFooterVisible = () => {
    return !loggedIn || counsellingStageRank[counsellingStage] < 10
  }

  return (
    <div>
      {!isHeaderHidden && (
        <>
          {customNavbar ? customNavbar : <Navbar />}
          {showNeedHelpModal && (
            <NeedHelpV1
              setShowNeedHelp={setShowNeedHelpModal}
              userInfo={userInfo}
            />
          )}
        </>
      )}
      {shouldShowBackButton && (
        <img
          src={`${BASE_ICON_PATH}/back_button_dark.svg`}
          alt="back"
          className="mt-4 ml-4"
          onClick={() => {
            checkHistory()
          }}
        />
      )}
      <InternetCheckAlertBox />
      {children}

      {!isFooterVisible() || isFooterHidden ? null : <Footer />}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  auth: state.auth.auth,
  counsellingStage: state.spotCounselling.stage,
  userInfo: state.referAndEarn.userInfo,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  fetchUserInfoData: () => {
    dispatch(referAndEarnActions.fetchUserInfo())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
