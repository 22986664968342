import { FunctionComponent } from 'react'
import DownloadAppNavNudge from 'components/Nudges/AppDownloadNudges/DownloadAppNavNudge'
import useDevice from 'hooks/useDevice'
import { getIeltsPageName } from 'utils/events-utils'
import { getAndroidDeepLink } from 'utils/appNudgeUtils'
import { useRouter } from 'next/router'
import { trackIeltsClick } from 'scripts/segment'

const DownloadAppNavController: FunctionComponent = ({}) => {
  const { isIos, isMobile } = useDevice()
  const router = useRouter()
  const clickAction = () => {
    trackIeltsClick(getIeltsPageName(), {
      contentName: 'Use App NavBar',
      contentFormat: 'Button',
    })
    const deeplink = getAndroidDeepLink(router.pathname)
    window.open(deeplink)
  }
  if (!isMobile || isIos || !getIeltsPageName()) return null
  else
    return (
      <div>
        <DownloadAppNavNudge text="Use IELTS App" onClick={clickAction} />
      </div>
    )
}
export default DownloadAppNavController
