const fetchNpsStatus = () => ({
  type: "FETCH_NPS_STATUS",
});

const fetchFastTrackNpsStatus = () => ({
  type: "FETCH_FAST_TRACK_NPS_STATUS",
});

const fetchNpsStatusSuccess = (payload: boolean) => ({
  type: "FETCH_NPS_STATUS_SUCCESS",
  payload,
});

const updateNpsStatus = (payload: boolean) => ({
  type: "UPDATE_NPS_STATUS",
  payload,
});

const updateError = (message: string) => ({
  type: "UPDATE_ERROR_MESSAGE",
  payload: message,
});

const submitRatings = (payload: any) => ({
  type: "SUBMIT_NPS_RATINGS",
  payload,
});

const submitForm = (payload: any) => ({
  type: "SUBMIT_NPS_FORM",
  payload,
});

const updateQuestion = (payload: any) => ({
  type: "UPDATE_NPS_QUESTION",
  payload,
});

const updateNpsId = (payload: string) => ({
  type: "UPDATE_NPS_ID",
  payload,
});

const updateQuestionType = (payload: string) => ({
  type: "UPDATE_QUESTION_TYPE",
  payload,
});

const updateQuestionTitle = (payload: string) => ({
  type: "UPDATE_QUESTION_TITLE",
  payload,
});

export const npsActions = {
  fetchNpsStatus,
  fetchNpsStatusSuccess,
  updateNpsStatus,
  updateError,
  submitRatings,
  submitForm,
  updateQuestion,
  updateNpsId,
  updateQuestionType,
  updateQuestionTitle,
  fetchFastTrackNpsStatus,
};
