import { IeltsScreenData } from 'DataMapper/IeltsProfile/StudentDashboard'

const setIeltsView = (payload: IeltsScreenData) => ({
  type: 'SET_IELTS_SCREEN',
  payload,
})

const setIeltsScreenLoading = (payload: boolean) => ({
  type: 'IELTS_SCREEN_LOADING',
  payload,
})
const fetchUserStates = () => ({
  type: 'FETCH_USER_STATES',
})

const setUserStates = (payload: any) => ({
  type: 'SET_USER_STATES',
  payload,
})

const fetchDemoDetails = (payload: any) => ({
  type: 'FETCH_DEMO_DETAILS',
  payload,
})

const setDemoDetails = (payload: any) => ({
  type: 'SET_DEMO_DETAILS',
  payload,
})

const fetchUserIeltsDetailsReq = () => ({
  type: 'FETCH_USER_IELTS_DETAILS_REQUEST',
})

const fetchUserIeltsDetailsSuccess = (payload: any) => ({
  type: 'FETCH_USER_IELTS_DETAILS_SUCCESS',
  payload,
})

const fetchUserPaymentInfo = (payload: string) => ({
  type: 'FETCH_USER_PAYMENT_INFO',
  payload,
})
const fetchUserPaymentInfoSuccess = (payload: any) => ({
  type: 'FETCH_USER_PAYMENT_INFO_SUCCESS',
  payload,
})

export const ieltsScreenControllerActions = {
  setIeltsView,
  setIeltsScreenLoading,
  fetchUserStates,
  setUserStates,
  fetchDemoDetails,
  setDemoDetails,
  fetchUserIeltsDetailsReq,
  fetchUserIeltsDetailsSuccess,
  fetchUserPaymentInfo,
  fetchUserPaymentInfoSuccess,
}
