export const chatActionsConstants = {
  FEEDBACK_RESPONSE: "FEEDBACK_RESPONSE",
  FEEDBACK_RESPONSE_SUCCESS: "FEEDBACK_RESPONSE_SUCCESS",
  FEEDBACK_RESPONSE_ERROR: "FEEDBACK_RESPONSE_ERROR",
  RATING_RESPONSE: "RATING_RESPONSE",
  RATING_RESPONSE_SUCCESS: "RATING_RESPONSE_SUCCESS",
  RATING_RESPONSE_ERROR: "RATING_RESPONSE_ERROR",
  UNSATISFACTORY_RESPONSE: "UNSATISFACTORY_RESPONSE",
  UNSATISFACTORY_RESPONSE_SUCCESS: "UNSATISFACTORY_RESPONSE_SUCCESS",
  UNSATISFACTORY_RESPONSE_ERROR: "UNSATISFACTORY_RESPONSE_ERROR",
};

const feedbackResponse = (payload: any) => ({
  type: chatActionsConstants.FEEDBACK_RESPONSE,
  payload: payload,
});

const ratingResponse = (payload: any) => ({
  type: chatActionsConstants.RATING_RESPONSE,
  payload: payload,
});

const unsatisfiedResponse = (payload: any) => ({
  type: chatActionsConstants.UNSATISFACTORY_RESPONSE,
  payload: payload,
});

export const chatActions = {
  feedbackResponse,
  ratingResponse,
  unsatisfiedResponse,
};
