import { batchDetailsActions } from 'components/Ielts/BatchDetails/redux/BatchDetailsAction'
import { counsellingFormActions } from 'components/Profile/CounsellingFormExperiment/redux/CounsellingFormActions'
import { ieltsRegistrationFormActions } from 'components/Profile/IeltsRegistrationForm/redux/IeltsRegistrationFormActions'
import { ieltsRegistrationFormApi } from 'components/Profile/IeltsRegistrationForm/redux/IeltsRegistrationFormApi'
import { IeltsToCounsellingEligibility } from 'components/TofuOnbordingForms/SpotCounsellingProgressiveForm/IeltsToCounselling/IeltsToCounsellingApi'
import sha256 from 'crypto-js/sha256'
import { eventCategory, eventName } from 'DataMapper/EventTracker/EventData'
import { eventTracker } from 'eventTracker'
import { fireTagEvent, logEvent } from 'ga'
import { State } from 'Interfaces/storeSchema'
import { getUserInfo } from 'lib/getUserInfo'
import { createOrder } from 'lib/Razorpay/payment_util'
import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  eligibleForNewFlow,
  eligibleForNewFlowByPrice,
  isPremiumBundleCheck,
} from 'utils/ielts_batch_utils'
import { priceBundleName } from 'DataMapper/Common/WorkshopData'

function* saveData(action: AnyAction): Generator<any, any, any> {
  const freeTrailEligibility = yield select(
    (state: State) => state.ieltsRegistrationForm.ieltsFreeTrailEligiblity,
  )
  if (freeTrailEligibility) {
    logEvent('Ielts free trial', 'start free trial', 'start free trial cta')
  }
  try {
    yield put(ieltsRegistrationFormActions.toggleCtaText('Saving'))
    const res = yield call(ieltsRegistrationFormApi.saveForm, {
      ...action.payload.values,
      formId: 'I2C_form',
    })
    yield call(eventTracker, eventName.INITIATE_CHECKOUT, {
      category: 'Ielts',
      action: eventName.INITIATE_CHECKOUT,
      label: '',
      batchId: parseInt(action.payload.batchId, 10),
    })
    yield call(fireTagEvent, 'ielts_initiate_checkout')
    if (res.success) {
      yield put(
        counsellingFormActions.updatePostFetchEligibilityAction(
          (eligibilityData) => {
            IeltsToCounsellingEligibility(eligibilityData)
          },
        ),
      )
      yield put(counsellingFormActions.fetchEligibility())
      yield put(batchDetailsActions.toggleIeltsPlusForm(false))
      yield put(ieltsRegistrationFormActions.toggleCtaText('Next'))
      const requestBody = action.payload.requestBody
      const paymentReceived = yield call(
        createOrder,
        requestBody,
        action.payload.paymentType,
        'Get IELTS Plus access',
        action.payload.batchId,
      )
      if (paymentReceived) {
        const { batchData, batchId } = action.payload
        if (
          !eligibleForNewFlow(batchData.pricingBundleName) &&
          !eligibleForNewFlowByPrice(requestBody.amount)
        )
          yield put(ieltsRegistrationFormActions.toggleIeltsFormModal(true))
        logEvent(eventCategory.PAID_FOR_IELTS_PLUS, batchId, '')
        const userData = yield call(getUserInfo)
        yield call(fireTagEvent, 'ielts_pro_registration', {
          value: isPremiumBundleCheck(action.payload.paymentType)
            ? '2499'
            : '999',

          currency: batchData?.currency,
          content_id: batchId,
          content_type: action.payload.paymentType
            ? action.payload.paymentType
            : 'IELTS_PLUS',
          hashed_email: sha256(action.payload.values?.email || '').toString(),
          hashed_phone: sha256(userData.phone).toString(),
        })
        yield put(ieltsRegistrationFormActions.setIeltsPlusPaymentSuccess(true))
      }
    } else {
      yield put(ieltsRegistrationFormActions.updateError(res.message))
    }
  } catch (e: any) {
    yield put(
      ieltsRegistrationFormActions.updateError(
        'Something went wrong. Please try after sometime.',
      ),
    )
    console.error(e)
  } finally {
    yield put(ieltsRegistrationFormActions.toggleCtaText('Next'))
  }
}

function* enrollToIeltsPlus(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(ieltsRegistrationFormActions.toggleCtaText('Saving'))
    const res = yield call(
      ieltsRegistrationFormApi.enrollToIeltsPlus,
      action.payload,
    )
    if (res.success) {
      yield put(ieltsRegistrationFormActions.toggleCtaText('Submit'))
      yield put(ieltsRegistrationFormActions.toggleIeltsFormModal(true))
    } else {
      yield put(ieltsRegistrationFormActions.updateError(res.message))
      yield put(ieltsRegistrationFormActions.toggleCtaText('Submit'))
    }
  } catch (e: any) {
    yield put(
      ieltsRegistrationFormActions.updateError(
        'Something went wrong. Please try after sometime.',
      ),
    )
    console.error(e)
  }
}

function* ieltsSaga() {
  yield takeLatest('SAVE_IELTS_FORM', saveData)
  yield takeLatest('ENROLL_TO_IELTS_PLUS_FORM', enrollToIeltsPlus)
}

export default ieltsSaga
