import axios from "axios";

export const getUserInfo = async (): Promise<UserInfoType | undefined> => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=student/basic`);
    if (res.data.success) {
      return res.data.data;
    } else {
      return undefined;
    }
  } catch (err:any) {
    console.log(err);
    return undefined;
  }
};

interface UserInfoType {
  name: string;
  email: string;
  phone: string;
}