import { upcomingApi } from 'components/Profile/IeltsProfile/UpcomingClass/redux/upcomingApi'
import {
  upcomingClassActions,
  upcomingType,
} from 'components/Profile/IeltsProfile/UpcomingClass/redux/UpcomingClassActions'
import { batchType } from 'DataMapper/Common/WorkshopData'
import { WorkshopSchema } from 'Interfaces/reducerTypes/IeltsPlusSchema'
import { sortBy } from 'lodash'
import moment from 'moment'
import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ieltsBatchSorting, repackUpcomingClass } from 'utils/batches'

function* fetchUpcomingClassData(): Generator<any, any, any> {
  try {
    const res = yield all([
      call(upcomingApi.fetchUpcomingClass),
      call(upcomingApi.enhanceUpcomingClass),
    ])
    const data = { ...res[0], ...res[1] }
    yield call(upcomingExtraClass, data)
    yield call(fetchOrientationClass, data)
    yield put(upcomingClassActions.fetchUpcomingClassSuccess(res))
    yield call(liveClassSuccess)
  } catch (e: any) {
    console.error(e)
    yield put(upcomingClassActions.fetchUpcomingClassFail(e.message))
  }
}

function* liveClassSuccess(): Generator<any, any, any> {
  try {
    yield put(upcomingClassActions.setLoading(true))
    const res = yield all([
      call(upcomingApi.fetchLiveClass),
      call(upcomingApi.enhanceLiveClass),
    ])
    const result = { ...res[0], ...res[1] }
    yield call(liveIeltsSection, result)
    yield call(liveMasterclassSection, result)
    yield put(upcomingClassActions.setLoading(false))
  } catch (e: any) {
    console.error(e)
    yield put(upcomingClassActions.setLoading(false))
    yield put(upcomingClassActions.fetchUpcomingClassFail(e.message))
  }
}

function* upcomingExtraClass(data: any): Generator<any, any, any> {
  try {
    const priorityList = ['SPEAKING_WORKSHOP', 'DOUBT_WORKSHOP']
    const filterUpcomingExtraClass = (data: any) =>
      data.workshopType != batchType.ONLINE_WORKSHOP &&
      data.workshopType != batchType.ORIENTATION_WORKSHOP
    const result = sortBy(
      repackUpcomingClass(data, filterUpcomingExtraClass, priorityList).filter(
        (data) => data
      ),
      (value: WorkshopSchema) => new Date(value.meetingStartTime)
    ).filter((data: WorkshopSchema) => moment(data.meetingStartTime) > moment())
    yield put(upcomingClassActions.fetchUpcomingextraClassSuccess(result))
  } catch (e: any) {
    console.error(e)
    yield put(upcomingClassActions.fetchUpcomingClassFail(e.message))
  }
}

function* liveIeltsSection(data: any): Generator<any, any, any> {
  try {
    const priorityList = [
      batchType.ONLINE_WORKSHOP,
      batchType.SPEAKING_WORKSHOP,
      batchType.MASTER_CLASS,
      batchType.DOUBT_WORKSHOP,
      batchType.FAST_TRACK_EXTRA_CLASSES,
    ]
    data[batchType.ONLINE_WORKSHOP] = ieltsBatchSorting(
      data[batchType.ONLINE_WORKSHOP]
    )
    const result = repackUpcomingClass(data, null, priorityList).filter(
      (data) => data
    )
    yield put(upcomingClassActions.setUpcomingIelts(result))
  } catch (e: any) {
    console.error(e)
    yield put(upcomingClassActions.fetchUpcomingClassFail(e.message))
  }
}

function* liveMasterclassSection(data: any): Generator<any, any, any> {
  try {
    const priorityList = ['BEGINNER_MASTER_CLASS', 'MASTER_CLASS']
    const result = repackUpcomingClass(data, null, priorityList).filter(
      (data) => data
    )
    yield put(upcomingClassActions.setUpcomingMasterclass(result))
  } catch (e: any) {
    console.error(e)
    yield put(upcomingClassActions.fetchUpcomingClassFail(e.message))
  }
}

function* fetchLiveMasterclass(action: AnyAction): Generator<any, any, any> {
  const res = yield call(upcomingApi.enhanceLiveClass, action.payload)
  if (res) {
    yield call(liveMasterclassSection, res)
  }
}

function* fetchVoiceRoomSaga(): Generator<any, any, any> {
  try {
    const res = yield call(upcomingApi.fetchVoiceRoomApi)
    if (res.success) {
      yield put(upcomingClassActions.fetchVoiceRoomSuccess(res.data))
    } else {
      yield put(
        upcomingClassActions.fetchUpcomingClassFail('Something Went Wrong!')
      )
    }
  } catch (e) {
    console.error(e)
    yield put(
      upcomingClassActions.fetchUpcomingClassFail('Something Went Wrong!')
    )
  }
}

function* fetchOrientationClass(data: any): Generator<any, any, any> {
  try {
    const filterUpcomingExtraClass = (data: any) =>
      data.workshopType == batchType.ORIENTATION_WORKSHOP
    const result = sortBy(
      repackUpcomingClass(data, filterUpcomingExtraClass).filter(
        (data) => data
      ),
      (value: WorkshopSchema) => new Date(value.meetingStartTime)
    )
    const enrolOrientationClass = result.filter((data) => data.isEnrolled)
    yield put(upcomingClassActions.setOrientationClass(result))
    yield put(
      upcomingClassActions.setOrientationEnrollment(enrolOrientationClass)
    )
  } catch (e) {
    console.error(e)
    yield put(
      upcomingClassActions.fetchUpcomingClassFail('Something Went Wrong!')
    )
  }
}

function* upcomingSaga() {
  yield takeLatest(
    upcomingType.UPCOMING_CLASS_FETCH_REQUESTED,
    fetchUpcomingClassData
  )
  yield takeLatest(upcomingType.LIVE_CLASS_FETCH_REQUESTED, liveClassSuccess)
  yield takeLatest(
    upcomingType.LIVE_MASTERCLASS_FETCH_REQUESTED,
    fetchLiveMasterclass
  )
  yield takeLatest(upcomingType.FETCH_VOICE_ROOM_DATA, fetchVoiceRoomSaga)
}

export default upcomingSaga
