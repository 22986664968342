import axios from "axios";
import { checkIfIeltsApp } from "components/LSRevamp/utils";
import { navRoutes } from "page_routes";
import { MutableRefObject } from "react";
import { toast } from "react-toastify";
import { trackForm, trackProduct } from "scripts/segment";
import { getProductCategory } from "../../utils";

export const IeltsToCounsellingEligibility = async (
  values: any,
  formId = "I2C_form"
) => {
  trackForm("Form Submitted", { ...values, formId });
  try {
    const res = await axios.get(`/api/scholarRoute?path=student/eligibility`);
    if (res?.data?.success) {
      const userData = await getUserData();
      if (userData?.data?.success) {
        const { ieltsStatus, phone, email, preferredCountry } = userData?.data?.data;
        const trackPayload = {
          eventCategory:
            res.data.data.reason === "SPOT_APPROVED"
              ? "Spot_Counselling"
              : "regular_counselling",
          productCategory: getProductCategory(res.data.data.slotType) || "",
          slotAvailable: "",
          qualificationType: ["NO_PROBLEM", "SPOT_APPROVED"].includes(
            res.data.data.reason
          )
            ? "Qualified"
            : "Not qualified",
          ieltsStatus: ieltsStatus || "NA",
          formId: formId,
          email: email || "NA",
          phone: phone || "NA",
        };
        trackProduct("Qualified for Product", trackPayload);
        if(res?.data?.data?.reason === 'SPOT_APPROVED') trackProduct(`Qualified for Product ${preferredCountry}`, trackPayload)
      } else {
        throw new Error("Something went wrong");
      }
    } else {
      throw new Error("Something went wrong");
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchSlot = async (
  setSlotType: Function,
  setIsLoading: Function,
  router: any,
  timer: MutableRefObject<any | null>,
  getSchedule: Function
) => {
  const isIeltsApp = checkIfIeltsApp();
  try {
    const res = await axios.get(`/api/scholarRoute?path=student/eligibility`);
    if (res?.data?.success) {
      getSchedule();
      const userData = await getUserData();
      if (userData?.data?.success) {
        const { ieltsStatus, phone, email } = userData?.data?.data;
        const trackPayload = {
          eventCategory:
            res.data.data.reason === "SPOT_APPROVED"
              ? "Spot_Counselling"
              : "regular_counselling",
          productCategory: getProductCategory(res.data.data.slotType) || "",
          slotAvailable: "",
          qualificationType: ["NO_PROBLEM", "SPOT_APPROVED"].includes(
            res.data.data.reason
          )
            ? "Qualified"
            : "Not qualified",
          ieltsStatus: ieltsStatus || "NA",
          formId: isIeltsApp ? "I2C_app" : "I2C_form",
          email: email || "NA",
          phone: phone || "NA",
        };
        trackProduct("Qualified for Product", trackPayload);
      }
      setSlotType(res?.data?.data?.slotType);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.error("Something went wrong");
      timer.current = setTimeout(() => {
        router.push({
          pathname: navRoutes.PROFILE,
        });
      }, 2000);
    }
  } catch (err: any) {
    setIsLoading(false);
    toast.error("Something went wrong");
    timer.current = setTimeout(() => {
      router.push({
        pathname: navRoutes.PROFILE,
      });
    }, 2000);
  }
};

export const getUserData = async () => {
  try {
    const userdata = await axios.get(
      `/api/scholarRoute?path=student/counselling-details`
    );
    return userdata;
  } catch (err: any) {
    console.log(err);
  }
};

export const saveUserData = async (payload: { [key: string]: any }) => {
  try {
    await axios.post(
      `/api/scholarRoute?path=student/spot-counseling/apply`, payload
    );
  } catch (err: any) {
    console.log(err);
  }
};
