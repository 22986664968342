import axios from 'axios'
import { batchType } from 'DataMapper/Common/WorkshopData'

const fetchUpcomingClass = async () => {
  try {
    const res = await axios.get(
      '/api/scholarRoute?path=workshop/enroll/upcoming',
      { headers: { 'X-API-VERSION': '3' } }
    )
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    console.log(err)
    throw new Error('Something went wrong. Please try after sometime')
  }
}

const enhanceUpcomingClass = async () => {
  try {
    const res = await axios.post(
      '/api/scholarRoute?path=api/workshop/upcoming',
      {
        enrollmentStatus: true,
        workshops: [batchType.ORIENTATION_WORKSHOP],
      }
    )
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    console.log(err)
    throw new Error('Something went wrong. Please try after sometime')
  }
}

const fetchLiveClass = async () => {
  try {
    const res = await axios.get('/api/scholarRoute?path=workshop/enroll/live')
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err) {
    console.log(err)
    throw new Error('Something went wrong. Please try after sometime')
  }
}

const enhanceLiveClass = async (includeNextClass?: number) => {
  try {
    const res = await axios.post('/api/scholarRoute?path=api/workshop/live', {
      workshops: [batchType.MASTER_CLASS, batchType.ONLINE_WORKSHOP],
      getBeforeLiveInMin: includeNextClass || 0,
    })
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err) {
    console.log(err)
    throw new Error('Something went wrong. Please try after sometime')
  }
}

const enhanceEnrolmentClass = async () => {
  try {
    const res = await axios.post(
      '/api/scholarRoute?path=api/enrollment/enroll',
      {
        workshops: [batchType.ORIENTATION_WORKSHOP],
      }
    )
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err) {
    console.log(err)
    throw new Error('Something went wrong. Please try after sometime')
  }
}

const fetchVoiceRoomApi = async () => {
  try {
    const res = await axios.get('/api/scholarRoute?path=voicerooms/live_now')
    if (res.data.success) {
      return res.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err) {
    console.log(err)
    throw new Error('Something went wrong. Please try after sometime')
  }
}

export const upcomingApi = {
  fetchUpcomingClass,
  fetchLiveClass,
  enhanceLiveClass,
  enhanceUpcomingClass,
  fetchVoiceRoomApi,
  enhanceEnrolmentClass,
}
