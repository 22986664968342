import axios from "axios";
import { batchType } from "DataMapper/Common/WorkshopData";

const fetchMasterclassSubscriptionStatus = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=workshop/master-class-subscription-status`
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const fetchMasterclassData = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=workshop/MASTER_CLASS/0/12/v3`
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const fetchBeginnerMasterclassData = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=workshop/${batchType.BEGINNER_MASTER_CLASS}/0/12/v3`
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const enrollForMasterclass = async () => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=workshop/master-class/subscribe`
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const fetchMasterclassSubscriptionState = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=v2/workshop/ieltsFreeCourse/status`
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};
const fetchMasterclassRecommendation = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=workshop/recommended/master-class`
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const masterclassApis = {
  fetchMasterclassSubscriptionStatus,
  fetchMasterclassData,
  enrollForMasterclass,
  fetchBeginnerMasterclassData,
  fetchMasterclassSubscriptionState,
  fetchMasterclassRecommendation,
};
