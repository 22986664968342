import axios from "axios";

const getExpertData = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=v2/workshop/MASTER_CLASS/0/12`
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const saveData = async (values: any) => {
  let res;
  try {
    res = await axios.post(
      `/api/scholarRoute?path=lead/country-form-spot`,
      values
    );
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
  if (res?.data?.success) {
    return true;
  } else {
    return res?.data?.message;
  }
};

const fetchMeetCounsellerSlot = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=scholar-calendar/slots/live/${4}`
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return res?.data?.message;
    }
  } catch (err: any) {
    console.log(err);
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const fetchTypedMeetCounsellerSlot = async (payload: any) => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=scholar-calendar/slots/live/${4}/${
        payload?.leadId
      }/${payload?.meetType}`
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return res?.data?.message;
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const confirmMeetCounsellerSlot = async (payload: any) => {
  const data = {
    minute: payload.minute || "",
    date: payload.date || "",
    duration: payload.duration,
    utmTerm: payload.utmTerm || "",
    utmCampaign: payload.utmCampaign || "",
    utmSource: payload?.reschedule ? "Rescheduled" : payload.utmSource || "",
    label: payload.label || "",
  };

  try {
    const res = await axios.post(
      `/api/scholarRoute?path=scholar-calendar/create/meeting/${
        payload?.meetingType || "OFFICE_HOUR"
      }`,
      data
    );
    if (res.data.success) {
      return true;
    } else {
      return res.data.message;
    }
  } catch (err: any) {
    console.log(err);
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const confirmRescheduleSlot = async (payload: any) => {
  const { minute, utmData, meetingId } = payload;
  const data = {
    utm_campaign: utmData.utm_campaign || "",
    utm_source: utmData.utm_source || "",
    utm_term: utmData.utm_term || "",
    utm_medium: utmData.utm_medium || "",
    campaign_type: utmData.campaign_type || "",
    form_id: utmData.formId?.length ? utmData.formId : null,
    ad_id: utmData.ad_id || "",
  };

  try {
    const res = await axios.post(
      `/api/scholarRoute?path=counselling/register/${minute}&reschedule=true&meetingId=${meetingId}`,
      data
    );
    if (res.data.success) {
      return res.data;
    } else {
      return res.data.message;
    }
  } catch (err: any) {
    console.log(err);
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const fetchMeetProfile = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=student/spot-profile`);
    if (res?.data?.success) {
      return res.data;
    } else {
      return res?.data?.message;
    }
  } catch (err: any) {
    console.log(err);
    throw new Error("Something went wrong. Please refresh the page");
  }
};

export const submitMeetProfile = async (values: any) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=student/spot-profile`,
      values
    );
    if (res?.data?.success) {
      return res;
    } else {
      return res?.data?.message;
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime");
  }
};

const fetchVisaCounsellerSlot = async (leadId: string) => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=scholar-calendar/slots/live/${4}/${leadId}/VISA_COUNSELLING`
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return res?.data?.message;
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

export const meetCounsellerApi = {
  getExpertData,
  saveData,
  fetchMeetCounsellerSlot,
  confirmMeetCounsellerSlot,
  confirmRescheduleSlot,
  fetchMeetProfile,
  submitMeetProfile,
  fetchTypedMeetCounsellerSlot,
  fetchVisaCounsellerSlot,
};
