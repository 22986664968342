import { all } from 'redux-saga/effects'
import ieltsSaga from 'components/Profile/IeltsProfile/IeltsPlus/redux/IeltsPlusSaga'
import financialCounsellingSaga from 'components/Profile/CounsellingProfile/FinancialCounselling/redux/FinancialCounsellingSaga'
import SpeakingClubCounsellingSaga from 'components/Profile/IeltsProfile/SpeakingClub/redux/SpeakingClubSaga'
import classRecordingsSaga from 'components/Profile/IeltsProfile/ClassRecordings/redux/ClassRecordingsSaga'
import noticeBoardSaga from 'components/Profile/IeltsProfile/NoticeBoard/redux/NoticeBoardSaga'
import studyMaterialSaga from 'components/Profile/IeltsProfile/StudyMaterial/redux/StudyMaterialSaga'
import MyClassSaga from 'components/Profile/IeltsProfile/MyClasses/redux/MyClassSaga'
import upcomingClassSaga from 'components/Profile/IeltsProfile/UpcomingClass/redux/upcomingSaga'
import referAndEarnSaga from 'components/Profile/ReferAndEarn/redux/ReferAndEarnSaga'
import counsellingSaga from 'components/Profile/CounsellingProfile/redux/CounsellingSaga'
import counsellingFormSaga from 'components/Profile/CounsellingFormExperiment/redux/CounsellingFormSaga'
import EssaySaga from 'components/Profile/IeltsProfile/EssayEvaluation/redux/EssaySaga'
import facultiesSaga from 'components/Ielts/Faculty/redux/FacultySaga'
import batchDetailsSaga from 'components/Ielts/BatchDetails/redux/BatchDetailsSaga'
import UpcomingIeltsBatchesSaga from 'components/Ielts/UpcomingIeltsBatches/redux/UpcomingIeltsSaga'
import updateProfileSaga from 'components/Profile/UpdateProfileFormsNew/redux/UpdateProfileSaga'
import ieltsRegistrationFormSaga from 'components/Profile/IeltsRegistrationForm/redux/IeltsRegistrationFormSaga'
import masterclassRegistrationSaga from 'components/Profile/MasterclassRegistrationForm/redux/MasterclassRegistrationFormSaga'
import ieltsAttendanceSaga from 'components/Profile/IeltsAttendanceScreen/redux/IeltsAttendanceSaga'
import ExtraClassSaga from 'components/Profile/IeltsProfile/ExtraClass/redux/ExtraClassSaga'
import ieltsNpsSaga from 'components/Profile/IeltsProfile/Nps/redux/NpsSaga'
import spotCounsellingSaga from 'components/SpotCounselling/redux/SpotCounsellingSaga'
import promoCodeSaga from 'components/Ielts/PromoCode/redux/PromoCodeSaga'
import mockTestSaga from 'components/Profile/IeltsProfile/MockTest/redux/MockTestSaga'
import fastTrackRegistrationSaga from 'components/Profile/FastTrackRegistrationForm/redux/FastTrackFormSaga'
import FastTrackSaga from 'components/Profile/IeltsProfile/FastTrack/redux/FastTrackSaga'
import FastTrackVideoSaga from 'components/Profile/FastTrackVideo/redux/FastTrackVideoSaga'
import intermediateScreenSaga from 'components/Profile/IntermediateScreen/redux/IntermediateScreenSaga'
import overviewSaga from 'components/Profile/CounsellingProfile/redux/sagas/overview-sagas'
import counsellingNoticeBoardSaga from 'components/Profile/CounsellingProfile/redux/sagas/notice-board-sagas'
import upcomingAppointmentsSaga from 'components/Profile/CounsellingProfile/redux/sagas/upcoming-appointments-sagas'
import documentsDetailsSaga from 'components/Profile/CounsellingProfile/redux/sagas/document-details-sagas'
import visaAssistanceSaga from 'components/Profile/CounsellingProfile/redux/sagas/visa-assistance-sagas'
import registrationFormSaga from 'components/Profile/RegistrationForm/redux/RegistrationFormSaga'
import masterclassSaga from 'components/Profile/IeltsProfile/Masterclass/redux/MasterclassSaga'
import ieltsTestSaga from 'components/Profile/IeltsProfile/IeltsTest/redux/IeltsTestSaga'
import onboardingSaga from 'components/Onboarding/redux/OnboardingSaga'
import ieltsCertificateSaga from 'components/Profile/IeltsProfile/Certificates/redux/CertificateSaga'
import documentsUploadSaga from 'components/DocumentsUpload/redux/DocumentUploadSaga'
import reviewSaga from 'components/Ielts/Reviews/redux/ReviewsSaga'
import meetCounsellerSaga from 'components/MeetCounsellerVirtualOffice/redux/MeetCounsellerSaga'
import storiesSaga from 'components/Profile/IeltsProfile/Stories/redux/StoriesSaga'
import aiEvalutionSaga from 'components/Ielts/AiEvaluation/redux/AiEvalutionSaga'
import MasterclassReplaysSaga from 'components/Profile/IeltsProfile/Masterclass/MasterclassReplays/redux/MasterclassReplaysSaga'
import PaymentSaga from 'components/Payment/redux/PaymentSaga'
import IeltsQuizSaga from 'components/Profile/IeltsProfile/QuizSection/redux/QuizSaga'
import semPageSaga from 'components/Ielts/SemLanding/redux/SemLandingSaga'
import masterClassTypeSaga from 'components/Ielts/IeltsMasterclassType/redux/MasterClassTypeSaga'
import joinMasterClassSaga from 'components/Profile/IeltsProfile/JoinMasterClass/redux/joinMasterClassSaga'
import chatSaga from 'components/Chat/redux/chatSaga'
import RequestCbSaga from 'components/Ielts/BatchDetails/RequestCallBackForm/redux/RequestCallBackFormSaga'
//tofu
import tofuOnbordingSaga from 'components/TofuOnbordingForms/redux/Saga'
import MockTestPriceSaga from 'components/Profile/IeltsProfile/MockTest/MockTestLandingPage/redux/MockTestPriceSaga'
import SchedulePageSaga from 'components/Profile/IeltsProfile/IeltsPlus/SchedulePage/redux/schedulePageSaga'
// ls commumity
import CommunitySaga from 'components/CounsellingCommunity/redux/CommunitySaga'
import practiseAppSaga from 'components/Profile/AndroidApp/redux/PractiseAppSaga'
import NeedHelpSaga from 'components/NeedHelpV1/redux/NeedHelpSaga'
import wordOfTheDaySaga from 'components/Profile/AndroidApp/PractiseTab/WordOfTheDay/redux/WordOfTheDaySaga'
import testPrepSaga from 'components/Profile/IeltsProfile/TestPrep/redux/TestPrepSaga'
import ieltsEliteSaga from 'components/Ielts/IeltsEliteDemo/redux/IeltsEliteSaga'
import ieltsScreenSaga from 'components/Profile/StudentDashbord/IeltsScreenController/redux/ieltsScreenSaga'
import ieltsDemoSaga from 'components/Ielts/IeltsDemo/redux/IeltsDemoSaga'
import DemoRewardsSaga from 'components/Profile/StudentDashbord/IeltsSection/IeltsDemoRewards/redux/DemoRewardSaga'

function* rootSaga() {
  yield all([
    ieltsSaga(),
    financialCounsellingSaga(),
    SpeakingClubCounsellingSaga(),
    classRecordingsSaga(),
    noticeBoardSaga(),
    studyMaterialSaga(),
    MyClassSaga(),
    upcomingClassSaga(),
    referAndEarnSaga(),
    counsellingSaga(),
    counsellingFormSaga(),
    EssaySaga(),
    facultiesSaga(),
    batchDetailsSaga(),
    UpcomingIeltsBatchesSaga(),
    updateProfileSaga(),
    ieltsRegistrationFormSaga(),
    masterclassRegistrationSaga(),
    ieltsAttendanceSaga(),
    ExtraClassSaga(),
    ieltsNpsSaga(),
    spotCounsellingSaga(),
    promoCodeSaga(),
    overviewSaga(),
    counsellingNoticeBoardSaga(),
    upcomingAppointmentsSaga(),
    documentsDetailsSaga(),
    visaAssistanceSaga(),
    mockTestSaga(),
    fastTrackRegistrationSaga(),
    FastTrackSaga(),
    FastTrackVideoSaga(),
    intermediateScreenSaga(),
    registrationFormSaga(),
    masterclassSaga(),
    ieltsTestSaga(),
    onboardingSaga(),
    ieltsCertificateSaga(),
    documentsUploadSaga(),
    reviewSaga(),
    meetCounsellerSaga(),
    storiesSaga(),
    aiEvalutionSaga(),
    MasterclassReplaysSaga(),
    PaymentSaga(),
    IeltsQuizSaga(),
    semPageSaga(),
    masterClassTypeSaga(),
    joinMasterClassSaga(),
    chatSaga(),
    RequestCbSaga(),
    tofuOnbordingSaga(),
    MockTestPriceSaga(),
    SchedulePageSaga(),
    CommunitySaga(),
    practiseAppSaga(),
    NeedHelpSaga(),
    wordOfTheDaySaga(),
    testPrepSaga(),
    ieltsEliteSaga(),
    ieltsScreenSaga(),
    ieltsDemoSaga(),
    DemoRewardsSaga(),
  ])
}

export default rootSaga
