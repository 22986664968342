import { TestPrepApi } from 'components/Profile/IeltsProfile/TestPrep/redux/TestPrepApi'
import {
  fetchGetDaignosticTests,
  fetchMockTest,
  fetchMockTestList,
  fetchMockTestModal,
  fetchMockTestPrice,
  saveForm,
  setDaignosticTests,
  setDaignosticTestsError,
  setInitiatePayment,
  setMockTest,
  setMockTestError,
  setMockTestIsLoading,
  setMockTestList,
  setMocktestModalData,
  setUnlockPrice,
} from 'components/Profile/IeltsProfile/TestPrep/redux/TestPrepSlice'
import { ieltsRegistrationFormApi } from 'components/Profile/IeltsRegistrationForm/redux/IeltsRegistrationFormApi'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchMockTestListSaga(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(setMockTestIsLoading(true))
    const res = yield call(TestPrepApi.fetchMockTestList, action.payload)
    yield put(setMockTestIsLoading(false))
    if (res.data.success) {
      yield put(setMockTestList(res.data.data))
    } else {
      yield put(setMockTestError('something went wrong'))
    }
  } catch (e) {
    yield put(setMockTestIsLoading(false))
    yield put(setMockTestError('something went wrong'))
  }
}

function* fetchMockTestSaga(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(setMockTestIsLoading(true))
    const res = yield call(TestPrepApi.fetchMockTest, action.payload)
    yield put(setMockTestIsLoading(false))

    if (res.data.success && res.data.data) {
      yield put(setMockTest(res.data.data))
    } else {
      yield put(
        setMockTestError(
          'something went wrong while fetching  mock test data please try after some time',
        ),
      )
    }
    yield put(setMockTestIsLoading(false))
  } catch (e) {
    yield put(setMockTestIsLoading(false))
    yield put(
      setMockTestError(
        'something went wrong while fetching  mock test data please try after some time',
      ),
    )
  }
}

function* fetchMockTestModalSaga(): Generator<any, any, any> {
  try {
    yield put(setMockTestIsLoading(true))
    const res = yield call(TestPrepApi.fetchModalDataCms)
    yield put(setMockTestIsLoading(false))
    if (res?.data?.length > 0) {
      yield put(setMocktestModalData(res?.data[0]))
    } else {
      yield put(setMockTestError('something went wrong'))
    }
  } catch (e) {
    yield put(setMockTestIsLoading(false))
    yield put(setMockTestError('something went wrong'))
  }
}
function* fetchMockTestPriceSaga(): Generator<any, any, any> {
  try {
    yield put(setMockTestIsLoading(true))
    const res = yield call(TestPrepApi.fetchMocktestPrice)
    yield put(setMockTestIsLoading(false))
    if (res?.data?.success) {
      yield put(setUnlockPrice(res?.data?.data))
    } else {
      yield put(setMockTestError('something went wrong'))
    }
  } catch (e) {
    yield put(setMockTestIsLoading(false))
    yield put(setMockTestError('something went wrong'))
  }
}

function* saveIeltsForm(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(ieltsRegistrationFormApi.saveForm, action.payload)
    if (res?.success) {
      yield put(setInitiatePayment(true))
    } else {
      yield put(setMockTestError('something went wrong'))
    }
  } catch (e) {
    yield put(setMockTestError('something went wrong'))
  }
}

function* fetchGetDaignosticTestsSaga(): Generator<any, any, any> {
  try {
    const res = yield call(TestPrepApi.fetchGetDaignosticTests)
    if (res.data?.success) {
      yield put(setDaignosticTests(res.data?.data))
    } else {
      yield put(setDaignosticTestsError(true))
    }
  } catch (error) {
    yield put(setDaignosticTestsError(true))
  }
}

function* testPrepSaga() {
  yield takeLatest(fetchMockTestList, fetchMockTestListSaga)
  yield takeLatest(fetchMockTest, fetchMockTestSaga)

  yield takeLatest(fetchMockTestModal, fetchMockTestModalSaga)
  yield takeLatest(fetchMockTestPrice, fetchMockTestPriceSaga)
  yield takeLatest(saveForm, saveIeltsForm)
  yield takeLatest(fetchGetDaignosticTests, fetchGetDaignosticTestsSaga)
}

export default testPrepSaga
