enum Currency {
  USD,
  INR,
  EUR,
  GBP,
  AUD,
  BDT,
}

export enum PaymentGatewayVendor {
  STRIPE,
  EBIX,
  RAZORPAY,
  LEAP_PAYMENT,
  RAZOR_PAY_LINK,
  BKASH,
}

enum PaymentPlatform {
  WEB,
  ANDROID,
  DEFAULT,
}

export enum PaymentType {
  COUNSELLING_FEE = 'COUNSELLING_FEE',
  IELTS_FEE = 'IELTS_FEE',
  FAST_TRACK_FEE = 'FAST_TRACK_FEE',
  IELTS_PREMIUM_FEE = 'IELTS_PREMIUM_FEE',
  IELTS_PREMIUM_2_FEE = 'IELTS_PREMIUM_2_FEE',
  IELTS_PREMIUM_3_FEE = 'IELTS_PREMIUM_3_FEE',
  SPEAKING_CLUB_FEE = 'SPEAKING_CLUB_FEE',
  SPEAKING_CLUB_SINGLE_CREDIT_FEE = 'SPEAKING_CLUB_SINGLE_CREDIT_FEE',
  IELTS_FOUNDATION_FEE = 'IELTS_FOUNDATION_FEE',
  REWARD_BOOSTER_FEE = 'REWARD_BOOSTER_FEE',
  TCY_MOCK_TEST_FEE = 'TCY_MOCK_TEST_FEE',
  AI_EVALUATION = 'AI_EVALUATION',
  IELTS_PLUS_PART_2_FEE = 'IELTS_PART_II_FEE',
  IELTS_SELECT_FEE = 'IELTS_SELECT_FEE',
  IELTS_ELITE_FEE = 'IELTS_ELITE_FEE',
  IELTS_CUSTOM_FEE = 'IELTS_CUSTOM_FEE',
}

export enum Status {
  CREATED,
  SUCCESS,
  FAILED,
  REFUNDED,
}

export interface PaymentDetailDto {
  orderId: string
  amount?: number
  currency?: Currency
  paymentGatewayVendor?: PaymentGatewayVendor
  paymentDate: Date
  paymentPlatform?: PaymentPlatform
  paymentType?: PaymentType
  paymentStatus: Status
  paymentReferenceId?: number
}

export enum BundleIds {
  TCY_MOCK_TEST = 'TCY_MOCK_TEST',
  IELTS_AI_EVAL_IELTS8 = 'IELTS_AI_EVAL_IELTS8',
}
