import { authActions } from 'components/decorator/redux/AuthActions'
import { loginApis } from 'components/Login/LoginApis'
import LoginPopup from 'components/Login/LoginPopup'
import { spotCounsellingActions } from 'components/SpotCounselling/redux/SpotCounsellingActions'
import { getUserData } from 'components/TofuOnbordingForms/SpotCounsellingProgressiveForm/IeltsToCounselling/IeltsToCounsellingApi'
import { countryData } from 'DataMapper/CountryCode'
import { eventCategory } from 'DataMapper/EventTracker/EventData'
import { fireTagEvent, logEvent } from 'ga'
import { useRouter } from 'next/router'
import { navRoutes } from 'page_routes'
import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { connect, useDispatch } from 'react-redux'
import { Action, Dispatch } from 'redux'
import { trackClick, trackSignup } from 'scripts/segment'
import { deleteCookie, saveCookie } from 'utils/cookie-utiils'
import { pageName } from 'utils/events-utils'
import { getUtmData } from 'utils/utm_utils'
import { v4 as uuidv4 } from 'uuid'

const MAX_AGE = 60 * 60 * 24 * 30

export const webFlowType = {
  WEB_FLOW_MASTERCLASS: 'webflow_masterclass',
  WEB_FLOW_FASTTRACK: 'webflow_fasttrack',
}

export const getTrackingId = (url_string: string) => {
  try {
    const url = new URL(window.location.origin + url_string)
    return url.searchParams.get('tracking_id')
  } catch (_) {
    return
  }
}

export const getLoginPageTitle = (
  url: string,
  trackingId: string | null | undefined,
) => {
  const callbackUrl = url.split('?')[0]
  if (
    callbackUrl &&
    (callbackUrl === navRoutes.FAST_TRACK_FORM ||
      callbackUrl.startsWith(navRoutes.FAST_TRACK_VIDEO))
  ) {
    if (trackingId === webFlowType.WEB_FLOW_FASTTRACK) {
      return 'Register for Fast Track'
    }
    return 'Login for IELTS Fast Track'
  } else if (trackingId === webFlowType.WEB_FLOW_MASTERCLASS) {
    return 'Register for Masterclass'
  } else return 'Login'
}

interface Props {
  isModalOpen: boolean
  title: string
  canClose: boolean
  preFilledPhone: string
  callback: Function
  flowType: string
  saveToken: Function
  canChangeCountryCode: boolean
  resetPreFilledPhone: () => void
  handleClose: () => void
}

const Login: FunctionComponent<Props> = ({
  isModalOpen,
  title,
  callback,
  canClose,
  preFilledPhone,
  flowType,
  saveToken,
  canChangeCountryCode,
  handleClose,
  resetPreFilledPhone,
}) => {
  const [phone, setPhone] = useState<string>('')
  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const [activeView, setActiveView] = useState<string>('phone')
  const [correlationId, setCorrelationId] = useState<string>('')
  const [whatsAppSubscription, setWhatsAppSubscription] =
    useState<boolean>(true)
  const [phoneText, setPhoneText] = useState<string>('Get OTP')
  const [phoneErrMsg, setPhoneErrMsg] = useState<string>('')
  const [otpText, setOtpText] = useState<string>('Proceed')
  const [otpErrMsg, setOtpErrMsg] = useState<string>('')
  const [phoneInfoMsg, setPhoneInfoMsg] = useState<string>('')
  const [otpSuccessMsg, setOtpSuccessMsg] = useState<string>('')
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showTruecaller, setShowTruecaller] = useState<boolean>(true)
  const [country, setCountry] = useState<string>('INDIA')
  const dispatch = useDispatch()

  const router = useRouter()
  const { samesite } = router.query
  const path = router.pathname
  const utmDetails = getUtmData(null)
  const callbackUrl = router.query.callbackUrl as string

  const eventParam = useMemo(() => {
    if (callbackUrl) {
      const trackinId = getTrackingId(callbackUrl)
      if (trackinId === webFlowType.WEB_FLOW_MASTERCLASS) {
        return {
          category: trackinId,
          label: 'IELTS - MasterClass Landing Page',
        }
      } else if (trackinId === webFlowType.WEB_FLOW_FASTTRACK) {
        return {
          category: trackinId,
          label: 'IELTS Fast Track',
        }
      } else return null
    }
    return null
  }, [callbackUrl])

  const generateOtp = async () => {
    trackClick(pageName.Login_Modal, {
      widgetName: 'Login Modal',
      widgetFormat: 'Modal',
      contentName: 'Get OTP',
      contentFormat: 'Button',
      phone: phone,
    })
    if (phone.length !== 10) {
      setPhoneErrMsg('Please enter a valid 10 digit number')
      return
    } else {
      setPhoneErrMsg('')
      setPhoneText('Please wait')
      try {
        const res = await loginApis.generateOtp(
          countryData[country].code,
          country,
          phone,
          whatsAppSubscription,
        )
        if (res.success) {
          setCorrelationId(res.data)
          setActiveView('otp')
          if (eventParam) {
            logEvent(eventParam.category, 'Get OTP', eventParam.label)
          }
        } else {
          setPhoneErrMsg(res.message)
        }
      } catch (err: any) {
        setPhoneErrMsg('Something went wrong. Please try after sometime')
      } finally {
        setPhoneText('Get OTP')
      }
    }
  }

  const validateOtp = async () => {
    trackClick(pageName.Login_Modal, {
      widgetName: 'Login Modal',
      widgetFormat: 'Modal',
      contentName: 'Submit OTP',
      contentFormat: 'Button',
      phone: phone,
    })
    setOtpErrMsg('')
    setOtpText('Please wait')
    try {
      const res = await loginApis.validateOtp(
        correlationId,
        otp.join(''),
        whatsAppSubscription,
      )
      if (res.success) {
        await storeToken(res.data, setOtpErrMsg)
        if (eventParam) {
          logEvent(eventParam.category, 'Submit OTP', eventParam.label)
        }
      } else {
        setOtpErrMsg(res.message)
      }
    } catch (err: any) {
      console.log(err.message, 'err?')
      deleteCookie(null, 'token')
      setOtpErrMsg('OTP is invalid')
    } finally {
      setOtpText('Proceed')
    }
  }

  const storeToken = async (token: string, cbError: Function) => {
    saveCookie(null, 'token', token, MAX_AGE, samesite as string)
    const registerRes = await loginApis.registerUser(flowType)
    if (registerRes.success) {
      const trackPayload = {
        pageName: path || 'NA',
        authType: registerRes?.data?.newUser ? 'Sign Up' : 'Sign In',
        utmCampaign: utmDetails?.utmCampaign || '',
        utmSource: utmDetails?.utmSource || '',
        utmTerm: utmDetails?.utmTerm || '',
        adId: utmDetails?.adId || '',
        flowType: flowType,
        userId: registerRes?.data?.userId || '',
        subscribedToWhatsapp: whatsAppSubscription ? true : false,
        phone: phone || 'NA',
        country: country || 'NA',
      }
      saveCookie(null, 'user_id', registerRes?.data?.userId || '')
      saveToken(token)
      logEvent('login success', 'login success', '')
      if (registerRes?.data?.newUser) {
        await fireTagEvent('complete registration')
        logEvent(eventCategory.NEW_IELTS_LEAD, router.asPath, '')
      }
      dispatch(spotCounsellingActions.fetchStage())
      const data = await getUserData()
      await trackSignup('Log In Successful', trackPayload)
      if(registerRes?.data?.newUser && data?.data?.data?.preferredCountry) await trackSignup(`Log In Successful ${data?.data?.data?.preferredCountry}`, trackPayload)
      setTimeout(() => {
        callback(registerRes.data.newUser)
        handleClose()
      }, 1)
    } else {
      deleteCookie(null, 'token')
      cbError(registerRes.message)
    }
  }

  const resendOtp = async () => {
    trackClick(pageName.Login_Modal, {
      widgetName: 'Login Modal',
      widgetFormat: 'Modal',
      contentName: 'Resend OTP',
      contentFormat: 'Button',
      phone: phone,
    })
    try {
      const res = await loginApis.resendOtp(
        country,
        correlationId,
        whatsAppSubscription,
      )
      if (res.success) {
        setOtpSuccessMsg('OTP resent successfully')
        if (eventParam) {
          logEvent(eventParam.category, 'Resend OTP', eventParam.label)
        }
      } else {
        setOtpErrMsg(res.message)
      }
    } catch (err: any) {
      setOtpErrMsg('Something went wrong. Please try after sometime')
    } finally {
      setTimeout(() => {
        setOtpSuccessMsg('')
      }, 5000)
    }
  }

  useEffect(() => {
    if (preFilledPhone) {
      setPhone(preFilledPhone)
    }
  }, [preFilledPhone])

  useEffect(() => {
    if (phone && preFilledPhone) {
      generateOtp()
      resetPreFilledPhone()
    }
  }, [phone])

  const truecallerDeepLink = (requestId: string) => {
    window.location.href = `truecallersdk://truesdk/web_verify?requestNonce=${requestId}&partnerKey=${process.env.PARTNER_KEY}&partnerName=${process.env.PARTNER_NAME}&title=${title}&&skipOption=Skip`
  }

  const truecallerRejectHandler = (error: string) => {
    setShowLoader(false)
    setShowTruecaller(false)
    setPhoneInfoMsg(error)
  }

  const truecallerErrorHandler = (error: string) => {
    setPhoneErrMsg(error)
    setShowLoader(false)
    setShowTruecaller(false)
  }

  const truecallerAcceptHandler = async (token: string) => {
    await storeToken(token, truecallerErrorHandler)
  }

  const truecallerDeepLinkActive = (requestId: string): Promise<string> =>
    new Promise((resolve, reject) => {
      let requestAttempt = 0
      const requestInterval = setInterval(async () => {
        const res = await loginApis.verifyTruecaller(
          requestId,
          whatsAppSubscription,
        )
        requestAttempt += 1
        if (res?.success) {
          if (res.data) {
            clearInterval(requestInterval)
            resolve(res.data)
          }
        }
        if (requestAttempt > 5 || !res?.success) {
          clearInterval(requestInterval)
          reject(
            'Oops ! Truecaller verification failed. You can login with OTP',
          )
        }
      }, 3000)
    })

  const trueCallerCheckout = (requestId: string): Promise<string> =>
    new Promise((resolve, reject) => {
      setTimeout(async function () {
        if (document.hasFocus()) {
          reject(
            'Looks like you don’t have Truecaller installed. You can login with OTP',
          )
        } else {
          resolve(requestId)
        }
      }, 600)
    })

  const truecallerLoginManager = async () => {
    trackClick(pageName.Login_Modal, {
      widgetName: 'Login Modal',
      widgetFormat: 'Modal',
      contentName: 'Login with Truecaller',
      contentFormat: 'Button',
    })
    setShowLoader(true)
    const requestId = uuidv4()
    loginApis.initTruecallerLogin(requestId)
    truecallerDeepLink(requestId)
    await trueCallerCheckout(requestId)
      .then(truecallerDeepLinkActive)
      .then(truecallerAcceptHandler)
      .catch(truecallerRejectHandler)
  }

  return (
    <LoginPopup
      phone={phone}
      handlePhoneChange={(e: ChangeEvent<HTMLInputElement>) => {
        setPhone(e.target.value)
      }}
      activeView={activeView}
      handleViewChange={(view: string) => setActiveView(view)}
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      generateOtp={generateOtp}
      whatsAppSubscription={whatsAppSubscription}
      handleWhatsAppChange={() =>
        setWhatsAppSubscription(!whatsAppSubscription)
      }
      otp={otp}
      handleOtpChange={(val: Array<any>) => setOtp(val)}
      validateOtp={validateOtp}
      canClose={canClose}
      title={title}
      resendOtp={resendOtp}
      phoneText={phoneText}
      phoneErrMsg={phoneErrMsg}
      otpText={otpText}
      otpErrMsg={otpErrMsg}
      otpSuccessMsg={otpSuccessMsg}
      truecallerLoginManager={truecallerLoginManager}
      showTruecaller={showTruecaller}
      showLoader={showLoader}
      phoneInfoMsg={phoneInfoMsg}
      canChangeCountryCode={canChangeCountryCode}
      country={country}
      changeCountry={(country: string) => setCountry(country)}
    />
  )
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  saveToken: (payload: any) => {
    dispatch(authActions.saveAuth(payload))
  },
})

export default connect(null, mapDispatchToProps)(Login)
