import axios from "axios";

const fetchNpsStatus = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=nps/eligible`);
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const fetchFastTrackNpsStatus = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=nps/eligible/fast_track`
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const submitRatings = async (data: any) => {
  let pathName = "";
  if (data.pathName) {
    pathName = `${window.location.pathname}/${data.pathName}`;
  }
  try {
    const body = {
      rating: data.ratings,
      originatingUrl: pathName || window.location.pathname,
    };
    const res = await axios.post(`/api/scholarRoute?path=nps/rating`, body);
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const submitForm = async (values: Record<string, any>) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=nps/supporting_answer`,
      values
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const npsApis = {
  fetchNpsStatus,
  submitRatings,
  submitForm,
  fetchFastTrackNpsStatus,
};
