import { useState, ComponentType, FC } from 'react'
import { useRouter } from 'next/router'
import { navRoutes } from 'page_routes'
import Login from 'components/Login'
import { useSelector } from 'react-redux'
import { AuthToken } from 'lib/auth_token'
import { counsellingStageRank } from 'DataMapper/Profile/StageMapper'

export type LoginProps = {
  initLogin: (
    title?: string,
    canClose?: boolean,
    phone?: string,
    flowType?: string,
    callback?: Function
  ) => void
  handleSpotCounsellingFlow?: () => void
}
export const withLogin =
  <P extends LoginProps>(
    Component: ComponentType<P>
  ): FC<Pick<P, Exclude<keyof P, keyof LoginProps>>> =>
  (props: Pick<P, Exclude<keyof P, keyof LoginProps>>) => {
    const counsellingStage = useSelector(
      (state: any) => state?.counselling?.counsellingStage
    )
    const authValue = useSelector((state: any) => state?.auth?.auth?.token)
    const authToken = new AuthToken(authValue)
    const isLoggedIn = authToken && authToken.isValid
    // ====
    const router = useRouter()
    const [showLoginView, setShowLoginView] = useState<boolean>(false)
    const [callback, setCallback] = useState<Function>(() => void 0)
    const [title, setTitle] = useState<string>(
      'Take the first step, to fulfill your study abroad dreams'
    )
    const [canClose, setCanClose] = useState<boolean>(true)
    const [preFilledPhone, setPrefilledPhone] = useState<string>('')
    const [flowType, setFlowType] = useState<string>('')
    const [canChangeCountryCode, setCanChangeCountryCode] =
      useState<boolean>(false)

    const initLogin = (
      title = 'Sign Up To Get Free Counselling',
      canClose = true,
      phone = '',
      flowType = 'counselling',
      callback: Function = (isNewUser: boolean) => {
        if (isNewUser) {
          router.push('/spot-counselling')
        } else {
          router.push(navRoutes.POSTLOGIN_COUNSELLING)
        }
      }
    ) => {
      setShowLoginView(true)
      setTitle(title)
      setCanClose(canClose)
      setPrefilledPhone(phone)
      setFlowType(flowType)
      setCallback(() => callback)
      flowType === 'ielts' && setCanChangeCountryCode(true)
    }

    const handleSpotCounsellingFlow = () => {
      if (isLoggedIn) {
        // check counselling stage and depending on stage we route user to different urls
        if (
          counsellingStage &&
          counsellingStageRank[counsellingStage] <=
            counsellingStageRank['LS_LEAD_CAPTURED']
        ) {
          router.push(navRoutes.SPOT_COUNSELLING)
        } else {
          router.push(navRoutes.POSTLOGIN_COUNSELLING)
        }
      } else {
        initLogin()
      }
    }

    return (
      <>
        <Component
          {...(props as P)}
          initLogin={initLogin}
          handleSpotCounsellingFlow={handleSpotCounsellingFlow}
        />
        {showLoginView && (
          <Login
            title={title}
            canClose={canClose}
            handleClose={() => setShowLoginView(false)}
            callback={callback}
            isModalOpen={showLoginView}
            preFilledPhone={preFilledPhone}
            flowType={flowType}
            resetPreFilledPhone={() => setPrefilledPhone('')}
            canChangeCountryCode={canChangeCountryCode}
          />
        )}
      </>
    )
  }
