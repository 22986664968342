import { call, put, takeLatest } from 'redux-saga/effects'
import { ieltsScreenApi } from 'components/Profile/StudentDashbord/IeltsScreenController/redux/ieltsSceenApi'
import { ieltsScreenControllerActions } from './IeltsScreenControllerActions'
import { AnyAction } from 'redux'

function* fetchIeltsUserStates(): Generator<any, any, any> {
  try {
    const res = yield call(ieltsScreenApi.fetchIeltsUserStates)
    if (res?.data) {
      yield put(ieltsScreenControllerActions.setUserStates(res.data))
    } else {
      yield put(ieltsScreenControllerActions.setUserStates(null))
    }
  } catch (e: any) {
    console.error(e)
    yield put(ieltsScreenControllerActions.setUserStates(null))
  }
}
function* fetchDemoBookingDetails(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(ieltsScreenApi.fetchDemoDetails, action.payload)
    if (res.data.success) {
      yield put(
        ieltsScreenControllerActions.setDemoDetails(res.data.data[0] ?? null),
      )
    } else {
      yield put(ieltsScreenControllerActions.setDemoDetails(null))
    }
  } catch (e: any) {
    console.error(e)
    yield put(ieltsScreenControllerActions.setDemoDetails(null))
  }
}

function* fetchUserIeltsDetailsSaga(): Generator<any, any, any> {
  try {
    const res = yield call(ieltsScreenApi.fetchIeltsUserDetails)
    if (res.data.success) {
      yield put(
        ieltsScreenControllerActions.fetchUserIeltsDetailsSuccess(
          res.data.data,
        ),
      )
    }
  } catch (e) {}
}

function* fetchUserPaymentInfoSaga(
  action: AnyAction,
): Generator<any, any, any> {
  try {
    const res = yield call(ieltsScreenApi.fetchIeltsPaymentInfo, action.payload)
    if (res.data.success) {
      yield put(
        ieltsScreenControllerActions.fetchUserPaymentInfoSuccess(res.data.data),
      )
    }
  } catch (e) {}
}

function* ieltsScreenSaga() {
  yield takeLatest('FETCH_USER_STATES', fetchIeltsUserStates)
  yield takeLatest('FETCH_DEMO_DETAILS', fetchDemoBookingDetails)
  yield takeLatest(
    'FETCH_USER_IELTS_DETAILS_REQUEST',
    fetchUserIeltsDetailsSaga,
  )
  yield takeLatest('FETCH_USER_PAYMENT_INFO', fetchUserPaymentInfoSaga)
}

export default ieltsScreenSaga
