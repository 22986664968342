import axios from "axios";

const fetchGetWordOfTheDay = async () => {
  try {
    const path = "/api/scholarRoute?path=wotd/get/word_of_the_day";
    const res = await axios.get(path);
    return res.data;
  } catch (error) {
    return { success: false, data: null };
  }
};
const fetchPostLike = async (wordOfTheDayId: number) => {
  try {
    const path = `/api/scholarRoute?path=wotd/update/like/${wordOfTheDayId}`;
    const res = await axios.post(path);
    return res.data;
  } catch (error) {
    return { success: false, data: null };
  }
};
export const wordOfTheDayApis = {
  fetchGetWordOfTheDay,
  fetchPostLike,
};
