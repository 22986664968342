import { initPaymentGateway } from 'lib/Razorpay/paymentGateWay'
import { priceBundleName } from 'DataMapper/Common/WorkshopData'
import { PaymentType } from 'Interfaces/common/PaymentSchema'

const ieltsBundlerPaymentTypeHandler = (
  priceBundler: priceBundleName | string,
) => {
  switch (priceBundler) {
    case priceBundleName.IELTS_FOUNDATION:
      return PaymentType.IELTS_FOUNDATION_FEE
    case priceBundleName.IELTS_PLUS_PREMIUM:
      return PaymentType.IELTS_PREMIUM_FEE
    case priceBundleName.IELTS_PLUS_PREMIUM_2:
      return PaymentType.IELTS_PREMIUM_2_FEE
    case priceBundleName.IELTS_PLUS_PREMIUM_3:
      return PaymentType.IELTS_PREMIUM_3_FEE
    case priceBundleName.BOOSTER_PACK:
      return PaymentType.REWARD_BOOSTER_FEE
    case priceBundleName.IELTS_PLUS_PART_2:
      return PaymentType.IELTS_PLUS_PART_2_FEE
    case priceBundleName.IELTS_SELECT:
    case priceBundleName.IELTS_SELECT_V2:
      return PaymentType.IELTS_SELECT_FEE
    case priceBundleName.IELTS_ELITE_V1:
      return PaymentType.IELTS_ELITE_FEE
    case priceBundleName.IELTS_CUSTOM:
      return PaymentType.IELTS_CUSTOM_FEE
    case priceBundleName.IELTS_PLUS:
    default:
      return PaymentType.IELTS_FEE
  }
}

export const createOrder = async (
  requestBody: any,
  paymentType: string,
  formDescription: string,
  batchId: string,
) => {
  const newPaymentType = ieltsBundlerPaymentTypeHandler(paymentType)
  const paymentInfo = { paymentType: newPaymentType, formDescription }
  const res = await initPaymentGateway(requestBody, paymentInfo, batchId)
  if (res != undefined) {
    return res
  }
}

export const getPaymentProductName = (paymentType?: PaymentType) => {
  switch (paymentType) {
    case PaymentType.IELTS_PREMIUM_3_FEE:
    case PaymentType.IELTS_ELITE_FEE:
      return 'IELTS Elite'
    case PaymentType.IELTS_SELECT_FEE:
      return 'IELTS Select'
    default:
      return 'Ielts Plus'
  }
}
