import axios from 'axios'

const fetchPostPaymentData = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=student/post-payment`)
    return res
  } catch (err: any) {
    throw new Error('Something went wrong. Please refresh the page')
  }
}

const fetchBookmarksData = async () => {
  try {
    const res = await axios.post(
      '/api/scholarRoute?path=scholarship-finder/get-bookmarks',
      {
        bookmarkType: 'SCHOLARSHIP',
      },
    )
    return res
  } catch (err: any) {
    throw new Error('Something went wrong. Please refresh the page')
  }
}

export const counsellingApi = {
  fetchPostPaymentData,
  fetchBookmarksData,
}
