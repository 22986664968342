import axios from "axios";

const fetchUserDataAndToken = async () => {
  try {
    const res = await axios.get(
      "/api/scholarRoute?path=community/generate/token"
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};

export const communityApi = {
  fetchUserDataAndToken,
};
