import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

export const ieltsEliteStateName = "IeltsElite";
export enum IeltsStageType {
  IELTS_DEMO_BOOKED = "IELTS_DEMO_BOOKED",
  IELTS_DEMO_ATTENDED = "IELTS_DEMO_ATTENDED",
  LS_USER_ACQUIRED = "LS_USER_ACQUIRED",
}
export type studentEligibilityType = {
  meetingLink: string;
  reason: string;
  slotType: number;
};
type PostGetStageActionType = (stage: IeltsStageType | null) => void;
type PostGetEligibilityActionType = (
  eligibility: studentEligibilityType | null
) => void;

type PostFillIeltsQeFormActionType = (formData: object | null) => void;
export interface IeltsEliteReducerSchema {
  isIeltsStageLoading: boolean;
  ieltsStageError: string | null;
  isStudentEligibilityLoading: boolean;
  isStudentEligibilityError: string | null;
  error: string | null;
  isIeltsEliteQeFormOpen: boolean;
  ieltsStage: IeltsStageType | null;
  studentEligibility: studentEligibilityType | null;
  showCongratsText: boolean;
  postGetStageAction: null | PostGetStageActionType;
  postGetEligibilityAction: null | PostGetEligibilityActionType;
  postFillIeltsQeFormAction: null | PostFillIeltsQeFormActionType;
}
const initialState: IeltsEliteReducerSchema = {
  isIeltsStageLoading: false,
  ieltsStageError: null,
  isStudentEligibilityLoading: false,
  isStudentEligibilityError: null,
  error: null,
  isIeltsEliteQeFormOpen: false,
  ieltsStage: null,
  studentEligibility: null,
  showCongratsText: false,
  postGetStageAction: null,
  postGetEligibilityAction: null,
  postFillIeltsQeFormAction: null,
};

export const fetchGetIeltsStage = createAction(
  `${ieltsEliteStateName}/fetchGetIeltsStage`
);

export const fetchGetStudentEligibility = createAction(
  `${ieltsEliteStateName}/fetchGetStudentEligibility`
);

export const fetchPostIeltsEliteNudgeForm = createAction<any>(
  `${ieltsEliteStateName}/fetchPostIeltsEliteNudgeForm`
);

export const fetchPostIeltsQeform = createAction(
  `${ieltsEliteStateName}/fetchPostIeltsQeform`
);

export const fetchPostDetailsIeltsClass = createAction<any>(
  `${ieltsEliteStateName}/fetchPostDetailsIeltsClass`
);

const ieltsEliteSlice = createSlice({
  name: ieltsEliteStateName,
  initialState,
  reducers: {
    toggleIsIeltsEliteQeFormOpen(state, action: PayloadAction<boolean>) {
      return { ...state, isIeltsEliteQeFormOpen: action.payload };
    },
    updateIeltsStage(state, action: PayloadAction<IeltsStageType>) {
      return { ...state, ieltsStage: action.payload };
    },
    updateStudentEligibility(
      state,
      action: PayloadAction<studentEligibilityType>
    ) {
      return { ...state, studentEligibility: action.payload };
    },
    setIeltsStageLoading(state, action: PayloadAction<boolean>) {
      return { ...state, ieltsStageLoading: action.payload };
    },
    setIeltsStageError(state, action: PayloadAction<string>) {
      return { ...state, ieltsStageError: action.payload };
    },
    setStudentEligibilityLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isStudentEligibilityLoading: action.payload };
    },
    setStudentEligibilityError(state, action: PayloadAction<string>) {
      return { ...state, isStudentEligibilityError: action.payload };
    },
    setShowCongratsText(state, action: PayloadAction<boolean>) {
      return { ...state, showCongratsText: action.payload };
    },
    updatePostGetStageAction(
      state,
      action: PayloadAction<PostGetStageActionType>
    ) {
      return { ...state, postGetStageAction: action.payload };
    },
    updatePostGetStudentEligibility(
      state,
      action: PayloadAction<PostGetEligibilityActionType>
    ) {
      return { ...state, postGetEligibilityAction: action.payload };
    },
    updatePostFillIeltsQeFormAction(
      state,
      action: PayloadAction<PostFillIeltsQeFormActionType>
    ) {
      return { ...state, postFillIeltsQeFormAction: action.payload };
    },
  },
});

export const {
  toggleIsIeltsEliteQeFormOpen,
  updateIeltsStage,
  updateStudentEligibility,
  setIeltsStageLoading,
  setIeltsStageError,
  setStudentEligibilityLoading,
  setStudentEligibilityError,
  setShowCongratsText,
  updatePostGetStageAction,
  updatePostGetStudentEligibility,
  updatePostFillIeltsQeFormAction,
} = ieltsEliteSlice.actions;
export default ieltsEliteSlice.reducer;
