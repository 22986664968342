import { masterclassApis } from 'components/Profile/IeltsProfile/Masterclass/redux/MasterclassApis'
import { WorkshopSchema } from 'Interfaces/reducerTypes/IeltsPlusSchema'
import { sortJSON } from 'lib/sortingJson'
import moment from 'moment'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'
import { actionConstant, masterclassTypeActions } from './MasterClassTypeAction'
import { masterclassTypeApi } from './MasterClassTypeApi'

function* getBeginnerMasterClassDataHandler(
  action: AnyAction
): Generator<any, any, any> {
  try {
    const isLogin = action.payload
    const res = isLogin
      ? yield call(masterclassApis.fetchBeginnerMasterclassData)
      : yield call(masterclassTypeApi.getBeginnerMasterClassData)
    if (res.success) {
      const masterclassData: Array<WorkshopSchema> = res.data.filter(
        (data: WorkshopSchema) => moment(data.meetingStartTime) > moment()
      )
      yield put(
        masterclassTypeActions.successBeginnerMasterClassData(
          sortJSON(masterclassData, 'meetingStartTime', true)
        )
      )
    } else {
      yield put(masterclassTypeActions.updateError(res.message))
    }
  } catch (e) {
    console.error(e)
    yield put(
      masterclassTypeActions.updateError(
        'Something went wrong. Please try after sometime'
      )
    )
  }
}

function* getClassicMasterClassDataHandler(
  action: AnyAction
): Generator<any, any, any> {
  try {
    const isLogin = action.payload
    const res = isLogin
      ? yield call(masterclassApis.fetchMasterclassData)
      : yield call(masterclassTypeApi.getClassicMasterClassData)
    if (res.success) {
      const masterclassData: Array<WorkshopSchema> = res.data.filter(
        (data: WorkshopSchema) => moment(data.meetingStartTime) > moment()
      )
      yield put(
        masterclassTypeActions.successClassicMasterClassData(
          sortJSON(masterclassData, 'meetingStartTime', true)
        )
      )
    } else {
      yield put(masterclassTypeActions.updateError(res.message))
    }
  } catch (e) {
    console.error(e)
    yield put(
      masterclassTypeActions.updateError(
        'Something went wrong. Please try after sometime'
      )
    )
  }
}

function* masterclassTypeSaga() {
  yield takeLatest(
    actionConstant.REQUEST_BEGINNER_MASTERCLASS_TYPE_DATA,
    getBeginnerMasterClassDataHandler
  )
  yield takeLatest(
    actionConstant.REQUEST_CLASSIC_MASTERCLASS_TYPE_DATA,
    getClassicMasterClassDataHandler
  )
}

export default masterclassTypeSaga
