import { AnyAction } from "redux";

const initialState: any = {
  isLoading: false,
  expertsData: [],
  error: null,
  stage: null,
  goalPlanData: {},
  slots: [],
  isEnrolled: false,
  isLoadingSlots: false,
  spotProfileData: null,
  isSubmittedSpotProfile: false,
  isLiveSlotAvailable: null,
  liveSlot: null,
  eligibility: {},
  eventsData: { upcomingEvents: [], bookedEvents: [] },
  profileLayout: {},
};

// create your reducer
const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case "FETCH_PROFILE_LAYOUT":
      return { ...state, isLoading: true };
    case "FETCH_PROFILE_LAYOUT_SUCCESS":
      return { ...state, profileLayout: action.payload, isLoading: false };
    case "FETCH_PROFILE_LAYOUT_FAIL":
      return { ...state, isLoading: false };
    case "ELIGIBILITY_FETCH_REQUESTED":
      return { ...state };
    case "ELIGIBILITY_FETCH_SUCCESS":
      return {
        ...state,
        eligibility: action.payload,
        saving: false,
      };
    case "ELIGIBILITY_FETCH_FAIL":
      return {
        ...state,
        error: action.payload,
        saving: false,
      };
    case "FETCH_UPCOMING_EVENTS":
      return {
        ...state,
      };
    case "FETCH_UPCOMING_EVENTS_SUCCESS":
      return {
        ...state,
        eventsData: action.payload,
      };
    case "FETCH_UPCOMING_EVENTS_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    case "FETCH_EXPERTS_DATA":
      return {
        ...state,
      };
    case "FETCH_EXPERTS_DATA_SUCCESS":
      return {
        ...state,
        expertsData: action.payload,
      };
    case "FETCH_EXPERTS_DATA_FAIL":
      return {
        ...state,
        error: action.payload,
        expertsData: [],
      };
    case "FETCH_STAGE":
      return {
        ...state,
      };
    case "FETCH_STAGE_SUCCESS":
      return {
        ...state,
        stage: action.payload,
      };
    case "FETCH_STAGE_FAIL":
      return {
        ...state,
        error: action.payload,
        stage: null,
      };
    case "FETCH_GOAL_PLAN_DATA":
      return {
        ...state,
      };
    case "FETCH_GOAL_PLAN_DATA_SUCCESS":
      return {
        ...state,
        goalPlanData: action.payload,
        error: null,
      };
    case "FETCH_GOAL_PLAN_DATA_FAIL":
      return {
        ...state,
        error: action.payload,
        goalPlanData: null,
      };
    case "FETCH_SPOT_COUNSELLING_SLOT":
      return {
        ...state,
        slots: [],
        isLoadingSlots: true,
      };

    case "UNAUTHENTICATED_FETCH_SPOT_COUNSELLING_SLOT":
      return {
        ...state,
        slots: [],
        isLoadingSlots: true,
      };

    case "FETCH_SPOT_COUNSELLING_SLOT_SUCCESS":
      return {
        ...state,
        slots: action.payload.spotCounselingCalendarResponses,
        isLoadingSlots: false,
        isLiveSlotAvailable: action.payload.liveSlotAvailable,
        liveSlot: action.payload.liveSlot,
      };
    case "FETCH_SPOT_COUNSELLING_SLOT_FAIL":
      return {
        ...state,
        error: action.payload,
        slots: [],
        isLoadingSlots: false,
        liveSlot: null,
        isLiveSlotAvailable: false,
      };
    case "CONFIRM_SPOT_COUNSELLING_SLOT_SUCCESS":
      return {
        ...state,
        isEnrolled: action.payload.data,
      };
    case "CONFIRM_SPOT_COUNSELLING_SLOT_FAIL":
      return {
        ...state,
        isEnrolled: false,
        error: action.payload,
      };
    case "CONFIRM_SPOT_COUNSELLING_SLOT":
      return {
        ...state,
        error: null,
        isEnrolled: false,
      };

    case "UNAUTHENTICATED_CONFIRM_SPOT_COUNSELLING_SLOT":
      return {
        ...state,
        error: null,
        isEnrolled: false,
      };

    case "CONFIRM_SPOT_RESCHEDULE_SLOT_SUCCESS":
      return {
        ...state,
        isEnrolled: action.payload.data,
      };
    case "CONFIRM_SPOT_RESCHEDULE_SLOT_FAIL":
      return {
        ...state,
        isEnrolled: false,
        error: action.payload,
      };
    case "CONFIRM_SPOT_RESCHEDULE_SLOT":
      return {
        ...state,
        error: null,
        isEnrolled: false,
      };
    case "FETCH_SPOT_PROFILE":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SPOT_PROFILE_SUCCESS":
      return {
        ...state,
        spotProfileData: action.payload,
        isLoading: false,
      };
    case "FETCH_SPOT_PROFILE_FAIL":
      return {
        ...state,
        error: action.payload,
        spotProfileData: null,
        isLoading: false,
      };
    case "SUBMIT_SPOT_PROFILE":
      return {
        ...state,
      };
    case "SUBMIT_SPOT_PROFILE_SUCCESS":
      return {
        ...state,
        isSubmittedSpotProfile: action.payload,
      };
    case "SUBMIT_SPOT_PROFILE_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
