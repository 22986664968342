import { AnyAction } from "redux";

const initialState: any = {
  recordingData: null,
  error: "",
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case "RECORDINGS_FETCH_SUCCESS":
      return {
        ...state,
        recordingData: action.payload,
      };
    case "RECORDINGS_FETCH_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    case "UPDATE_RECORDINGS":
      return {
        ...state,
        recordingData: action.payload,
      }
    case "VIDEO_FETCH_SUCCESS":
      return {
        ...state,
        recordingData: action.payload,
      };
    case "VIDEO_FETCH_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
