import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { userCategory } from 'components/Ielts/BatchDetails/IeltsBatchRequestCbFormWrapper'

export interface RequestCallBackReducer {
  isRequestCbFormOpen: boolean
  isRequestCbSucessModalOpen: boolean
  isLoading: boolean
  error: null | string
  ctaText: string
  requestedCallBackBefore: null | boolean
  isRequestCallBackStatusLoading: boolean
  callBackFormData: any
}
const initialState: RequestCallBackReducer = {
  isRequestCbFormOpen: false,
  isRequestCbSucessModalOpen: false,
  isLoading: false,
  error: null,
  ctaText: 'Submit',
  requestedCallBackBefore: null,
  isRequestCallBackStatusLoading: false,
  callBackFormData: null,
}
export const fetchRequestCbRequested = createAction<Record<string, any>>(
  'RequestCbPremium/fetchRequestCbRequested'
)

export const fetchRequestCallBackStatus = createAction<userCategory>(
  'RequestCbPremium/fetchRequestCallBackStatus'
)

const RequestCbSlice = createSlice({
  name: 'RequestCbPremium',
  initialState,
  reducers: {
    toggleRequestCbForm(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isRequestCbFormOpen: action.payload,
      }
    },
    toggleRequestCbSucessModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isRequestCbSucessModalOpen: action.payload,
      }
    },
    toggleCtaText(state, action: PayloadAction<string>) {
      return {
        ...state,
        ctaText: action.payload,
      }
    },
    fetchRequestCbSuccess(state) {
      return {
        ...state,
        isLoading: false,
        ctaText: 'Done',
      }
    },
    fetchRequestCbFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    },
    fetchRequestCbStatusSuccess(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isRequestCallBackStatusLoading: false,
        requestedCallBackBefore: action.payload,
      }
    },
    fetchRequestCbStatusFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isRequestCallBackStatusLoading: false,
        error: action.payload,
      }
    },
    savecallBackFormData(state, action: PayloadAction<any>) {
      return {
        ...state,
        callBackFormData: action.payload,
      }
    },
  },
})
export const {
  fetchRequestCbSuccess,
  fetchRequestCbFailure,
  toggleRequestCbForm,
  toggleRequestCbSucessModal,
  fetchRequestCbStatusSuccess,
  fetchRequestCbStatusFailure,
  savecallBackFormData,
} = RequestCbSlice.actions
export default RequestCbSlice.reducer
