import axios from "axios";

const fetchUpcomingAppointmentsRequest = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=profile/post/events`);
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

export const upcomingAppointmentsApi = {
  fetchUpcomingAppointmentsRequest,
};
