import axios from 'axios'

const fetchMockTestList = (examType: string) =>
  axios.get(`/api/scholarRoute?path=test-prep/dashboard/${examType}`, {
    headers: {
      'X-API-VERSION': '5',
    },
  })

const fetchMockTest = (examId: string) =>
  axios.get(`/api/scholarRoute?path=test-prep/card/${examId}`)

const fetchModalDataCms = () =>
  axios.get(`${process.env.CMS_URL}/mocktest-modals?status_eq=ACTIVE`)

const fetchMocktestPrice = () =>
  axios.get(`/api/scholarRoute?path=fee/mock-test/status`)

const fetchGetDaignosticTests = () =>
  axios.get('/api/scholarRoute?path=test-prep/diagnostic-test/result')

export const TestPrepApi = {
  fetchMockTestList,
  fetchMockTest,
  fetchModalDataCms,
  fetchMocktestPrice,
  fetchGetDaignosticTests,
}
