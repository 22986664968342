import axios from "axios";
import { getTokenCookie } from "lib/auth-cookies-new";
import { NextPageContext } from "next";

const fetchUpcomingIeltsPlusClass = async (ctx: NextPageContext) => {
  try {
    const accessToken = getTokenCookie(ctx);
    let headers: { [key: string]: string } = {};
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
    headers["X-API-VERSION"] = "5";
    const res = await axios.get(
      `${process.env.SCHOLAR_URL}/v2/workshop/ielts-class/home`,
      { headers }
    );
    if (res.data.success) {
      return { success: true, data: res.data.data };
    } else {
      return { success: false, data: res.data.message };
    }
  } catch (err: any) {
    console.log(err);
    return {
      success: false,
      data: "Something went wrong. Please try after sometime",
    };
  }
};

const fetchUpcomingIeltsPlusClassClientSide = async () => {
  try {
    const headers = {
      "X-API-VERSION": "5",
    };
    const res = await axios.get(
      `/api/scholarRoute?path=v2/workshop/ielts-class/home`,
      { headers }
    );
    if (res.data.success) {
      return { success: true, data: res.data.data };
    } else {
      return { success: false, data: res.data.message };
    }
  } catch (err: any) {
    console.log(err);
    return {
      success: false,
      data: "Something went wrong. Please try after sometime",
    };
  }
};

export const upcomingIeltsPlusBatchesApi = {
  fetchUpcomingIeltsPlusClass,
  fetchUpcomingIeltsPlusClassClientSide,
};
