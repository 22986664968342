import { isPlusBatch } from 'components/LSRevamp/ieltsPage/FreeIeltsCourse/FreeCrashCourseUtils'
import { checkIfMefuUser } from 'components/LSRevamp/utils'
import { batchType, priceBundleName } from 'DataMapper/Common/WorkshopData'
import { navRoutes } from 'page_routes'
import { isMobile } from 'react-device-detect'
import { mobileCheck } from 'utils/browserUtils'
import { isIeltsPlusPart2Batch, isPremiumBatch } from './ielts_batch_utils'

export const pageName = {
  Login_Modal: 'Login Modal',
  Event_Join_Page: 'Event Joining Page',
  Neutral_Landing_Page: 'Neutral Landing Page',
  UK_Landing_Page: 'UK Landing Page',
  Canada_Landing_Page: 'Canada Landing Page',
  Australia_Landing_Page: 'Australia Landing Page',
  USA_Landing_Page: 'USA Landing Page',
  Ireland_Landing_Page: 'Ireland Landing Page',
  Leapscholar_Overview_Page:
    checkIfMefuUser() === 'true'
      ? 'Leapscholar Overview Page - MeFu'
      : 'Leapscholar Overview Page',
  My_Universities: 'My Universities',
  Document_Details: 'Document Details',
  Notice_Board: 'Notice Board',
  Upcoming_Appointments: 'Upcoming Appointments',
  Financial_Assistance: 'Financial Assistance',
  Visa_Assistance: 'Visa Assistance',
  Webinar_Landing_Page: 'Webinar Landing Page',
  Profile_Routing_Page: 'Profile Routing Page',
  Calendar_Booking_Page: 'Calendar Booking Page',
  Calendar_Booking_Page_Join_Live_View: 'Calendar Booking Page Join Live View',
  Calendar_Booking_Page_Join_Live_View_V2:
    'Calendar Booking Page Join Live View V2',
  Pre_Session_Page: 'Pre-session Page',
  Form_Landing_page: 'Form Landing Page',
  Profile_Form_Page: 'Profile Form Page',
  Application_Profile_Page: 'Application Profile Page',
  Application_Document_Page: 'Application Document Page',
  IELTS_To_Counselling_Form_Page: 'IELTS To Counselling Form_Page',
  IELTS_To_Counselling_Form_Page_Exp1:
    'IELTS To Counselling Form_Page_question',
  Counselling_Chat_Page: 'Counselling Chat Page',
  BOFU_User_Dashboard: 'BOFU User Dashboard',
  BOFU_Slot_Page: 'BOFU slot page',
  Event_Landing_Page: 'Event Landing Page',
  Event_Specific_Page: 'Event Specific Page',
  Leapscholar_Chat: 'Leapscholar Chat',
  IELTS_Smart_Test_Landing_Page: 'IELTS SmartTests Landing Page',
  IELTS_SmartTests: 'IELTS SmartTests',
  SC_Profile_Form_Page: 'SC Profile Form Page',
  REPLAYS_PAGE: 'IELTS Replays',
  Join_Redirect_Page: 'Join Redirect Page',
  Counsellor_Chat_Page: 'Counsellor Chat page',
  RM_Chat_Page: 'RM Chat page',
  Chat_Main_Page: 'Chat main page',
  Direct_Live_Join_Page: 'Direct Live Join Page',
  Scholarship_Landing_Page: 'Scholarship Landing Page',
  Unresolved_reason: 'Unresolved Reason',
  Resolution_feedback: 'Resolution Feedback',
  Resolution_rating: 'Resolution rating',
  Onboard: 'onboard',
  IELTS_GIVING_IELTS_SOON: 'IELTS - Giving IELTS Soon',
  IELTS_BOOKING_SUBMISSION: 'IELTS - IELTS Booking Submission',
  LeapScholarHomePage: 'LeapScholar Home Page',
  CounsellorPage: 'Counsellor Page',
  AboutUsLandingPage: 'About Us Landing Page',
  ProfileI2CApp: 'Profile Page I2C',
  CalendarI2CApp: 'Calendar Page I2C',
  IeltsCounsellingTab: 'Counselling_tab',
  userBookmarksScreen: 'Profile Bookmarks',
}

export const getPageName = () => {
  if (process.browser) {
    const path = window?.location?.pathname || ''
    if (path === navRoutes.UK_HOMEPAGE) {
      return pageName.UK_Landing_Page
    } else if (path === navRoutes.USA_HOMEPAGE) {
      return pageName.USA_Landing_Page
    } else if (path === navRoutes.CANADA_HOMEPAGE) {
      return pageName.Canada_Landing_Page
    } else if (path === navRoutes.IRELAND_HOMEPAGE) {
      return pageName.Ireland_Landing_Page
    } else if (navRoutes.AUSTRALIA_HOMEPAGE) {
      return pageName.Australia_Landing_Page
    } else if (navRoutes.Form_Landing_page) {
      return pageName.Form_Landing_page
    } else {
      return pageName.Neutral_Landing_Page
    }
  }
}

export const ieltsPageName = {
  IELTS_COURSE_LANDING_PAGE: 'IELTS - Course Landing Page',
  IELTS_FREE_CRASH_COURSE_LANDING_PAGE: 'IELTS -Free Trial Landing Page',
  IELTS_MASTERCLASS_LANDING_PAGE: 'IELTS - MasterClass Landing Page',
  IELTS_COURSE_DESCRIPTION_PAGE: 'IELTS - Course Description Page',
  IELTS_FREE_TRAIL_COURSE_DESCRIPTION_PAGE:
    'IELTS - Free Trial Course Description Page',
  IELTS_OVERVIEW: 'IELTS - Overview',
  IELTS_My_Classes: 'IELTS - My Classes',
  IELTS_SPEAKING_CLUB_SLOTS_BOOKING: 'IELTS - Speaking Club Slots Booking',
  IELTS_COURSE_PAGE: 'IELTS - Course Page',
  IELTS_SPEAKING_CLUB_PAGE: 'IELTS - Speaking Club Page',
  IELTS_FAST_TRACK: 'IELTS Fast Track',
  IELTS_FAST_TRACK_VIDEO_PLAYER: 'IELTS Fast Track Video Player',
  IELTS_STUDY_MATERIAL_PAGE: 'IELTS - Study Material Page',
  IELTS_RECORDINGS_PAGE: 'IELTS - Recordings Page',
  IELTS_MOCK_TEST_PAGE: 'IELTS - Mock Test Page',
  IELTS_WRITING_TEST_PAGE: 'IELTS - Writing Test Page',
  IELTS_MASTERCLASS_PROFILE_PAGE: 'IELTS - Overview',
  IELTS_PLUS: 'IELTS Courses Profile Page',
  IELTS_MASTERCLASS_LANDING_PAGE_TEST1:
    'IELTS - MasterClass Landing Page Test1',
  IELTS_FAST_TRACK_STUDY_MATERIAL_PAGE:
    'IELTS Fast Track - Study Material Page',
  MASTERCLASS_PROFILE_PAGE: 'IELTS MasterClass Profile Page',
  IELTS_PROFILE_PAGE: 'IELTS Plus',
  FAST_TRACK_PROFILE_PAGE: 'IELTS Fast Track',
  IELTS_NEW_QUIZ_PAGE: 'IELTS New Quiz page',
  IELTS_COMPLETED_QUIZ_PAGE: 'IELTS Completed Quiz page',
  IELTS_QUIZ_RESULTS_PAGE: 'IELTS Quiz Results page',
  IELTS_ADDITIONAL_COURSE_PAGE: 'IELTS Additional Course page',
  IELTS_FOUNDATION_LANDING_PAGE_VIEW_ALL: 'IELTS Foundation Landing Page',
  IELTS_PLUS_LANDING_PAGE_VIEW_ALL: 'IELTS Plus Landing Page',
  IELTS_BEGINNER_MASTERCLASS_LANDING_PAGE_VIEW_ALL:
    ' IELTS - Beginner MasterClass Landing Page',
  IELTS_CLASSIC_MASTERCLASS_LANDING_PAGE_VIEW_ALL:
    ' IELTS - Classic MasterClass Landing Page',
  IELTS_COURSES_PROFILE_PAGE: 'IELTS Courses Profile Page',
  IELTS_NEW_LANDING_PAGE: 'IELTS New Landing Page',
  COUNSELLING_PAGE: 'Counselling Page',
  CRASH_COURSE_REWARDS_PAGE: 'Crash Course - Rewards Page',
  CRASH_COURSE_QUIZ_PAGE: 'Crash Course - Quiz Page',
  CRASH_COURSE_TAKE_QUIZ_NOW: 'Crash Course - Take Quiz now',
  NEW_REWARDS_PAGE: 'New Rewards Page',
  NEW_REWARDS_POP_UP: 'New Rewards - Pop up',
  MOCK_TEST_LANDING_PAGE: 'Crash Course - Mock Test Page',
  NEW_TCY_ONLINE_PAGE: 'New TCYonline page',
  CLASS_SCHEDULE_PAGE: 'Class Schedule Page',
  IELTS_PLUS_CHALLENGING_PAGE: 'IELTSPlus Challenge Landing Page',
  IELTS_CALLBACK_PAGE: 'IELTS - IELTS Booking Callback',
  IELTS_APP_PRACTICE_TAB: 'IELTS App - Practice Tab',
  IELTS_PLUS_START_BATCH_LANDING_PAGE: 'Ielts Plus Start Batch Landing Page',
  IELTS_TO_COUNSELLING_PAGE: 'IELTS To Counselling Form_Page',
  IELTS_PLUS_EXPIRY_MODAL: 'IELTS Plus Expiry Modal',
  IELTS_PLUS_LANDING_PAGE: 'IELTS Plus Landing Page',
  IELTS_BATCH_CARD_PAGE: 'IELTS Batch Card page',
  IELTS_PLUS_CONFIRMATION_MODAL: 'IELTS Plus Confirmation Modal',
  IELTS_ELITE_INPAGE_FORM_1: 'Elite LP Form 1',
  IELTS_ELITE_INPAGE_FORM_2: 'Elite LP Form 2',
  DEMO_PROG_FORM: 'Demo Prog Form',
  IELTS_ELITE_LP_FORM: 'Elite LP Form',
  IELTS_ELITE_DEMO_BOOKING_PAGE: 'Elite Demo Booking Form',
  D2C_FORM: 'D2C Form',
  MOCK_FORM: 'mock_form',
  IELTS_ALL_BATCHES_VIEW_ALL_PAGE: 'IELTS All Batches Page',
  MOCK_TEST_DASHBOARD: 'Mock Test Dashboard',
  KNOW_MORE_MOCK_TEST_DASHBOARD: 'Know more from Mock Test Dashboard',
  DEMO_CALENDAR_THREE_SLOTS: 'Demo Calendar Three Slots',
  DEMO_CALENDAR_ALL_SLOTS: 'Demo Calendar All Slots',
  DEMO_CONFIRMATION_SCREEN: 'Demo Confirmation Screen',
  IELTS_TOOLKIT_PAGE: 'IELTS Toolkit Page',
  IELTS_TOOLKIT_UNLOCKED_CARD: 'IELTS Toolkit Unlocked Card',
  IELTS_TOOLKIT_LOCKED_CARD: 'IELTS Toolkit Locked Card',
  NO_DEMO_SLOTS: 'No demo slots',
  BPT_REDIRECT_PAGE: 'BPT Redirect Page',
  GENERIC_BUNDLE_PAYMENT_PAGE: 'Generic Bundle Payment Page',
  IELTS_PAYMENT_DETAILS: 'Payment details',
}

export const getIeltsPageName = () => {
  if (process.browser) {
    const path = window?.location?.pathname || ''
    switch (path) {
      case navRoutes.IELTS_OVERVIEW:
        return ieltsPageName.IELTS_COURSE_PAGE
      case navRoutes.IELTS_MYCLASSES:
        return ieltsPageName.IELTS_My_Classes
      case navRoutes.IELTS_COURSE:
        return ieltsPageName.IELTS_COURSE_PAGE
      case navRoutes.IELTS_FAST_TRACK:
        return ieltsPageName.IELTS_FAST_TRACK
      case navRoutes.IELTS_SPEAKING_CLUB:
        return ieltsPageName.IELTS_SPEAKING_CLUB_PAGE
      case navRoutes.IELTS_MASTERCLASS:
        return ieltsPageName.MASTERCLASS_PROFILE_PAGE
      case navRoutes.IELTS_SECTION:
        return ieltsPageName.IELTS_PLUS
      case navRoutes.IELTS_PAGE:
        return ieltsPageName.IELTS_MASTERCLASS_LANDING_PAGE
      case navRoutes.NEW_MASTERCLASS_LANDING_PAGE:
        return ieltsPageName.IELTS_NEW_LANDING_PAGE
      case navRoutes.IELTS_STUDY_MATERIAL:
        return ieltsPageName.IELTS_STUDY_MATERIAL_PAGE
      case navRoutes.STUDENT_IELTS_DASHBOARD:
        return ieltsPageName.IELTS_COURSES_PROFILE_PAGE
      case navRoutes.PRACTISE_TAB:
        return ieltsPageName.IELTS_APP_PRACTICE_TAB
      case navRoutes.EXPIRED_CLASS:
      case navRoutes.FREE_RESOURCE:
        return ieltsPageName.IELTS_BATCH_CARD_PAGE
      case navRoutes.MASTERCLASS_TYPE:
        return ieltsPageName.MASTERCLASS_PROFILE_PAGE
      case navRoutes.IELTS_TEST_PREP_MOCK_TEST:
        return ieltsPageName.MOCK_TEST_DASHBOARD
      case navRoutes.IELTS_ONBOARDING_SCREEN:
        return ieltsPageName.IELTS_PAYMENT_DETAILS
      default:
        return ''
    }
  }
}

export const getBatchType = (batchData) => {
  if (batchData?.workshopType) {
    switch (batchData?.workshopType) {
      case batchType.ONLINE_WORKSHOP:
        if (
          batchData.pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM
        ) {
          return 'IELTS Premium'
        } else if (
          batchData.pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM_2
        ) {
          return 'IELTS Premium 2'
        } else if (batchData.pricingBundleName === 'IELTS_FOUNDATION') {
          return 'IELTS Foundation'
        } else if (isIeltsPlusPart2Batch(batchData)) {
          return 'IELTS Plus Part 2'
        } else {
          return 'IELTS Plus'
        }
      case batchType.DOUBT_WORKSHOP:
        return 'IELTS ExtraClass'
      case batchType.FAST_TRACK_EXTRA_CLASSES:
        return 'IELTS Fast Track ExtraClass'
      case batchType.SPEAKING_WORKSHOP:
      case batchType.SPEAKING_ELITE_WORKSHOP:
        return 'IELTS Speaking Club'
      case batchType.MASTER_CLASS:
        return 'IELTS Classic MasterClass'
      case batchType.BEGINNER_MASTER_CLASS:
        return 'IELTS Beginner MasterClass'
      case batchType.ORIENTATION_WORKSHOP:
        return 'Orientation Class'
    }
  } else if (batchData?.pricingBundleName) {
    if (batchData.pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM) {
      return 'IELTS Premium'
    } else if (
      batchData.pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM_2
    ) {
      return 'IELTS Premium 2'
    } else if (batchData.pricingBundleName === 'IELTS_FOUNDATION') {
      return 'IELTS Foundation'
    } else {
      return 'IELTS Plus'
    }
  } else return ''
}

export const getMasterClassType = (batchData) => {
  if (batchData?.workshopType === batchType.BEGINNER_MASTER_CLASS) {
    return 'Beginner MasterClass'
  } else if (batchData?.workshopType === batchType.MASTER_CLASS) {
    return 'Classic MasterClass'
  }
  return ''
}

export const onboardingStepsPage = {
  STEP_ONE: 'Onboarding step1',
  STEP_TWO: 'Onboarding step2',
  STEP_THREE: 'Onboarding step3',
  STEP_FOUR: 'Onboarding step4',
}

export const LeadCaptureStepsPage = {
  STEP_ONE: 'Prog_Form_P1',
  STEP_TWO: 'Prog_Form_P2',
  STEP_THREE: 'Prog_Form_P3',
  STEP_FOUR: 'Prog_Form_P4',
  STEP_FIVE: 'Prog_Form_P5',
  STEP_SIX: 'Prog_Form_P6',
  STEP_SEVEN: 'Prog_Form_P7',
  STEP_EIGHT: 'Prog_Form_P8',
  STEP_NINE: 'Prog_Form_P9',
}

export const getBatchTagName = (batchData) => {
  if (batchData.workshopType) {
    switch (batchData?.workshopType) {
      case batchType.ONLINE_WORKSHOP:
        if (batchData.pricingBundleName === priceBundleName.IELTS_FOUNDATION) {
          return 'Foundation'
        } else if (isPlusBatch(batchData) || isIeltsPlusPart2Batch(batchData)) {
          return 'IELTS Plus'
        } else return 'IELTS Premium'
      case batchType.DOUBT_WORKSHOP:
        return 'IELTS ExtraClass'
      case batchType.FAST_TRACK_EXTRA_CLASSES:
        return 'IELTS Fast Track ExtraClass'
      case batchType.SPEAKING_WORKSHOP:
        return 'IELTS Speaking Club'
      case batchType.MASTER_CLASS:
        return 'Classic'
      case batchType.BEGINNER_MASTER_CLASS:
        return 'Beginner'
    }
  } else if (batchData?.pricingBundleName) {
    if (isPremiumBatch(batchData)) {
      return 'IELTS Premium'
    } else {
      return 'IELTS Plus'
    }
  } else return ''
}

export const getBatchTagSkin = (batchData) => {
  if (batchData.workshopType) {
    switch (batchData?.workshopType) {
      case batchType.ONLINE_WORKSHOP:
        if (batchData.pricingBundleName === priceBundleName.IELTS_FOUNDATION) {
          return 'primary'
        }
        if (isPremiumBatch(batchData)) {
          return 'success'
        } else {
          return 'orange'
        }
      case batchType.DOUBT_WORKSHOP:
        return 'warning'
      case batchType.FAST_TRACK_EXTRA_CLASSES:
        return 'success'
      case batchType.SPEAKING_WORKSHOP:
        return 'success'
      case batchType.MASTER_CLASS:
        return 'warning'
      case batchType.BEGINNER_MASTER_CLASS:
        return 'success'
    }
  } else if (batchData?.pricingBundleName) {
    if (batchData.pricingBundleName === priceBundleName.IELTS_PLUS) {
      return 'warning'
    } else {
      return 'primary'
    }
  }
  return 'primary'
}

export const platforms = {
  android: 'ANDROID',
  web: 'WEB',
  mweb: 'MWEB',
}

export const getPlatform = () => {
  const params = new URL(document.location).searchParams
  const platform = params.get('platform')
  if (platform == 'app') return platforms.android
  else if (isMobile) return platforms.mweb
  else return platforms.web
}

export const getPlatformV2 = () => {
  const params = new URL(document.location).searchParams
  const platform = params.get('platform')
  if (platform == 'app') return platforms.android
  if (mobileCheck()) return platforms.mweb
  return platforms.web
}
