import withWindowDimensions from 'components/common/withWindowDimensions'
import { LoginProps, withLogin } from 'components/Login/withLogin'
import Image from 'next/image'
import Link from 'next/link'
import { navRoutes } from 'page_routes'
import React, { FunctionComponent } from 'react'
import { trackClick } from 'scripts/segment'
import { pageName } from 'utils/events-utils'

const links = {
  countries: [
    {
      country: 'USA',
      link: '/usa',
    },
    {
      country: 'UK',
      link: '/uk',
    },
    {
      country: 'Canada',
      link: '/canada',
    },
    {
      country: 'Australia',
      link: '/australia',
    },
    {
      country: 'Ireland',
      link: '/ireland',
    },
  ],
  topUniversities: [
    {
      text: 'Top Universities in USA',
      link: '/usa/university/top-universities-in-usa',
    },
    {
      text: 'Top Universities in Canada',
      link: '/canada/university/top-universities-in-canada',
    },
    {
      text: 'Top Universities in UK',
      link: '/uk/university/top-universities-in-uk',
    },
  ],
  offerings: [
    {
      text: 'Free IELTS Masterclass',
      path: '/ielts-preparation-online',
    },
    {
      text: 'Talk to a counsellor',
      path: '/counsellors',
    },
    {
      text: 'Twinning Programs',
      link: 'https://advantage.leapscholar.com',
    },
    {
      text: 'Scholarships',
      path: '/scholarship-finder',
    },
    {
      text: 'Cost Calculator',
      path: '/cost-calculator',
    },
    {
      text: 'Blog',
      link: 'https://leapscholar.com/blog',
    },
  ],
  company: [
    {
      text: 'About Us',
      path: '/about-us',
    },
    {
      text: 'Careers',
      link: 'https://leapfinance.freshteam.com/jobs',
    },
    {
      text: 'Leap in the news!',
      link: 'https://press.leapscholar.com',
    },
  ],
  products: [
    {
      text: 'LeapFinance',
      link: 'https://leapfinance.com',
    },
    {
      text: 'LevelUp by Leap',
      link: 'https://levelup.leapfinance.com',
    },
    {
      text: 'LeapAdvantage',
      link: 'https://advantage.leapscholar.com',
    },
    {
      text: 'IELTS by LeapScholar',
      link: '/ielts-preparation-online',
    },
  ],
  socialMedia: [
    {
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/youtube.svg',
      link: 'https://www.youtube.com/c/leapscholar',
      contentName: 'Youtube',
    },
    {
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/twitter.svg',
      link: 'https://twitter.com/leapscholar',
      contentName: 'Twitter',
    },
    {
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/linkedin.svg',
      link: 'https://in.linkedin.com/company/leap-scholar',
      contentName: 'Linkedin',
    },
    {
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/instagram.svg',
      link: 'https://www.instagram.com/leapscholar',
      contentName: 'Instagram',
    },
    {
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/facebook.svg',
      link: 'https://www.facebook.com/leapscholar',
      contentName: 'Facebook',
    },
    {
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/telegram-icon.svg',
      link: 'https://t.me/leap_abroad',
      contentName: 'Telegram',
    },
  ],
  partners: [
    {
      link: 'https://yocket.com',
      contentName: 'Yocket study abroad',
    },
    {
      link: 'https://geebeeworld.com',
      contentName: 'GEEBEE',
    },
  ],
}

const getWidget = (contentName?: string, widgetName?: string) => {
  return {
    contentName,
    widgetName,
    widgetFormat: 'Banner',
    contentFormat: 'Button',
  }
}

const Divider: FunctionComponent = () => (
  <div className="h-100 bg-gray-780 w-full my-6"></div>
)

const ContactSection: React.FC<FooterProps> = ({ isMobileSized }) => {
  return (
    <div className="flex flex-col gap-1 text-lightgray-250">
      {!isMobileSized && <p className="font-bold text-normal">Contact us</p>}
      <a className="text-font14 text-lightgray-1650" href="tel:08047184844">
        Call us on: (080)47184844 (Between 10AM to 7PM)
      </a>
      <a
        className="text-font14 text-lightgray-1650"
        href="mailto:contact@leapscholar.com"
      >
        Mail us on: contact@leapscholar.com
      </a>
    </div>
  )
}

interface FooterProps {
  isMobileSized?: boolean
  windowWidth?: number
}

const Footer = (props: FooterProps & LoginProps) => {
  const { isMobileSized, handleSpotCounsellingFlow, windowWidth } = props

  return (
    <>
      <footer
        className={`bg-blue-100 text-white ${
          isMobileSized ? 'py-5 px-[22px]' : 'p-[36px]'
        }`}
      >
        <div className={!isMobileSized ? 'wrapper' : ''}>
          <div className="flex flex-col gap-6">
            {/* logo */}
            <div className="flex items-center gap-2">
              <img
                className="logo"
                src="/assets/company_logo/new-footer-logo.svg"
                alt="logo"
                style={{ width: '200px' }}
              />
            </div>
            <div>
              <button
                className="mb-4 shadow-20 text-center rounded w-180 bg-yellow-to-orange inline-block text-sm leading-6 font-bold py-3"
                onClick={() => {
                  if (handleSpotCounsellingFlow) handleSpotCounsellingFlow()
                  trackClick(
                    pageName.LeapScholarHomePage,
                    getWidget('Start your Journey', 'Footer'),
                  )
                }}
              >
                Start your Journey
              </button>
            </div>

            {/* contact-mobile */}
            {isMobileSized ? (
              <ContactSection windowWidth={windowWidth} />
            ) : null}

            {/* Social links */}
            <div className="flex flex-col gap-1 text-lightgray-250">
              <p className="font-bold text-normal">Follow us on:</p>
              <div className="flex gap-2 mt-2">
                {links.socialMedia.map((element, index) => (
                  <a
                    onClick={() =>
                      trackClick(
                        pageName.LeapScholarHomePage,
                        getWidget(element.contentName, 'Footer'),
                      )
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    href={element.link}
                  >
                    <Image
                      src={element.icon}
                      alt="social-icon"
                      width={30}
                      height={30}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="flex-1">
            <div
              className={`grid ${
                !isMobileSized ? 'md:grid-cols-4' : 'grid-cols-2 gap-5 mt-8'
              }`}
            >
              {/* Countries */}
              <div className="flex flex-col gap-1 text-lightgray-250">
                <p className="font-bold text-normal">Countries</p>
                {links.countries.map((element, index) => (
                  <a
                    key={index}
                    href={`${element.link}`}
                    onClick={() => {
                      trackClick(
                        pageName.LeapScholarHomePage,
                        getWidget(element.country, 'Footer - Countries List'),
                      )
                    }}
                    className="text-font14 text-lightgray-1650"
                  >
                    {element.country}
                  </a>
                ))}

                <div className="flex flex-col text-lightgray-250 mt-2">
                  <p className="font-bold text-normal">Top Universities</p>
                  {links.topUniversities.map((element, index) => (
                    <a
                      key={index}
                      href={`${element.link}`}
                      className="text-font14 text-lightgray-1650"
                    >
                      {element.text}
                    </a>
                  ))}
                </div>
              </div>

              {/* Company */}
              <div className="flex flex-col gap-1 text-lightgray-250">
                <p className="font-bold text-normal">Company</p>
                {links.company.map((element, index) =>
                  element.link ? (
                    <a
                      onClick={() => {
                        trackClick(
                          pageName.LeapScholarHomePage,
                          getWidget(element.text, 'Footer - Company'),
                        )
                      }}
                      className="text-font14 text-lightgray-1650"
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                      href={element.link}
                    >
                      {element.text}
                    </a>
                  ) : (
                    <Link key={index} href={`${element.path}`}>
                      <a
                        onClick={() => {
                          trackClick(
                            pageName.LeapScholarHomePage,
                            getWidget(element.text, 'Footer - Company'),
                          )
                        }}
                        className="text-font14 text-lightgray-1650"
                      >
                        {element.text}
                      </a>
                    </Link>
                  ),
                )}

                {!isMobileSized && (
                  <div className="flex flex-col text-lightgray-250 mt-2">
                    <p className="font-bold text-normal">Our Partners</p>
                    {links.partners.map((element, index) =>
                      element.link ? (
                        <a
                          onClick={() => {
                            trackClick(
                              pageName.LeapScholarHomePage,
                              getWidget(
                                element.contentName,
                                'Footer - Partner',
                              ),
                            )
                          }}
                          className="text-font14 text-lightgray-1650"
                          target="_blank"
                          rel="noopener noreferrer"
                          key={index}
                          href={element.link}
                        >
                          {element.contentName}
                        </a>
                      ) : (
                        <Link key={index} href={`${element.link}`}>
                          <a
                            onClick={() => {
                              trackClick(
                                pageName.LeapScholarHomePage,
                                getWidget(
                                  element.contentName,
                                  'Footer - Partner',
                                ),
                              )
                            }}
                            className="text-font14 text-lightgray-1650"
                          >
                            {element.contentName}
                          </a>
                        </Link>
                      ),
                    )}
                  </div>
                )}
              </div>

              {/* Offerings */}
              <div className="flex flex-col gap-1 text-lightgray-250">
                <p className="font-bold text-normal">Our Offerings</p>
                {links.offerings.map((element, index) =>
                  element.link ? (
                    element.link === '/' ? (
                      <a
                        onClick={() => {
                          trackClick(
                            pageName.LeapScholarHomePage,
                            getWidget(element.text, 'Footer - Our Offerings'),
                          )
                        }}
                        className="text-font14 text-lightgray-1650"
                        key={index}
                        href="/"
                      >
                        {element.text}
                      </a>
                    ) : (
                      <a
                        onClick={() => {
                          trackClick(
                            pageName.LeapScholarHomePage,
                            getWidget(element.text, 'Footer - Our Offerings'),
                          )
                        }}
                        className="text-font14 text-lightgray-1650"
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}
                        href={element.link}
                      >
                        {element.text}
                      </a>
                    )
                  ) : (
                    <Link key={index} href={`${element.path}`}>
                      <a
                        className="text-font14 text-lightgray-1650"
                        onClick={() => {
                          trackClick(
                            pageName.LeapScholarHomePage,
                            getWidget(element.text, 'Footer - Our Offerings'),
                          )
                        }}
                      >
                        {element.text}
                      </a>
                    </Link>
                  ),
                )}
              </div>

              {/* Products */}
              <div className="flex flex-col gap-1 text-lightgray-250">
                <p className="font-bold text-normal">Our Products</p>
                {links.products.map((element, index) => (
                  <a
                    onClick={() => {
                      trackClick(
                        pageName.LeapScholarHomePage,
                        getWidget(element.text, 'Footer - Our Products'),
                      )
                    }}
                    className="text-font14 text-lightgray-1650"
                    rel="noopener noreferrer"
                    target={
                      element.text === 'IELTS by Leap' ? '_self' : '_blank'
                    }
                    key={index}
                    href={element.link}
                  >
                    {element.text}
                  </a>
                ))}
              </div>
            </div>

            <Divider />

            <div>
              {/* Locations */}
              <div className={!isMobileSized ? 'grid md:grid-cols-4' : ''}>
                <div className="flex flex-col gap-1 text-lightgray-250">
                  <p className="font-bold text-normal">
                    HQ- Bangalore &nbsp;(India)
                  </p>
                  <p
                    className={`text-font13 text-lightgray-1650 ${
                      !isMobileSized && 'max-w-[148px]'
                    }`}
                  >
                    163/A, 9th Main Rd, Sector 6, HSR Layout, Bengaluru,
                    Karnataka 560102
                  </p>
                </div>

                {isMobileSized ? <Divider /> : null}

                <div className="flex flex-col gap-1 text-lightgray-250">
                  <p className="font-bold text-normal">
                    Singapore &nbsp;(International)
                  </p>
                  <p
                    className={`text-font13 text-lightgray-1650 ${
                      !isMobileSized && 'max-w-[148px]'
                    }`}
                  >
                    11 Collyer Quay #17-00 The Arcade, 049317, Singapore
                  </p>
                </div>

                {isMobileSized ? <Divider /> : null}

                <div className="flex flex-col gap-1 text-lightgray-250">
                  <p className="font-bold text-normal">
                    UAE &nbsp;(International)
                  </p>
                  <p
                    className={`text-font13 text-lightgray-1650 ${
                      !isMobileSized && 'max-w-[148px]'
                    }`}
                  >
                    Office 1106, Arenco Tower, Sheikh Zayed Road, <br /> Media
                    City Dubai - 3087, UAE <br /> Contact Number: +971 501501220
                  </p>
                </div>

                {isMobileSized ? <Divider /> : null}

                {!isMobileSized ? <ContactSection /> : null}

                {/* Our Partners */}
                <div className="flex flex-col gap-1 text-lightgray-250 md:hidden">
                  <p className="font-bold text-normal">Our Partners</p>
                  {links.partners.map((element, index) =>
                    element.link ? (
                      <a
                        onClick={() => {
                          trackClick(
                            pageName.LeapScholarHomePage,
                            getWidget(element.contentName, 'Footer - Partner'),
                          )
                        }}
                        className="text-font14 text-lightgray-1650"
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}
                        href={element.link}
                      >
                        {element.contentName}
                      </a>
                    ) : (
                      <Link key={index} href={`${element.link}`}>
                        <a
                          onClick={() => {
                            trackClick(
                              pageName.LeapScholarHomePage,
                              getWidget(
                                element.contentName,
                                'Footer - Partner',
                              ),
                            )
                          }}
                          className="text-font14 text-lightgray-1650"
                        >
                          {element.contentName}
                        </a>
                      </Link>
                    ),
                  )}
                </div>

                {/* Privacy */}
                <div className="flex flex-col gap-1 text-lightgray-250 pt-4 md:pt-0">
                  <Link href="/privacy">
                    <a className={`text-sm text-lightgray-1650 inline-block`}>
                      Privacy Policy
                    </a>
                  </Link>
                  <Link href={navRoutes.TERM_AND_CONDITION} prefetch={false}>
                    {/* Terms and Conditions */}
                    <a className={`text-sm text-lightgray-1650 inline-block`}>
                      Terms and Conditions
                    </a>
                  </Link>
                  <Link href={navRoutes.REFUND_POLICY} prefetch={false}>
                    {/* Refund policy */}
                    <a className={`text-sm text-lightgray-1650 inline-block`}>
                      Refund policy
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <style jsx>
        {`
          .logo {
            filter: brightness(0) invert(1);
            margin-left: -4px;
            height: 50px;
          }

          .wrapper {
            max-width: 1120px;
            margin: auto;
            display: flex;
            gap: 80px;
          }
        `}
      </style>
    </>
  )
}

export default withWindowDimensions(withLogin(Footer))
