import { AnyAction } from "redux";
import { chatActionsConstants } from "./chatActions";

const initialState = {
  ratingResponseSuccess: false,
  ratingResponseError: false,
  ratingResponseLoading: false
};

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case chatActionsConstants.RATING_RESPONSE_SUCCESS:
      return {
        ...state,
        ratingResponseSuccess: true
      };

    case chatActionsConstants.RATING_RESPONSE_ERROR:
       return {
         ...state,
         ratingResponseError: true,
       }
        
    default:
        return state;
  }
};

export default reducer;
