import { MasterclassDataSchema } from "Interfaces/reducerTypes/MasterclassSchema";
import { AnyAction } from "redux";
import { actionConstant } from "./MasterClassTypeAction";

export interface MasterClassTypeReducerInterface {
  error: string;
  showLoader: boolean;
  classicMasterClass: Array<MasterclassDataSchema> | null;
  beginnerMasterClass: Array<MasterclassDataSchema> | null;
}

const initialState: MasterClassTypeReducerInterface = {
  beginnerMasterClass: null,
  classicMasterClass: null,
  error: "",
  showLoader: false,
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionConstant.REQUEST_BEGINNER_MASTERCLASS_TYPE_DATA:
      return {
        ...state,
        showLoader: true,
      };
    case actionConstant.SUCCESS_BEGINNER_MASTERCLASS_TYPE_DATA:
      return {
        ...state,
        beginnerMasterClass: action.payload,
        showLoader: false,
      };
    case actionConstant.REQUEST_CLASSIC_MASTERCLASS_TYPE_DATA:
      return {
        ...state,
        showLoader: true,
      };
    case actionConstant.SUCCESS_CLASSIC_MASTERCLASS_TYPE_DATA:
      return {
        ...state,
        classicMasterClass: action.payload,
        showLoader: false,
      };
    case actionConstant.UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
