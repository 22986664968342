import axios, { AxiosRequestConfig } from 'axios'
import { ResponseWrapper } from 'components/SpotCounselling/Calendar/calendarInterface'
import { SlotTypes } from 'components/SpotCounselling/Calendar/utils'
import { getPlatformV2 } from 'utils/events-utils'

export const fetchSpotCounsellingSlot = async (
  setIsLoading: (value: boolean) => void,
  isLoaderNeeded?: boolean,
): Promise<ResponseWrapper> => {
  try {
    const res: ResponseWrapper = await axios.get(
      `/api/scholarRoute?path=counselling/slots/live/30`,
    )
    if (isLoaderNeeded) setIsLoading(false)
    return res
  } catch (err: any) {
    console.log('Something went wrong. Please refresh the page')
    throw new Error('Something went wrong. Please refresh the page')
  }
}

export const confirmRescheduleSlot = async (payload: any) => {
  const { batchId, utmData, meetingId } = payload
  const data = {
    utm_campaign: utmData.utm_campaign || '',
    utm_source: utmData.utm_source || '',
    utm_term: utmData.utm_term || '',
    utm_medium: utmData.utm_medium || '',
    campaign_type: utmData.campaign_type || '',
    form_id: utmData.formId?.length ? utmData.formId : null,
    ad_id: utmData.ad_id || '',
  }

  try {
    const res = await axios.post(
      `/api/scholarRoute?path=counselling/register/${batchId}&reschedule=true&meetingId=${meetingId}`,
      data,
    )
    return res?.data
  } catch (err: any) {
    console.log('Something went wrong. Please refresh the page')
  }
}

export const confirmSpotCounsellingSlot = async (payload: any) => {
  const { batchId, utmData } = payload

  try {
    const res = await axios.post(
      `/api/scholarRoute?path=counselling/register/${batchId}`,
      utmData,
    )

    return res?.data
  } catch (err: any) {
    console.log('Something went wrong. Please refresh the page')
  }
}

export const getRegisteredMeetingLink = async (
  batch_id: number,
): Promise<void> => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=counselling/details/${batch_id}`,
    )
    if (res.data.success) {
      window.open(res?.data?.data?.meetingLink, '_blank')
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    console.log(err || 'Something went wrong. Please refresh the page')
  }
}

// Ielts Elite Demo Api's

export const fetchIeltsEliteDemoSlots = async (
  setIsLoading: (value: boolean) => void,
  isLoaderNeeded?: boolean,
): Promise<ResponseWrapper> => {
  try {
    const res: ResponseWrapper = await axios.get(
      `/api/scholarRoute?path=counselling/slots/${SlotTypes.IELTS_SPOT_COUNSELLING}/live/30`,
    )
    if (isLoaderNeeded) setIsLoading(false)
    return res
  } catch (err: any) {
    console.log(err)
    throw new Error('Something went wrong. Please refresh the page')
  }
}

export const confirmIeltsEliteDemoSlot = async (
  payload: any,
  isReschedule?: boolean,
) => {
  const { batchId } = payload
  try {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `/api/scholarRoute?path=counselling/register/${batchId}&slotType=${SlotTypes.IELTS_SPOT_COUNSELLING}&reschedule=${isReschedule}`,
      headers: {
        'X-IELTS-PLATFORM': getPlatformV2(),
        'X-API-VERSION': '2',
      },
      data: payload,
    }

    const res = await axios(config)
    if (res.data.success) {
      return res.data
    } else {
      return res.data.message
    }
  } catch (err: any) {
    return { data: null, message: err?.message }
  }
}

export const unAuthenticatedFetchIeltsEliteDemoSlot = async (
  tempToken: any,
) => {
  try {
    const res = await axios.post(
      `/api/scholarRoute?path=counselling/slots/${SlotTypes.IELTS_SPOT_COUNSELLING}/live/30`,
      { sessionToken: tempToken },
    )
    if (res.data.success) {
      return res.data.data
    } else {
      return res?.data?.message
    }
  } catch (err: any) {
    console.log(err)
    throw new Error('Something went wrong. Please refresh the page')
  }
}
export const fetchGetBookedIeltsEliteDemoSlots = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=counselling/slots/${SlotTypes.IELTS_SPOT_COUNSELLING}/booked`,
    )
    return res.data
  } catch (error) {}
}
