import axios from 'axios'
import { RewardsApi } from 'Interfaces/reducerTypes/IeltsTestSchema'

const fetchIeltsTestSubmissionStatus = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=basket/status`)
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const submitIeltsTestDetails = async (payload: any) => {
  try {
    const values = {
      testDateReceiptUrl: payload['IELTS_TEST_DETAILS'].url,
      testDate: payload.examDate,
      ieltsExamType: payload?.ieltsExamType ?? null,
      oneToOneCounsellingSession: payload?.oneToOneCounsellingSession ?? null,
    }
    const res = await axios.post(
      `/api/scholarRoute?path=artifact/save/ielts_test_details_basket`,
      values,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const fetchIeltsDateRewards = async (stage: RewardsApi) => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=basket/rewards/${stage}`,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const fetchIeltsDateUserStatus = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=basket/user-status`)
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const fetchIeltsTestBannerStatusApi = () =>
  axios.get(`/api/scholarRoute?path=metadata/ielts`)

export const ieltsTestApis = {
  fetchIeltsTestSubmissionStatus,
  submitIeltsTestDetails,
  fetchIeltsDateRewards,
  fetchIeltsDateUserStatus,
  fetchIeltsTestBannerStatusApi,
}
