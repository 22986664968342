import axios from "axios";

const fetchStudyMaterial = async (batchId: string) => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=workshop/study-material/section/${batchId}`
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      throw new Error(res.data.message);
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please contact the teach team");
  }
};

const fetchFastTrackStudyMaterial = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=fasttrack/study-material`
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      throw new Error(res.data.message);
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please contact the teach team");
  }
};

export const studyMaterialApi = {
  fetchStudyMaterial,
  fetchFastTrackStudyMaterial,
};
