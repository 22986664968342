const fetchExpertsData = () => ({
  type: "FETCH_EXPERTS_DATA",
});

const fetchExpertsSuccess = (payload: any) => ({
  type: "FETCH_EXPERTS_DATA_SUCCESS",
  payload,
});

const fetchExpertsFail = (message: string) => ({
  type: "FETCH_EXPERTS_DATA_FAIL",
  payload: message,
});

const fetchStage = () => ({
  type: "FETCH_STAGE",
});

const fetchStageSuccess = (payload: any) => ({
  type: "FETCH_STAGE_SUCCESS",
  payload,
});

const fetchStageFail = (message: string) => ({
  type: "FETCH_STAGE_FAIL",
  payload: message,
});

const fetchSpotCounsellingSlot = () => ({
  type: "FETCH_SPOT_COUNSELLING_SLOT",
});

const fetchSpotCounsellingSlotSuccess = (payload: any) => ({
  type: "FETCH_SPOT_COUNSELLING_SLOT_SUCCESS",
  payload,
});

const fetchSpotCounsellingSlotFail = (message: string) => ({
  type: "FETCH_SPOT_COUNSELLING_SLOT_FAIL",
  payload: message,
});

const confirmSpotCounsellingSlot = (payload: any) => ({
  type: "CONFIRM_SPOT_COUNSELLING_SLOT",
  payload: payload,
});

const confirmSpotCounsellingSlotSuccess = (payload: any) => ({
  type: "CONFIRM_SPOT_COUNSELLING_SLOT_SUCCESS",
  payload,
});

const confirmSpotCounsellingSlotFail = (message: string) => ({
  type: "CONFIRM_SPOT_COUNSELLING_SLOT_FAIL",
  payload: message,
});

const confirmRescheduleSlot = (payload: number) => ({
  type: "CONFIRM_SPOT_RESCHEDULE_SLOT",
  payload: payload,
});

const confirmRescheduleSlotSuccess = (payload: any) => ({
  type: "CONFIRM_SPOT_RESCHEDULE_SLOT_SUCCESS",
  payload,
});

const confirmRescheduleSlotFail = (message: string) => ({
  type: "CONFIRM_SPOT_RESCHEDULE_SLOT_FAIL",
  payload: message,
});

const fetchSpotProfile = () => ({
  type: "FETCH_SPOT_PROFILE",
});

const fetchSpotProfileSuccess = (payload: any) => ({
  type: "FETCH_SPOT_PROFILE_SUCCESS",
  payload,
});

const fetchSpotProfileFail = (message: string) => ({
  type: "FETCH_SPOT_PROFILE_FAIL",
  payload: message,
});

const submitSpotProfile = (payload: any) => ({
  type: "SUBMIT_SPOT_PROFILE",
  payload,
});

const submitSpotProfileSuccess = (payload: any) => ({
  type: "SUBMIT_SPOT_PROFILE_SUCCESS",
  payload,
});

const submitSpotProfileFail = (message: string) => ({
  type: "SUBMIT_SPOT_PROFILE_FAIL",
  payload: message,
});

const fetchGoalPlanData = () => ({
  type: "FETCH_GOAL_PLAN_DATA",
});

const fetchUpcomingEvents = () => ({
  type: "FETCH_UPCOMING_EVENTS",
});

const fetchUpcomingEventsSuccess = (payload: any) => ({
  type: "FETCH_UPCOMING_EVENTS_SUCCESS",
  payload,
});

const fetchEligibility = () => ({
  type: "ELIGIBILITY_FETCH_REQUESTED",
});

const fetchEligibilitySuccess = (payload: any) => ({
  type: "ELIGIBILITY_FETCH_SUCCESS",
  payload,
});

const fetchProfileLayout = () => ({
  type: "FETCH_PROFILE_LAYOUT",
});

const fetchProfileLayoutSuccess = (payload: any) => ({
  type: "FETCH_PROFILE_LAYOUT_SUCCESS",
  payload,
});

const fetchProfileLayoutFail = (payload: any) => ({
  type: "FETCH_PROFILE_LAYOUT_FAIL",
  payload,
}); 

const fetchEligibilityFail = (message: string) => ({
  type: "ELIGIBILITY_FETCH_FAIL",
  payload: message,
});

const fetchUpcomingEventsFail = (payload: any) => ({
  type: "FETCH_UPCOMING_EVENTS_FAIL",
  payload,
});

const fetchGoalPlanDataSuccess = (payload: any) => ({
  type: "FETCH_GOAL_PLAN_DATA_SUCCESS",
  payload,
});

const fetchGoalPlanDataFail = (message: string) => ({
  type: "FETCH_GOAL_PLAN_DATA_FAIL",
  payload: message,
});

export const spotCounsellingActions = {
  fetchExpertsData,
  fetchExpertsSuccess,
  fetchExpertsFail,
  fetchStage,
  fetchStageSuccess,
  fetchStageFail,
  fetchSpotCounsellingSlot,
  fetchSpotCounsellingSlotSuccess,
  fetchSpotCounsellingSlotFail,
  confirmSpotCounsellingSlot,
  confirmSpotCounsellingSlotSuccess,
  confirmSpotCounsellingSlotFail,
  confirmRescheduleSlot,
  confirmRescheduleSlotSuccess,
  confirmRescheduleSlotFail,
  fetchSpotProfile,
  fetchSpotProfileSuccess,
  fetchSpotProfileFail,
  submitSpotProfile,
  submitSpotProfileSuccess,
  submitSpotProfileFail,
  fetchGoalPlanData,
  fetchGoalPlanDataSuccess,
  fetchGoalPlanDataFail,
  fetchUpcomingEvents,
  fetchUpcomingEventsSuccess,
  fetchUpcomingEventsFail,
  fetchEligibility,
  fetchEligibilitySuccess,
  fetchEligibilityFail,
  fetchProfileLayout,
  fetchProfileLayoutSuccess,
  fetchProfileLayoutFail
};
