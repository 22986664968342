import axios from 'axios'
import { SlotTypes } from 'components/SpotCounselling/Calendar/utils'

const fetchIeltsEliteDemoSlots = () =>
  axios.get(
    `/api/scholarRoute?path=counselling/slots/${SlotTypes.IELTS_SPOT_COUNSELLING}/live/30`,
  )

export const IeltsDemoApi = {
  fetchIeltsEliteDemoSlots,
}
