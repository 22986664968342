import axios from "axios";

const fetchMasterclassReplays = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=video/youtubeRecording`
    );
    return res.data;
  } catch (err:any) {
    console.log(err);
  }
};

export const masterclassReplaysApis = {
  fetchMasterclassReplays,
};
