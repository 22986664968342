import axios from 'axios'
import { getCookie } from 'utils/cookie-utiils'

const checkIfPrivateEvent = (tags: any) => {
  const tagNames = tags.map((item: any) => item?.tag?.Name)
  return tagNames.includes('PRIVATE_EVENT')
}

const getEventsOnServerSide = async () => {
  const upcomingEvents = await axios.get(
    `${process.env.SCHOLAR_URL}/event/upcoming/30`
  )
  let finalData = []
  if (upcomingEvents.data.success) {
    if (upcomingEvents?.data?.data) {
      const eventsSlug: any = []
      const apiEventsData: any = []
      upcomingEvents?.data?.data.forEach((elem: any) => {
        if (elem.slots) {
          elem.slots.forEach((data: any) => {
            if (data.slug) {
              eventsSlug.push(data.slug)
              apiEventsData.push(data)
            }
          })
        }
      })

      const slugObj: any = {}

      eventsSlug.forEach((elen: any) => {
        if (!slugObj[elen]) {
          slugObj[elen] = 1
        }
      })

      const newSlugArray = Object.keys(slugObj)

      if (newSlugArray.length === 0) {
        throw Error('No events present')
      }

      const queryParam = newSlugArray.reduce((acc, curr, index) => {
        acc += `slug_in=${curr}${newSlugArray.length - 1 === index ? '' : '&'}`
        return acc
      }, '?')

      const eventsStaticData = await axios.get(
        `${process.env.CMS_URL}/events${queryParam}`
      )
      const eventStaticDataArray = eventsStaticData?.data
      if (apiEventsData?.length > 0 && eventStaticDataArray.length > 0) {
        for (let i = 0; i < apiEventsData.length; i++) {
          for (let j = 0; j < eventStaticDataArray.length; j++) {
            if (eventStaticDataArray[j]?.slug == apiEventsData[i]?.slug) {
              const data = { ...eventStaticDataArray[j], ...apiEventsData[i] }
              finalData.push(data)
            }
          }
        }
      }
    }
  }
  finalData = finalData.filter(
    (event: any) => !checkIfPrivateEvent(event?.event_tags_v2)
  )
  return finalData
}

const getCountryEventsData = async (country: string) => {
  try {
    const eventsData = await getEventsOnServerSide()
    const sortedData = eventsData.sort((a: any, b: any) => {
      return (new Date(a.date) as any) - (new Date(b.date) as any)
    })

    const filteredCountryEvents = sortedData.filter((item: any) => {
      const eventTags = item.event_tags_v2
      for (let i = 0; i < eventTags.length; i++) {
        if (
          eventTags[i].tag?.type === 'country' &&
          eventTags[i].tag?.Name === country
        ) {
          return true
        }
      }
      return false
    })

    if (filteredCountryEvents.length >= 3) {
      return {
        events: filteredCountryEvents.slice(0, 3),
      }
    } else {
      return {
        events: filteredCountryEvents,
      }
    }
  } catch (err) {
    return {
      events: [],
    }
  }
}

const getStudentTestimonialsForCountryPage = async (country: string) => {
  try {
    const { data: reviews } = await axios.get(
      `${process.env.CMS_URL}/reviews?platform_eq=SCHOLAR&type_eq=Testimonial&country_null=false&country_eq=${country}`
    )

    const finalData = reviews
      .map((el: any) => {
        let studentInfo = ''
        if (el.Name) {
          studentInfo = el.Name?.split(',')
        }

        return {
          rating: el.rating,
          name: studentInfo[0] || '',
          image: el.user_image?.url,
          college: studentInfo[1] || '',
          reviews: el.review,
        }
      })
      .filter((el: any) => {
        if (!!(el.name && el.reviews)) return true
        return false
      })

    if (finalData.length > 4) {
      return {
        reviews: finalData.slice(0, 4),
      }
    } else {
      return {
        reviews: finalData,
      }
    }
  } catch (err) {
    return {
      reviews: [],
    }
  }
}

const getSingleEventOnServerSide = async (eventId: number) => {
  const eventData = await axios.get(
    `${process.env.SCHOLAR_URL}/event/details/${eventId}`
  )
  if (eventData.data.success) {
    const apiData = eventData?.data?.data
    if (apiData) {
      const CMS_URL = process.env.CMS_URL || 'https://cms.leapscholar.com'
      const res2 = await axios.get(CMS_URL + `/events?slug=${apiData?.slug}`)
      const cmsData = res2.data?.[0]

      if (cmsData) {
        const eventData = { ...apiData, ...cmsData }
        return eventData
      }
    }
  }
  return {}
}

const getI2CBlogsFromCMS = async () => {
  try {
    const { data: blogs } = await axios.get(
      `${process.env.CMS_URL}/banners?type=i2c_blog`
    )
    return blogs
  } catch (err) {
    return {
      blogs: [],
    }
  }
}

const getI2CSlidersFromCMS = async () => {
  try {
    const { data: sliders } = await axios.get(
      `${process.env.CMS_URL}/banners?type=i2c_sliders`
    )
    return sliders
  } catch (err) {
    return {
      blogs: [],
    }
  }
}

const getI2CMainBannerFromCMS = async () => {
  try {
    const { data: mainBanner } = await axios.get(
      `${process.env.CMS_URL}/banners?type=i2c_main`
    )
    return mainBanner
  } catch (err) {
    return {
      mainBanner: [],
    }
  }
}

const getI2CMOfferingsFromCMS = async () => {
  try {
    const { data: offerings } = await axios.get(
      `${process.env.CMS_URL}/banners?type=offerings`
    )
    return offerings
  } catch (err) {
    return {
      offerings: [],
    }
  }
}
const checkIfIeltsApp = () => {
  const platformCookie = getCookie(null, 'platform')
  return platformCookie === 'mweb'
}

const checkIfMefuUser = () => {
  const mefuCookie = getCookie(null, 'mefu_user')
  if (!mefuCookie) return null
  console.log(mefuCookie)
  return mefuCookie == 'true' ? 'true' : 'false'
}

const getFormJsonByFormId = async (formId: string) => {
  try {
    const res = await axios.get(
      `${process.env.SCHOLAR_URL}/internal-profile/dynamic/tofu/form/${formId}`
    )

    return { data: res?.data?.data?.formJson }
  } catch (er) {
    return { data: {} }
  }
}

export {
  getEventsOnServerSide,
  getCountryEventsData,
  getStudentTestimonialsForCountryPage,
  getSingleEventOnServerSide,
  getI2CBlogsFromCMS,
  getI2CMainBannerFromCMS,
  getI2CMOfferingsFromCMS,
  checkIfIeltsApp,
  getFormJsonByFormId,
  getI2CSlidersFromCMS,
  checkIfMefuUser,
}
