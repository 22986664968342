import axios from "axios";
import playListQuery from "apollo/queries/playList";
import { apoloClient } from "apollo/apollo";

const fetchOverviewRequest = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=profile/post/task`);
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const fetchCounsellingFeeRequest = async () => {
  try {
    const res = await axios.get(
      `/api/scholarRoute?path=student/counselling/fee`
    );
    return res;
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const fetchPostPaymentDataRequest = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=student/post-payment`);
    if (res.data.success) {
      return res;
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const fetchOfferRequest = async () => {
  try {
    const res = await axios.get(`/api/scholarRoute?path=student/offer`);
    if (res.data.success) {
      return res;
    }
  } catch (err:any) {
    throw new Error("Something went wrong. Please refresh the page");
  }
};

const fetchPlaylist = async () => {
  try {
    const data = await apoloClient.query({
      query: playListQuery,
      variables: [],
    });
    return data;
  } catch (e:any) {
    console.log(e);
  }
};

export const overviewApi = {
  fetchOverviewRequest,
  fetchCounsellingFeeRequest,
  fetchPostPaymentDataRequest,
  fetchOfferRequest,
  fetchPlaylist,
};
